import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';

export const fetchProjectStages = async (projectId?: number) => {
  const { data: projectStages, error } = await supabase
    .from('project_stage')
    .select(
      `*, project_milestone(*, project_checklist_item(*), project_milestone_file(*), project_milestone_image(*))`
    )
    .order('status', {
      ascending: true,
    })
    .order('sequence', {
      ascending: true,
    })
    .order('sequence', {
      ascending: true,
      foreignTable: 'project_milestone',
    })
    .eq('project_id', projectId);

  if (error) {
    throw error;
  }

  if (!projectStages) {
    throw new Error('No  found');
  }

  return projectStages;
};

const useProjectStages = (projectId?: number) =>
  useQuery(['projectStages'], () => fetchProjectStages(projectId), {
    enabled: !!projectId,
  });

export default useProjectStages;
