import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';

import { MilestoneStore } from '_store/milestone-store';

export const ProjectNavigator = observer(
  (props: { selectedTab: string | null }) => {
    const { selectedTab } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    // const { stageId } = MilestoneStore;

    const handleTabChange = (selected: string) => {
      if (!id) return;
      if (selected === 'chat') {
        navigate({
          pathname: '/chats',
          search: `?project=${+id}`,
        });
        return;
      }
      navigate({
        pathname: `/projects/${+id}`,
        search: `?selectedTab=${selected}`,
      });
    };

    const tabs = [
      {
        key: 'details',
        title: 'Details',
      },
      {
        key: 'milestone',
        title: 'Milestones',
      },
      {
        key: 'chat',
        title: 'Chat',
      },
    ];

    return (
      <div className="w-32">
        {tabs.map(tab => (
          <div
            key={tab.key}
            className={classNames(
              'border-l-2 pl-4 font-semibold text-dark-gray-2 h-9 flex items-center cursor-pointer',
              selectedTab === tab.key
                ? 'border-primary text-primary'
                : 'hover:text-primary-50'
            )}
            onClick={() => {
              handleTabChange(tab.key);
            }}>
            <div className="flex flex-start items-center">
              <span>{tab.title}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
);
