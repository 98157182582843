import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link, useParams } from 'react-router-dom';

import Spinner from '_components/spinner';
import useSelectedOrganizationClients from '_hooks/useSelectedOrganizationClients';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import { Project } from '_types/project';

const PAGE_SIZE = 6;

export const CurrentOrganizationClientList = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState('');

  const { data: clientProfiles, isInitialLoading } =
    useSelectedOrganizationClients();
  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  // update breadcrumbs
  useEffect(() => {
    setItems([{ label: 'People' }, { label: 'Clients', highlight: true }]);
  }, [addItem, id, setItems]);

  const filteredClients = useMemo(() => {
    if (!clientProfiles) return [];
    return clientProfiles.filter(clientProfile => {
      if (searchString) {
        return (
          clientProfile?.first_name
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          clientProfile?.last_name
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          clientProfile?.email
            ?.toLowerCase()
            .includes(searchString.toLowerCase())
        );
      }

      return true;
    });
  }, [clientProfiles, searchString]);

  const currentPageData = useMemo(
    () =>
      orderBy(filteredClients, ['created_at'], ['desc']).slice(
        currentPage * PAGE_SIZE,
        (currentPage + 1) * PAGE_SIZE
      ),
    [currentPage, filteredClients]
  );

  const pageCount = useMemo(
    () => Math.ceil(filteredClients.length / PAGE_SIZE),
    [filteredClients.length]
  );

  const getProjectDescriptionString = useCallback((projects: Project[]) => {
    let activeCount = 0;
    let archivedCount = 0;
    projects.forEach(p => {
      if (p.status === 'ACTIVE') {
        activeCount += 1;
      } else {
        archivedCount += 1;
      }
    });
    return `${activeCount} Active, ${archivedCount} Archived`;
  }, []);

  return (
    <div className="relative">
      <div className="flex justify-between items-center border-b pb-8 mt-12">
        <div className="font-semibold text-2xl flex items-center">Clients</div>
        <div></div>
      </div>
      <div className="mt-5 flex justify-between items-center">
        <div className="relative mt-1 rounded-md border w-72">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            name="search"
            onChange={e => {
              setCurrentPage(0);
              setSearchString(e.target.value);
            }}
            className="p-2 block w-full rounded-md border-gray-300 pl-10 outline-primary"
            placeholder="Search Name/Email"
          />
        </div>
      </div>
      <div className="border border-light-gray-3 rounded-lg overflow-hidden mt-7">
        <table className="w-full">
          <thead className="bg-light-gray-5">
            <tr>
              <th className="text-mid-gray-2 font-normal py-3 px-4 w-1/3 text-left">
                Name
              </th>
              <th className="text-mid-gray-2 font-normal py-3 px-4 text-left">
                Projects
              </th>
              <th className="w-48 text-mid-gray-2 font-normal py-3 px-4 text-left">
                Status
              </th>
              <th className="w-20"></th>
            </tr>
          </thead>
          {isInitialLoading ? (
            <tbody>
              <tr className="border-t border-light-gray-3">
                <td colSpan={4} className="table-cell">
                  <div className="w-full py-10 flex justify-center">
                    <Spinner className="w-8 h-8 z-15" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : currentPageData.length ? (
            <tbody>
              {currentPageData.map(profile => {
                return (
                  <tr key={profile.id} className="border-t border-light-gray-3">
                    <td className="p-4 w-1/2">
                      <div className="flex gap-2 items-center">
                        {profile.profile_image_url ? (
                          <div className="w-[34px] h-[34px] rounded-full overflow-hidden">
                            <img
                              alt="cover"
                              src={profile.profile_image_url}
                              className="w-full h-full object-cover object-center"
                            />
                          </div>
                        ) : (
                          <div className="w-[34px] h-[34px] bg-gray-200 rounded-full" />
                        )}
                        <div>
                          <Link
                            to={`/people/clients/${profile.id}`}
                            className="block font-medium">
                            {profile.first_name} {profile.last_name}
                          </Link>
                          <div className="text-xs text-mid-gray-1">
                            {profile.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 capitalize">
                      {getProjectDescriptionString(profile.projects)}
                    </td>
                    <td className="px-4">
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            'w-2 h-2 rounded-full',
                            profile.confirmed_at
                              ? 'bg-green-500'
                              : 'bg-yellow-700'
                          )}
                        />
                        <span className="ml-2 capitalize">
                          {profile.confirmed_at ? 'Active' : 'Pending'}
                        </span>
                      </div>
                    </td>
                    <td className="px-4">
                      <Link to={`/people/clients/${profile.id}`}>
                        <ArrowRightIcon className="w-6 h-6 text-light-gray-1" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr className="border-t border-light-gray-3">
                <td colSpan={4} className="table-cell">
                  <div className="w-full py-10 flex justify-center text-gray-400">
                    No Data Found
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ChevronRightIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          previousLabel={
            <ChevronLeftIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          className="flex items-center gap-1 p-4 justify-end border-t"
          pageClassName="py-1 px-2 leading-6"
          activeClassName="bg-light-gray-3 rounded"
          onPageChange={({ selected }) => {
            setCurrentPage(selected);
          }}
          forcePage={currentPage}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          disabledClassName="opacity-20 cursor-not-allowed"
        />
      </div>
    </div>
  );
};

export default CurrentOrganizationClientList;
