import { Dialog, Transition } from '@headlessui/react';

import { Fragment, useCallback, useState } from 'react';
import OtpInput from 'react-otp-input';

import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Spinner from '_components/spinner';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

interface Props {
  phone: string;
  isVisible: boolean;
  onClose: () => void;
}

const PhoneChangeOTPModal = ({ phone, isVisible = false, onClose }: Props) => {
  const snackBar = useSnackBarStore();
  const refetchProfile = useAuthStore($ => $.refetchProfile);

  const [otp, setOtp] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const { error } = await supabase.auth.verifyOtp({
        phone,
        token: otp,
        type: 'phone_change',
      });
      if (error) {
        throw error;
      }
      snackBar.show('OTP verified successfully', 'success');
      await refetchProfile();

      onClose();
    } catch (err) {
      const errorMessage = await getErrorMessage(err);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsSubmitting(false);
    }
  }, [onClose, otp, phone, refetchProfile, snackBar]);

  return (
    <Transition.Root appear show={isVisible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-xl relative">
                {isSubmitting ? (
                  <div className="absolute inset-0 bg-white opacity-70 z-20 flex justify-center items-center">
                    <Spinner className="w-8 h-8" />
                  </div>
                ) : null}
                <div className="font-semibold text-dark-gray-1 text-lg p-5">
                  Enter SMS Code
                </div>

                <div className="px-5 py-7 bg-light-gray-5 border-t border-light-gray-3">
                  A text message containing a 6-digit code has been sent to{' '}
                  {phone}.
                  <br />
                  Please enter it here to confirm your phone number.
                  <div className="flex items-center mt-4">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span className="ml-2"></span>}
                      renderInput={props => (
                        <input
                          {...props}
                          style={{}}
                          className="border focus:outline-none focus:ring-1 ring-primary rounded w-10 h-12 text-2xl font-semibold text-center text-black"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    onClick={handleSubmit}>
                    Confirm
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PhoneChangeOTPModal;
