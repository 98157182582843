import { makeAutoObservable, runInAction } from 'mobx';

import { Checklist } from '_models/milestone/checklist';
import { ChecklistStatus } from '_models/milestone/checklist-status-enum';
import { Milestone } from '_models/milestone/milestone';
import { MilestoneImageOrFile } from '_models/milestone/milestone-upload';
import { Stage } from '_models/milestone/stage';
import { ProjectUpdate } from '_models/project-update';
import { ProjectUpdateMessages } from '_models/project-update/project-update-message';
import { ProjectUpdateTypes } from '_models/project-update/project-update-type';
import { MilestoneService } from '_services/milestone-service';
import { profileService } from '_services/profile-service';
import { ProjectUpdateService } from '_services/project-update-service';
import useAuthStore from '_stores/useAuthStore';

import { ErrorResponse } from '../models/error-response';

class MilestoneStoreImplementation {
  errors: ErrorResponse[] = [];

  checklistItem?: Checklist;

  checklistItems: Checklist[] = [];

  projectMilestones?: Stage[] = [];

  projectMilestone?: Milestone;

  milestoneImages: [] = [];

  milestoneFile: File | undefined;

  milestoneFileData?: MilestoneImageOrFile;

  milestoneFileName = '';

  stageMilestones?: Stage[];

  milestoneIds?: number[] = [];

  milestoneImage?: MilestoneImageOrFile;

  loading = false;

  projectId = 0;

  isImageOrFile = true;

  constructor() {
    makeAutoObservable(this);
  }

  // getMilestones = async (id: number) => {
  //   this.projectId = id;
  //   const { data, errors } = await MilestoneService.getStagesWithMilestone(id);
  //   if (errors && errors.length > 0) {
  //     runInAction(() => {
  //       this.errors = errors;
  //     });
  //     return;
  //   }
  //   if (data) {
  //     runInAction(() => {
  //       this.projectMilestones = data;
  //       this.projectMilestones?.forEach(stage => {
  //         stage.project_milestone.forEach(item => {
  //           item.dates = [];
  //           item.project_checklist_item.forEach(e => {
  //             if (e.due_date) {
  //               item.dates.push(e.due_date);
  //             }
  //           });
  //           item.dates.sort(function (a: any, b: any) {
  //             return new Date(a).getTime() - new Date(b).getTime();
  //           });
  //           item.minDate = item.dates.length > 0 ? item.dates[0] : null;
  //         });
  //       });
  //     });
  //   }
  // };

  getMilestoneById = async (id: number) => {
    const { data, errors } = await MilestoneService.getMilestoneById(id);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    if (data) {
      if (data.length === 1) {
        const milestone = data[0];
        const uploadedByIds = milestone.project_milestone_file?.map(
          f => f.uploaded_by!
        );
        const { data: users } = await profileService.getUsersByIds(
          uploadedByIds || []
        );
        if (users) {
          milestone.project_milestone_file?.forEach(f => {
            f.uploaded_by_user = users.find(u => u.id === f.uploaded_by);
          });
        }
      }
      runInAction(() => {
        this.projectMilestone = data[0];
      });
    }
  };

  getChecklistItems = async (id: number) => {
    const { data, errors } = await MilestoneService.getChecklistItems(id);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    if (data) {
      runInAction(() => {
        this.checklistItems = data;
      });
    }
  };

  renameFile = async (id: number, rename: string, projectId: number) => {
    const { data, errors } = await MilestoneService.renameFile(id, rename);

    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
        this.loading = false;
      });
      return null;
    }
    runInAction(() => {
      this.loading = false;
    });

    const userId = useAuthStore.getState().profile?.id;
    if (!userId) {
      return;
    }

    const fileUpdate = {
      type: ProjectUpdateTypes.MILESTONE_FILE_RENAMED,
      project_id: projectId,
      action_taker_id: userId,
      message: ProjectUpdateMessages[ProjectUpdateTypes.MILESTONE_FILE_RENAMED],
      payload: {
        milestone: this.projectMilestone?.name ?? '',
        original_file: this.milestoneFileData?.name,
        new_file: rename,
      },
    };

    await this.createProjectUpdate(fileUpdate);
    return data;
  };

  addChecklistItem = async (
    projectId: number,
    id: number,
    name: string,
    dueDate?: Date | undefined
  ) => {
    const { data, errors } = await MilestoneService.addChecklistItem(
      'project_checklist_item',
      {
        project_milestone_id: id,
        name,
        due_date: dueDate,
      }
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    runInAction(() => {
      this.checklistItem = data!;
    });
    const currentUserId = useAuthStore.getState().profile?.id;
    if (!currentUserId) {
      return;
    }

    const checklistAdd = {
      type: ProjectUpdateTypes.CHECKLIST_ITEM_ADDED,
      project_id: projectId,
      action_taker_id: currentUserId,
      message: ProjectUpdateMessages[ProjectUpdateTypes.CHECKLIST_ITEM_ADDED],
      payload: {
        checklist_item_title: name,
        milestone: this.projectMilestone?.name,
      },
    };
    await this.createProjectUpdate(checklistAdd);
  };

  updateMilestoneNotes = async (id: any, notes: string, projectId: number) => {
    const { data, errors } = await MilestoneService.updateMilestoneNotes(
      id,
      notes
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
    }
    if (data) this.getMilestoneById(id);
    const name = this.projectMilestone?.name ?? '';

    const currentUserId = useAuthStore.getState().profile?.id;
    if (!currentUserId) {
      return;
    }

    const milestoneNoteUpdate = {
      type: ProjectUpdateTypes.MILESTONE_NOTE_UPDATED,
      project_id: projectId,
      action_taker_id: currentUserId,
      message: ProjectUpdateMessages[ProjectUpdateTypes.MILESTONE_NOTE_UPDATED],
      payload: {
        milestone: name,
      },
    };
    await this.createProjectUpdate(milestoneNoteUpdate);
  };

  uploadMilestoneImages = async (
    id: number,
    milestoneId: number,
    file: File,
    uploadedBy: string
  ) => {
    const timestamp = +new Date();
    const filePath = `${id}/milestone/${milestoneId}/images/${timestamp}_${file.name}`;
    const { data, errors } = await MilestoneService.uploadImagesOrFiles(
      filePath,
      file
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    if (data) {
      const { data: updateData, errors: updateErrors } =
        await MilestoneService.updateImagesOrFiles('project_milestone_image', {
          name: file.name,
          file_url: filePath,
          file_type: file.type,
          project_milestone_id: milestoneId,
          uploaded_by: uploadedBy,
        });
      if (updateErrors && updateErrors.length > 0) {
        runInAction(() => {
          this.errors = errors;
        });
        return;
      }
      await this.getMilestoneById(milestoneId);

      const currentUserId = useAuthStore.getState().profile?.id;
      if (!currentUserId) {
        return;
      }

      const milestoneFileUpload = {
        type: ProjectUpdateTypes.MILESTONE_IMAGE_UPLOADED,
        project_id: id,
        action_taker_id: currentUserId,
        message:
          ProjectUpdateMessages[ProjectUpdateTypes.MILESTONE_IMAGE_UPLOADED],
        payload: {
          new_file: filePath,
          milestone: this.projectMilestone?.name ?? '',
        },
      };
      await this.createProjectUpdate(milestoneFileUpload);

      return updateData;
    }
  };

  saveFile = (file: File) => {
    runInAction(() => {
      this.milestoneFile = file;
      this.milestoneFileName = file.name;
    });
  };

  saveFileName = (name: string) => {
    runInAction(() => {
      this.milestoneFileName = name;
    });
  };

  saveImageOrFile = (value: boolean) => {
    runInAction(() => {
      this.isImageOrFile = value;
    });
  };

  saveMilestoneIds = (ids: number[]) => {
    runInAction(() => {
      this.milestoneIds = ids;
    });
  };

  getMilestoneByStageId = async (id: number) => {
    const { data, errors } = await MilestoneService.getMilestoneByStageId(id);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    if (data) {
      runInAction(() => {
        this.stageMilestones = data;
      });
    }
  };

  // saveStageId = (id: number) => {
  //   runInAction(() => {
  //     this.stageId = id;
  //   });
  // };

  uploadMilestoneFile = async (
    id: number,
    milestoneIds: number[],
    fileName: string,
    file: File | undefined,
    uploadedBy: string
  ) => {
    const timestamp = +new Date();
    milestoneIds.forEach(async mId => {
      const filePath = `${id}/milestone/${mId}/files/${timestamp}_${fileName
        .split(' ')
        .join('_')}`;
      const { data, errors } = await MilestoneService.uploadImagesOrFiles(
        filePath,
        file!
      );
      if (errors && errors.length > 0) {
        runInAction(() => {
          this.errors = errors;
        });
      }
      if (data) {
        const fields = {
          name: file?.name || '',
          file_url: filePath,
          file_type: file?.type,
          project_milestone_id: mId,
          uploaded_by: uploadedBy,
        };
        const { data: updateData, errors: updateErrors } =
          await MilestoneService.updateImagesOrFiles(
            'project_milestone_file',
            fields
          );
        if (updateErrors && updateErrors.length > 0) {
          runInAction(() => {
            this.errors = errors;
          });
          return;
        }
        await this.getMilestoneById(mId);

        const currentUserId = useAuthStore.getState().profile?.id;
        if (!currentUserId) {
          return;
        }

        const milestoneFileUpload = {
          type: ProjectUpdateTypes.MILESTONE_FILE_UPLOADED,
          project_id: id,
          action_taker_id: currentUserId,
          message:
            ProjectUpdateMessages[ProjectUpdateTypes.MILESTONE_FILE_UPLOADED],
          payload: {
            new_file: filePath,
            milestone: this.projectMilestone?.name ?? '',
          },
        };
        await this.createProjectUpdate(milestoneFileUpload);
        return updateData;
      }
    });
  };

  changeMilestoneStatus = async (
    id: any,
    status: string,
    milestoneName: string
  ) => {
    const { errors } = await MilestoneService.changeMilestoneStatus(id, status);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }

    const currentUserId = useAuthStore.getState().profile?.id;
    if (!currentUserId) {
      return;
    }

    const milestoneStatusUpdate = {
      type:
        status === ChecklistStatus.Complete
          ? ProjectUpdateTypes.MILESTONE_CHECKED
          : ProjectUpdateTypes.MILESTONE_UNCHECKED,
      project_id: this.projectId,
      action_taker_id: currentUserId,
      message:
        ProjectUpdateMessages[
          status === ChecklistStatus.Complete
            ? ProjectUpdateTypes.MILESTONE_CHECKED
            : ProjectUpdateTypes.MILESTONE_UNCHECKED
        ],
      payload: { milestone: milestoneName },
    };
    await this.createProjectUpdate(milestoneStatusUpdate);
  };

  changeMilestoneHideStatus = async (id: number, status: string) => {
    const { errors } = await MilestoneService.updateHideStatus(
      'project_milestone',
      id,
      status
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }
  };

  changeStageStatus = async (id: number, status: string) => {
    const { errors } = await MilestoneService.updateStatus(
      'project_stage',
      id,
      status
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }
    return status;
  };

  updateComments = async (value: any, id: number, isImage: boolean) => {
    const { errors } = await MilestoneService.updateComments(
      value,
      id,
      isImage
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
    }
  };

  deleteMilestoneImageOrFile = async (
    projectId: number,
    id: number,
    isImage: boolean
  ) => {
    const { errors } = await MilestoneService.deleteMilestoneImageOrFile(
      id,
      isImage
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }

    const currentUserId = useAuthStore.getState().profile?.id;
    if (!currentUserId) {
      return;
    }

    const fileUpdate = {
      type:
        isImage === true
          ? ProjectUpdateTypes.MILESTONE_IMAGE_DELETED
          : ProjectUpdateTypes.MILESTONE_FILE_DELETED,
      project_id: projectId,
      action_taker_id: currentUserId,
      message:
        isImage === true
          ? ProjectUpdateMessages[ProjectUpdateTypes.MILESTONE_IMAGE_DELETED]
          : ProjectUpdateMessages[ProjectUpdateTypes.MILESTONE_FILE_DELETED],
      payload: { milestone: this.projectMilestone?.name ?? '' },
    };
    await this.createProjectUpdate(fileUpdate);
  };

  updateChecklist = async (
    projectId: number,
    id: number,
    column: string,
    item: { name?: string; due_date?: string }
  ) => {
    const isName = column === 'name';
    const value = isName ? item.name : item.due_date;
    const { errors } = await MilestoneService.updateChecklistItem(
      id,
      value ?? '',
      isName
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }

    const currentUserId = useAuthStore.getState().profile?.id;
    if (!currentUserId) {
      return;
    }

    const checklistAdd = {
      type: ProjectUpdateTypes.CHECKLIST_ITEM_EDITED,
      project_id: projectId,
      action_taker_id: currentUserId,
      message: ProjectUpdateMessages[ProjectUpdateTypes.CHECKLIST_ITEM_EDITED],
      payload: {
        checklist_item_title: item.name,
        milestone: this.projectMilestone?.name ?? '',
      },
    };
    await this.createProjectUpdate(checklistAdd);
  };

  getCommentsByImageOrFile = async (id: number) => {
    if (id <= 0) {
      return;
    }
    runInAction(() => {
      this.loading = true;
    });
    const { data, errors } = await MilestoneService.getMilestoneImageOrFileById(
      id,
      this.isImageOrFile
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
        this.loading = false;
      });
    }
    if (data) {
      runInAction(() => {
        this.milestoneImage = data!;
        this.loading = false;
      });
      return null;
    }
  };

  getMilestoneFileById = async (id: number) => {
    const { data, errors } = await MilestoneService.getFileById(id);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    runInAction(() => {
      this.milestoneFileData = data!;
    });
  };

  getChecklistItemById = async (id: number) => {
    const { data, errors } = await MilestoneService.getChecklistItemById(id);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    runInAction(() => {
      this.checklistItem = data!;
    });
    return data;
  };

  deleteChecklist = async (projectId: number, id: number, name: string) => {
    const { errors } = await MilestoneService.deleteChecklistItem(id);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }

    const currentUserId = useAuthStore.getState().profile?.id;
    if (!currentUserId) {
      return;
    }

    const checklistAdd = {
      type: ProjectUpdateTypes.CHECKLIST_ITEM_DELETED,
      project_id: projectId,
      action_taker_id: currentUserId,
      message: ProjectUpdateMessages[ProjectUpdateTypes.CHECKLIST_ITEM_DELETED],
      payload: {
        checklist_item_title: name,
        milestone: this.projectMilestone?.name ?? '',
      },
    };
    await this.createProjectUpdate(checklistAdd);
  };

  changeChecklistItemStatus = async (
    projectId: number,
    id: number,
    status: string,
    checklistName: string
  ) => {
    const currentUserId = useAuthStore.getState().profile?.id;
    if (!currentUserId) {
      return;
    }

    const { errors } = await MilestoneService.changeChecklistItemStatus(
      id,
      status,
      currentUserId
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }

    const checklistStatusUpdate = {
      type:
        status === ChecklistStatus.Complete
          ? ProjectUpdateTypes.CHECKLIST_ITEM_CHECKED
          : ProjectUpdateTypes.CHECKLIST_ITEM_UNCHECKED,
      project_id: projectId,
      action_taker_id: currentUserId,
      message:
        ProjectUpdateMessages[
          status === ChecklistStatus.Complete
            ? ProjectUpdateTypes.CHECKLIST_ITEM_CHECKED
            : ProjectUpdateTypes.CHECKLIST_ITEM_UNCHECKED
        ],
      payload: {
        checklist_item_title: checklistName,
        milestone: this.projectMilestone?.name ?? '',
      },
    };
    await this.createProjectUpdate(checklistStatusUpdate);
  };

  createProjectUpdate = async (projectUpdate: ProjectUpdate) => {
    const { errors } = await ProjectUpdateService.insert(projectUpdate);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }
  };
}

export const MilestoneStore = new MilestoneStoreImplementation();
