import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import { Organization, OrganizationUser } from '_types/organization';
import { Profile } from '_types/profile';

export type OrganizationUserWithDetails = OrganizationUser & {
  profile: Profile;
  organization: Organization;
};

export const fetchOrganizationUser = async (
  organizationId?: string | number,
  userId?: string
) => {
  if (!userId || !organizationId) {
    throw new Error('Missing user id or oraganization id');
  }

  const { data, error } = await supabase
    .from('organization_user')
    .select('*, profile(*), organization(*)')
    .eq('user_id', userId)
    .eq('organization_id', organizationId)
    .single();

  if (error) {
    throw error;
  }

  return data as OrganizationUserWithDetails;
};

const useOrganizationUser = (
  organizationId?: string | number,
  userId?: string
) =>
  useQuery(
    ['organizationUsers', userId],
    () => fetchOrganizationUser(organizationId, userId),
    {
      enabled: !!userId && !!organizationId,
    }
  );

export default useOrganizationUser;
