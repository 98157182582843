import { Dialog, Transition } from '@headlessui/react';

import { useFormik } from 'formik';
import { Fragment, useMemo, useState } from 'react';
import * as yup from 'yup';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

import useOrganizationUserDetailStore from './store';

interface FormValues {
  gender?: 'MALE' | 'FEMALE' | 'OTHER';
  firstName: string;
  lastName: string;
}

const EditProfileModal = () => {
  const organizationUserWithProfile = useOrganizationUserDetailStore(
    $ => $.organizationUserWithDetails
  );
  const isEditProfileModalVisible = useOrganizationUserDetailStore(
    $ => $.isEditProfileModalVisible
  );
  const setIsEditProfileModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsEditProfileModalVisible
  );
  const snackBar = useSnackBarStore();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const OrganizationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().trim().label('First Name').required(),
        lastName: yup.string().trim().label('Last Name').required(),
      }),
    []
  );

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      gender: organizationUserWithProfile?.profile.gender ?? undefined,
      firstName: organizationUserWithProfile?.profile.first_name ?? '',
      lastName: organizationUserWithProfile?.profile.last_name ?? '',
    },
    validationSchema: OrganizationSchema,
    onSubmit: async values => {
      if (!organizationUserWithProfile) return;
      try {
        setIsSubmitting(true);
        const { error } = await supabase
          .from('profile')
          .update({
            first_name: values.firstName,
            last_name: values.lastName,
            gender: values.gender,
          })
          .eq('id', organizationUserWithProfile.profile.id);
        if (error) {
          throw error;
        }
        snackBar.show('User updated successfully', 'success');
        queryClient.invalidateQueries({ queryKey: ['organizationUsers'] });
        setIsEditProfileModalVisible(false);
      } catch (err) {
        const errorMessage = await getErrorMessage(err);
        snackBar.show(errorMessage ?? 'User failed to update', 'error');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    setIsEditProfileModalVisible(false);
  };

  return (
    <Transition.Root appear show={isEditProfileModalVisible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsEditProfileModalVisible(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-xl relative">
                {isSubmitting ? (
                  <div className="absolute inset-0 bg-white opacity-70 z-20 flex justify-center items-center">
                    <Spinner className="w-8 h-8" />
                  </div>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  <div className="font-semibold text-dark-gray-1 text-lg p-5">
                    Edit Profile Info
                  </div>
                  <div className="px-5 py-7 bg-light-gray-5 border-t border-light-gray-3">
                    <div className="flex items-center mt-4">
                      <Label className="w-24">Gender</Label>
                      <div>
                        <select
                          name="role"
                          placeholder="Role"
                          disabled={isSubmitting}
                          onChange={formik.handleChange}
                          value={formik.values.gender}
                          onBlur={formik.handleBlur}
                          className="block w-48 ml-4 py-1.5 px-2.5 w-full rounded-lg border border-gray-200 sm:text-sm focus:outline-none">
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="OTHER">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">First Name</Label>
                      <div className="ml-4">
                        <input
                          name="firstName"
                          placeholder="e.g. John"
                          disabled={isSubmitting}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 sm:text-sm disabled:bg-light-gray-3 focus:outline-none"
                        />
                        {formik.errors.firstName && formik.touched.firstName ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Last Name</Label>
                      <div className="ml-4">
                        <input
                          name="lastName"
                          placeholder="e.g. Tan"
                          disabled={isSubmitting}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 sm:text-sm disabled:bg-light-gray-3 focus:outline-none"
                        />
                        {formik.errors.lastName && formik.touched.lastName ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                    <Button color="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button disabled={isSubmitting} type="submit">
                      Save
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditProfileModal;
