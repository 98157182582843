import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';

import { Profile } from '_types/profile';

import { supabaseService } from './supabase-service';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_VAPID_KEY,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = (profile: Profile) => {
  return getToken(messaging, { vapidKey: REACT_APP_FIREBASE_VAPID_KEY })
    .then(async currentToken => {
      if (currentToken) {
        // Perform any other neccessary action with the token
        await supabaseService.setFCMToken(profile.id, currentToken);
        return;
      }
      // Show permission request UI
      // eslint-disable-next-line no-console
      console.log(
        'No registration token available. Request permission to generate one.'
      );
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      // console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve: (payload: MessagePayload) => void) => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
