import * as Yup from 'yup';

export const profileValidator = Yup.object()
  .shape({
    first_name: Yup.string()
      .trim()
      .max(50)
      .min(3)
      .label('First name')
      .required(),
    last_name: Yup.string().trim().max(50).label('Last name').required(),
    mobile_number: Yup.string().label('Phone').min(8).max(12).nullable(),
    // .required()
    // .typeError('Mobile number is a required field'),
    gender: Yup.string()
      .label('Gender')
      .required()
      .typeError('Gender is a required field'),
    email: Yup.string()
      .trim()
      .email('Please enter a valid email id')
      .label('Email')
      .required()
      .typeError('Email is a required field'),
  })
  .required();
