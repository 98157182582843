import { Tooltip } from '@mui/material';

export const FileNotFound = (props: { fileName: string; fileUrl: string }) => {
  return (
    <div className="text-center">
      <div className="text-mid-gray-1 text-lg font-semibold">
        No File Preview Available
      </div>
      <Tooltip title="Download file">
        <a href={props.fileUrl} className="text-primary">
          {props.fileName}
        </a>
      </Tooltip>
    </div>
  );
};
