import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import { Status } from '_types/common';
import { Organization, OrganizationUser } from '_types/organization';
import { Profile } from '_types/profile';

export interface Filter {
  status?: Status | null;
  keyword?: string;
}

export type OrganizationUserWithProfile = OrganizationUser & {
  profile: Profile;
};

export interface OrganizationWithUsers extends Organization {
  organization_user: OrganizationUserWithProfile[];
}

export const fetchOrganization = async (
  id?: number
): Promise<OrganizationWithUsers> => {
  if (!id) {
    throw new Error('Missing organization id');
  }
  const { data, error } = await supabase
    .from('organization')
    .select('*, organization_user(*, profile(*))')
    .eq('id', id)
    .single();

  if (error) {
    throw error;
  }

  return data as OrganizationWithUsers;
};

const useOrganisation = (id?: number) =>
  useQuery(['organization', id], () => fetchOrganization(id), {
    enabled: !!id,
  });

export default useOrganisation;
