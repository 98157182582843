import {
  ArrowDownTrayIcon,
  ChatBubbleLeftRightIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Badge, BadgeProps, Box, Grid, styled } from '@mui/material';

import { saveAs } from 'file-saver';
import moment from 'moment';
import { useState } from 'react';

import { Milestone } from '_models/milestone/milestone';
import { MilestoneImageOrFile } from '_models/milestone/milestone-upload';

import { DeleteDialog } from './delete-dialog';

const { REACT_APP_SUPABASE_URL } = process.env;
const STORAGE_BUCKET_URL = `${REACT_APP_SUPABASE_URL}/storage/v1/object/public`;
const STORAGE_BUCKET_KEY_AVATARS = 'project';

export const ManageFiles = (props: {
  projectMilestone: Milestone;
  deleteMilestoneImageOrFile: (
    projectId: number,
    id: number,
    value: boolean
  ) => void;
  getMilestoneById: (id: number) => void;
  handleClickOpen: (
    item: MilestoneImageOrFile,
    isImage: boolean,
    isComments?: boolean
  ) => void;
  projectId: number;
  milestoneId: number;
}) => {
  const {
    projectMilestone,
    deleteMilestoneImageOrFile,
    getMilestoneById,
    handleClickOpen,
    projectId,
    milestoneId,
  } = props;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [fileId, setFileId] = useState<number>();

  const currentDate = moment(new Date());

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleClose = () => {
    setOpenConfirmDelete(false);
  };

  const handleSave = (_e: any, item: MilestoneImageOrFile) => {
    const imageUrl = `${STORAGE_BUCKET_URL}/${STORAGE_BUCKET_KEY_AVATARS}/${item.file_url}`;
    saveAs(imageUrl, item.name);
  };

  const handleDeleteFile = (id: number) => {
    setFileId(id);
    setOpenConfirmDelete(true);
  };

  const handleDeleteCompleted = async () => {
    setOpenConfirmDelete(false);
    if (fileId) {
      await deleteMilestoneImageOrFile(projectId, fileId, false);
      await getMilestoneById(milestoneId);
    }
    handleClose();
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <>
      {(projectMilestone?.project_milestone_file || [])
        .slice()
        .sort((a, b) => a.id! - b.id!)
        .map((item: any, index: number) => {
          return (
            <div key={index}>
              <div className="bg-light-gray-5 rounded-md mt-2 p-4">
                <Grid container sx={{ justifyContent: 'space-between' }}>
                  <Grid
                    item
                    sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Box>
                      <PictureAsPdfOutlinedIcon
                        sx={{ ml: 2, color: '#E24C5F' }}
                      />
                    </Box>
                    <Box>
                      <span
                        id="milestones-filename-click"
                        className="cursor-pointer font-semibold text-dark-gray-1"
                        onClick={() => handleClickOpen(item, false)}>
                        {item.name}
                      </span>
                      <div className="text-mid-gray-1 mt-1 text-xs">
                        {currentDate.diff(moment(item.created_at), 'days')}{' '}
                        {currentDate.diff(moment(item.created_at), 'days') <= 1
                          ? 'day'
                          : 'days'}{' '}
                        ago by{' '}
                        <span className="text-primary font-semibold">
                          {`${item.uploaded_by_user?.first_name} ${item.uploaded_by_user?.last_name}`}
                        </span>
                      </div>
                    </Box>
                  </Grid>
                  <div className="text-mid-gray-5 flex flex-end items-center gap-x-4">
                    <PencilSquareIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => handleClickOpen(item, false, false)}
                    />
                    <ArrowDownTrayIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={e => handleSave(e, item)}
                    />
                    <TrashIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => handleDeleteFile(item.id)}
                    />
                    <ChatBubbleLeftRightIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => handleClickOpen(item, false)}
                    />
                  </div>
                </Grid>
              </div>
            </div>
          );
        })}
      <DeleteDialog
        openConfirmDelete={openConfirmDelete}
        closeConfirmDelete={closeConfirmDelete}
        handleDeleteCompleted={handleDeleteCompleted}
      />
    </>
  );
};
