import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';

import { OrganizationUserWithDetails } from './useOrganizationUser';

export const fetchOrganizationUsers = async (userIds?: string[]) => {
  if (!userIds) {
    throw new Error('Missing user ids');
  }

  const organization = useAuthStore.getState().selectedOrganization;
  if (!organization) {
    throw new Error('Missing organization');
  }

  const { data, error } = await supabase
    .from('organization_user')
    .select('*, profile(*), organization(*)')
    .eq('organization_id', organization.id)
    .in('user_id', userIds);
  if (error) {
    throw error;
  }

  return data as OrganizationUserWithDetails[];
};

const useOrganizationUsers = (userIds?: string[]) =>
  useQuery(
    ['organizationUsers', userIds],
    () => fetchOrganizationUsers(userIds),
    {
      enabled: !!userIds,
    }
  );

export default useOrganizationUsers;
