import './App.css';

import { Alert, Snackbar } from '@mui/material';

import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import RequireAuth from '_components/auth/require-auth';
import { Notification } from '_components/notification';
import Spinner from '_components/spinner';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';

import { Authentication } from './pages/authentication/authentication';
import DownloadApp from './pages/authentication/download-app';
import { ForgotPassword } from './pages/authentication/forgot-password';
import ResetPassword from './pages/authentication/reset-password';
import SetPassword from './pages/authentication/set-password';
import { ChatList } from './pages/chat/chat-list';
import ClientDetail from './pages/ClientDetail';
import CurrentOrganizationClientList from './pages/CurrentOrganizationClientList';
import CurrentOrganizationStaffList from './pages/CurrentOrganizationStaffList';
import OrganizationDetail from './pages/OrganizationDetail';
import OrganizationHome from './pages/OrganizationHome';
import OrganizationInfoEdit from './pages/OrganizationInfoEdit';
import OrganizationUserDetail from './pages/OrganizationUserDetail';
import { Profile } from './pages/profile/profile';
import { CreateProject } from './pages/project/create-project';
import { ProgressTracker } from './pages/project/progress-tracker';
import { ProjectPage } from './pages/project/project';
import { ProjectDetails } from './pages/project/project-details';
import { ProjectMilestone } from './pages/project/project-milestone';
import TemplateDetail from './pages/TemplateDetail';
import Templates from './pages/Templates';
import Layout from './shared/layouts/layout';

function App() {
  const isLoading = useAuthStore($ => $.isLoading);
  const restore = useAuthStore($ => $.restore);
  const snackBar = useSnackBarStore();

  useEffect(() => {
    restore();
  }, [restore]);

  if (isLoading) {
    return (
      <div className="w-full py-10 flex justify-center">
        <Spinner className="w-8 h-8 z-15" />
      </div>
    );
  }

  return (
    <>
      <Notification />
      {snackBar.isVisible ? (
        <Snackbar
          id="error-wrong-pwd"
          open={snackBar.isVisible}
          autoHideDuration={3000}
          onClose={snackBar.hide}>
          <Alert
            title={snackBar.title}
            onClose={snackBar.hide}
            severity={snackBar.severity}
            sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      ) : null}
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <RequireAuth>
                <DownloadApp />
              </RequireAuth>
            }
            path="/download-app"
          />
          <Route element={<Authentication />} path="/login" />
          <Route
            element={
              <RequireAuth>
                <SetPassword />
              </RequireAuth>
            }
            path="/set-password"
          />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<Layout />}>
            {/* @olive/public-routes */}
            <Route path="/" element={<Navigate to="/projects" />} />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/projects"
              element={
                <RequireAuth>
                  <ProjectPage />
                </RequireAuth>
              }
            />
            <Route
              path="/projects/:id"
              element={
                <RequireAuth>
                  <ProjectDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/projects/:id/milestone/:milestone_id"
              element={
                <RequireAuth>
                  <ProjectMilestone />
                </RequireAuth>
              }
            />
            <Route
              path="/projects/:id/history"
              element={
                <RequireAuth>
                  <ProgressTracker />
                </RequireAuth>
              }
            />
            <Route
              path="/projects/create"
              element={
                <RequireAuth>
                  <CreateProject />
                </RequireAuth>
              }
            />
            <Route
              path="/projects/:id/edit"
              element={
                <RequireAuth>
                  <CreateProject />
                </RequireAuth>
              }
            />

            <Route
              path="/organizations"
              element={
                <RequireAuth>
                  <OrganizationHome />
                </RequireAuth>
              }
            />

            <Route
              path="/organizations/:id"
              element={
                <RequireAuth>
                  <OrganizationDetail />
                </RequireAuth>
              }
            />

            <Route
              path="/organizations/:organizationId/users/:userId"
              element={
                <RequireAuth>
                  <OrganizationUserDetail />
                </RequireAuth>
              }
            />

            <Route path="/people" element={<Navigate to="/people/staff" />} />

            <Route
              path="/people/staff"
              element={
                <RequireAuth>
                  <CurrentOrganizationStaffList />
                </RequireAuth>
              }
            />

            <Route
              path="/people/staff/:userId"
              element={
                <RequireAuth>
                  <OrganizationUserDetail />
                </RequireAuth>
              }
            />

            <Route
              path="/people/clients"
              element={
                <RequireAuth>
                  <CurrentOrganizationClientList />
                </RequireAuth>
              }
            />

            <Route
              path="/people/clients/:userId"
              element={
                <RequireAuth>
                  <ClientDetail />
                </RequireAuth>
              }
            />

            <Route
              path="/chats"
              element={
                <RequireAuth>
                  <ChatList />
                </RequireAuth>
              }
            />

            <Route
              path="/manage/templates"
              element={
                <RequireAuth>
                  <Templates />
                </RequireAuth>
              }
            />

            <Route
              path="/manage/templates/:projectTypeId"
              element={
                <RequireAuth>
                  <TemplateDetail />
                </RequireAuth>
              }
            />

            <Route
              path="/manage/organisation-info"
              element={
                <RequireAuth>
                  <OrganizationInfoEdit />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
