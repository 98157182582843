import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { Fragment, useState } from 'react';

interface Option {
  key: string | number;
  name: string;
}

interface Props {
  options: Option[];
  value?: string | number;
  label?: string;
  onChange: (value: string) => void;
}

export default function Autocomplete({
  value,
  onChange,
  options,
  label,
}: Props) {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option =>
          option.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );
  return (
    <Combobox value={value} onChange={onChange} nullable>
      <div className="relative">
        <div className="relative w-full cursor-default rounded-md bg-white">
          <Combobox.Input
            className="w-full border border-gray-300 rounded-md py-2 pl-3 pr-16 text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
            placeholder={label}
            displayValue={(val: string | number) =>
              options.find(opt => opt.key === val)?.name || ''
            }
            onChange={event => setQuery(event.target.value)}
          />
          {value ? (
            <div
              className="absolute inset-y-0 right-6 flex items-center pr-2 cursor-pointer"
              onClick={() => onChange('')}>
              <XCircleIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          ) : null}
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}>
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredOptions.map(option => (
                <Combobox.Option
                  key={option.key}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-primary' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={option.key}>
                  {({ selected, active }) => {
                    return (
                      <>
                        <div className="flex">
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'truncate'
                            )}>
                            {option.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    );
                  }}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
