import { Dialog, Transition } from '@headlessui/react';

import { useFormik } from 'formik';
import { Fragment, useState } from 'react';
import * as yup from 'yup';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';

import useOrganizationStore from './useOrganizationStore';

interface FormValues {
  name: string;
}

const OrganizationSchema = yup.object().shape({
  name: yup.string().required('Organisation name is required'),
});

const CreateOrganizationModal = () => {
  const isCreateModalVisible = useOrganizationStore(
    $ => $.isCreateModalVisible
  );
  const setIsCreateModalVisible = useOrganizationStore(
    $ => $.setIsCreateModalVisible
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
    },
    validationSchema: OrganizationSchema,
    onSubmit: async values => {
      try {
        setIsSubmitting(true);
        const { data: createdOrganization, error } = await supabase
          .from('organization')
          .insert(values)
          .select()
          .single();
        if (error) {
          throw error;
        }

        const { data: projectTemplates, error: getProjectTemplateError } =
          await supabase.from('project_template').select();
        if (getProjectTemplateError) {
          throw getProjectTemplateError;
        }

        for (let i = 0; i < projectTemplates.length; i++) {
          const projectTemplate = projectTemplates[i];
          const { error: insertProjectTypeError } = await supabase
            .from('project_type')
            .insert({
              name: projectTemplate.name,
              template: projectTemplate.template,
              organization_id: createdOrganization.id,
              sequence: i + 1,
            });
          if (insertProjectTypeError) {
            console.log(insertProjectTypeError);
          }
        }

        queryClient.invalidateQueries({ queryKey: ['organizations'] });
        formik.resetForm();
        setIsCreateModalVisible(false);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    setIsCreateModalVisible(false);
    formik.resetForm();
  };

  return (
    <Transition.Root show={isCreateModalVisible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setIsCreateModalVisible}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg relative">
                {isSubmitting ? (
                  <div className="absolute inset-0 bg-white opacity-70 z-20 flex justify-center items-center">
                    <Spinner className="w-8 h-8" />
                  </div>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  <div className="font-semibold text-dark-gray-1 text-lg p-5">
                    Create Organisation
                  </div>
                  <div className="px-5 py-7 bg-light-gray-5 border-t border-light-gray-3">
                    <div className="relative flex items-center">
                      <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                        Organisation Details
                      </span>
                      <div className="flex-grow border-t border-light-gray-3 ml-3" />
                    </div>
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Name*</Label>
                      <div className="ml-4">
                        <input
                          name="name"
                          title="Name"
                          placeholder="e.g. Sunnyside Studios"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          className="w-64 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {formik.errors.name && formik.touched ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                    <Button color="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button type="submit">Submit</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateOrganizationModal;
