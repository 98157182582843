import { ClockIcon } from '@heroicons/react/24/solid';

import { flatMap } from 'lodash';
import moment from 'moment';
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '_components/button';
import useProject from '_hooks/useProjectBasicInfo';
import useProjectUpdates, {
  ProjectUpdatePreloaded,
} from '_hooks/useProjectUpdates';
import { ProjectStore } from '_store/project-store';

export const ProgressTracker = () => {
  const { getProjectUpdate } = ProjectStore;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isHistory, setIsHistory] = useState(false);

  const projectId = useMemo<number | null>(() => {
    const idAsNumber = Number(id);
    return !isNaN(idAsNumber) ? idAsNumber : null;
  }, [id]);

  const { data: project } = useProject(projectId);
  const { data: projectUpdates } = useProjectUpdates(projectId ?? 0);

  const projectUpdate = useMemo(() => {
    return flatMap(
      projectUpdates?.pages,
      projectUpdate => projectUpdate.projectUpdates
    );
  }, [projectUpdates]);

  useEffect(() => {
    (async function () {
      if (!projectId) {
        return;
      }
      setIsHistory(
        location.pathname === `/projects/${id}/history` ? true : false
      );
      await getProjectUpdate(projectId);
    })();
  }, [
    getProjectUpdate,
    location.pathname,
    id,
    isHistory,
    setIsHistory,
    projectId,
  ]);

  const handleTrackerHistory = () => {
    navigate(`/projects/${id}/history`);
  };

  const replaceString = (value: string, data: ProjectUpdatePreloaded) => {
    let myString = value;

    const replaceArray = [
      'user',
      'action-taker',
      'project name',
      'milestone name',
      'new checklist item title',
      'checklist item title',
      'original-file-name',
      'new-file-name',
    ];

    const replaceWith = [
      'user',
      'action_taker',
      'project_name',
      'milestone_name',
      'new_checklist_item_title',
      'checklist_item_title',
      'original_file_name',
      'new_file_name',
    ];

    const targetUserName = [
      data?.targetUser?.first_name,
      data?.targetUser?.last_name,
    ]
      .filter(s => s)
      .join(' ')
      .trim();

    const dataMap: { [key: string]: ReactNode } = {
      user: (
        <span className="text-primary">
          {targetUserName ? targetUserName : 'Someone'}
        </span>
      ),
      action_taker: (
        <span className="text-primary">{`${data?.profile?.first_name} ${data?.profile?.last_name}`}</span>
      ),
      project_name: <span className="font-semibold">{`${project?.name}`}</span>,
      milestone_name: `${data?.payload?.milestone}`,
      new_checklist_item_title: `${data?.payload?.checklist_item_title}`,
      checklist_item_title: `${data?.payload?.checklist_item_title}`,
      original_file_name: `${data?.payload?.original_file}`,
      new_file_name: `${data?.payload?.new_file}`,
    };

    for (let i = 0; i < replaceArray.length; i++) {
      myString = myString.replace(
        new RegExp('"{' + replaceArray[i] + '}"', 'gi'),
        replaceWith[i]
      );
      myString = myString.replace(
        new RegExp('{' + replaceArray[i] + '}', 'gi'),
        replaceWith[i]
      );
    }

    const result: ReactNode[] = [];

    myString.split(' ').forEach(key => {
      result.push(dataMap[key] ? dataMap[key] : key);
    });

    return result;
  };

  return (
    <div className="border border-light-gray-3 rounded-xl overflow-hidden">
      <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
        <div className="font-semibold">Progress Tracker</div>
        {!isHistory && (
          <Button color="secondary" onClick={handleTrackerHistory}>
            <ClockIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            View Project History
          </Button>
        )}
      </div>
      <div className="p-5 max-h-60 overflow-auto border-t">
        {(projectUpdate || []).map((e, i) => {
          const date = moment(new Date(e.updated_at ?? '')).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          return (
            <div key={i} className={i !== 0 ? 'mt-2' : ''}>
              <span className="leading-6 text-mid-gray-3">{date}</span>
              <span className="leading-6 ml-3">
                {replaceString(e.message ?? '', e).map((node, index) => (
                  <Fragment key={index}>{node} </Fragment>
                ))}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
