import { Popover, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import {
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import { Avatar } from '@mui/material';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { queryClient } from '_clients/queryClient';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

import { getProfileOrDefault } from '../models/profile';
import Notifications from './notifications';
import useLayoutStore from './store';

export const PrimarySearchAppBar = observer(() => {
  const navigate = useNavigate();
  const snackBar = useSnackBarStore();

  const profile = useAuthStore($ => $.profile);
  const signOut = useAuthStore($ => $.signOut);
  const organizations = useAuthStore($ => $.organizations);

  const selectedOrganization = useAuthStore($ => $.selectedOrganization);
  const changeOrganization = useAuthStore($ => $.changeOrganization);

  const setIsBusy = useLayoutStore($ => $.setIsBusy);

  const [userData, setUserData] = useState<{
    id: string;
    name: string;
    image: string;
  } | null>(null);

  useEffect(() => {
    if (!profile) {
      return;
    }
    (async function () {
      const {
        first_name: firstName,
        last_name: lastName,
        profile_image_url: profileImageUrl,
      } = getProfileOrDefault(profile);

      setUserData({
        id: profile.id,
        name: `${firstName} ${lastName}`,
        image: `${profileImageUrl}`,
      });
    })();
  }, [profile]);

  const navigateToProfile = useCallback(() => {
    navigate('/profile');
  }, [navigate]);

  const navigateToChatList = () => {
    navigate('/chats');
  };

  const renderMenu = useMemo(() => {
    return (
      <Popover className="relative flex items-center">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={classNames(
                'ml-2 rounded-md text-base focus:outline-none focus:ring-1 ring-white'
              )}>
              <ChevronDownIcon className="w-4 h-4 text-white" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="w-48 bg-white rounded-lg border border-light-gray-5 shadow-lg absolute top-3 right-3 z-10">
                <div className="py-2 px-3">
                  <div
                    className="block rounded-md transition duration-150 ease-in-out hover:bg-light-gray-5 py-2 px-3 cursor-pointer"
                    onClick={() => {
                      navigateToProfile();
                      close();
                    }}>
                    <div className="flex justify-start gap-2 items-center">
                      <UserCircleIcon className="h-5 w-5 text-mid-gray-5" />
                      <span className="text-dark-gray-2">Your Account</span>
                    </div>
                  </div>
                </div>
                <div className="w-full border-t border-light-gray-3" />
                <div className="py-2 px-3">
                  <div
                    className="block rounded-md transition duration-150 ease-in-out hover:bg-light-gray-5 py-2 px-3 cursor-pointer"
                    onClick={async () => {
                      await signOut();
                      navigate('/login');
                    }}>
                    <div className="flex justify-start gap-2 items-center">
                      <ArrowLeftOnRectangleIcon className="h-5 w-5 text-mid-gray-5" />
                      <span className="text-dark-gray-2">Logout</span>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }, [navigateToProfile, signOut, navigate]);

  const handleChangeOrganization = useCallback(
    async (organizationId: number) => {
      try {
        setIsBusy(true);
        await changeOrganization(organizationId);
        await queryClient.resetQueries();
        navigate('/');
      } catch (err) {
        const errorMessage = await getErrorMessage(err);
        snackBar.show(errorMessage, 'error');
      } finally {
        setIsBusy(false);
      }
    },
    [changeOrganization, navigate, setIsBusy, snackBar]
  );

  const renderSelectOrganizationDropdown = useMemo(() => {
    if (!organizations?.length) {
      return null;
    }

    return (
      <Popover className="relative flex items-center">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={classNames(
                'ml-2 rounded-md text-base hover:opacity-70 focus:outline-none focus:ring-1 ring-white'
              )}>
              <ChevronDownIcon className="w-4 h-4 text-white" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="w-[280px] bg-white rounded-lg border border-light-gray-5 shadow-lg absolute top-3 z-10 py-1.5">
                {organizations.map(organization => (
                  <div
                    key={organization.id}
                    className={classNames(
                      'block rounded-md transition duration-150 ease-in-out py-1.5 px-5',
                      organization.id === selectedOrganization?.id
                        ? 'opacity-50 cursor-not-allowed'
                        : 'hover:bg-light-gray-5 cursor-pointer'
                    )}
                    onClick={() => {
                      if (organization.id === selectedOrganization?.id) {
                        return;
                      }
                      close();
                      handleChangeOrganization(organization.id);
                    }}>
                    <div className="flex justify-start gap-2 items-center">
                      <div className="h-8 w-8 rounded-full overflow-hidden bg-mid-gray-5 shrink-0">
                        {organization.cover_image_url ? (
                          <img
                            src={organization.cover_image_url}
                            className="h-full w-full object-cover"
                            alt=""
                          />
                        ) : null}
                      </div>
                      <span className="text-dark-gray-2">
                        {organization.name}
                      </span>
                    </div>
                  </div>
                ))}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }, [handleChangeOrganization, organizations, selectedOrganization]);

  const totalUnreadCount = useAuthStore($ => $.unreadCount);

  const leftTitle = useMemo(() => {
    if (profile?.role === 'SUPER_ADMIN') {
      return 'Sunnyside';
    }
    return selectedOrganization?.name ?? 'No Organisation';
  }, [profile, selectedOrganization]);

  return (
    <div className="bg-primary py-5 px-4">
      <div className="max-w-[1440px] mx-auto w-full flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <div className="h-8 w-8 rounded-full overflow-hidden bg-white">
            {selectedOrganization?.cover_image_url ? (
              <img
                src={selectedOrganization.cover_image_url}
                className="h-full w-full object-cover"
                alt=""
              />
            ) : null}
          </div>
          <Link to="/" className="text-white">
            {leftTitle}
          </Link>
          {renderSelectOrganizationDropdown}
        </div>

        <div className="flex items-center">
          <Notifications />
          {profile?.role !== 'SUPER_ADMIN' && (
            <div
              className="relative cursor-pointer ml-8 text-white hover:opacity-90"
              onClick={navigateToChatList}
              aria-label={`show ${totalUnreadCount} new chats`}>
              <ChatBubbleLeftRightIcon className="w-6 h-6" />
              <div className="absolute w-4 h-4 -bottom-1 -right-1 rounded-full bg-red-400 text-white text-xxs font-semibold flex justify-center items-center">
                {totalUnreadCount}
              </div>
            </div>
          )}
          <div className="ml-8">
            {userData && userData.image ? (
              <Avatar
                sx={{ width: 32, height: 32 }}
                alt={`${userData?.name}`}
                src={userData.image}
                id="profile-menu-lnk"
              />
            ) : (
              <Avatar src="/broken-image.jpg" />
            )}
          </div>
          <div className="ml-2 text-white">{`${userData?.name}`}</div>
          {renderMenu}
        </div>
      </div>
    </div>
  );
});
