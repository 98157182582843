import chatClient from '_clients/chatClient';
import { supabase } from '_clients/supabaseClient';
import { Profile } from '_types/profile';

export const CHANNEL_TYPE_MESSAGING = 'messaging';

export const chatService = {
  async removeChannelUser(project_id: number, user_id: string) {
    const channel = await chatClient.getChannelById(
      CHANNEL_TYPE_MESSAGING,
      `${project_id.toString()}_000`,
      {}
    );
    try {
      return await channel.removeMembers([user_id]);
    } catch (e) {
      return undefined;
    }
  },

  async setFrozenChatForProjectId(
    project: { id?: number; name?: string; coverImageUrl?: string } | null,
    frozen: boolean,
    user_profile?: Profile
  ): Promise<void> {
    if (!project || !project.id || !user_profile) return;

    const { data: projectChats, error } = await supabase
      .from('project_chat')
      .select()
      .eq('project_id', project.id);
    if (error) {
      throw error;
    }

    if (!projectChats || projectChats.length === 0) {
      return;
    }

    for (let i = 0; i < projectChats.length; i++) {
      const channel = chatClient.getChannelById(
        CHANNEL_TYPE_MESSAGING,
        projectChats[i].chat_id,
        {}
      );

      const message = frozen
        ? `${user_profile.first_name} ${user_profile.last_name} has archived this project`
        : `${user_profile.first_name} ${user_profile.last_name} has reactivated this project`;

      await channel.update(
        {
          name: project.name,
          image: project.coverImageUrl,
          frozen,
        },
        {
          text: message,
          user_id: user_profile.id,
        }
      );
    }
  },
};
