import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import { Status } from '_types/common';
import { Organization, OrganizationUser } from '_types/organization';

export interface Filter {
  status?: Status | null;
  keyword?: string;
}

export interface OrganizationWithUsers extends Organization {
  organization_user: OrganizationUser[];
}

export const fetchOrganizations = async (
  filter?: Filter
): Promise<OrganizationWithUsers[]> => {
  let query = supabase
    .from('organization')
    .select('*, organization_user(*, profile(*))');

  if (filter) {
    if (filter.keyword) {
      query = query.ilike('name', `%${filter.keyword.replace(/ /g, '+')}%`);
    }
    if (filter.status) {
      query = query.eq('status', filter.status);
    }
  }

  const { data: organizations, error } = await query.order('created_at', {
    ascending: false,
  });

  if (error) {
    throw error;
  }

  return organizations;
};

const useOrganizations = (filters?: Filter, enabled?: boolean) =>
  useQuery(['organizations', filters], () => fetchOrganizations(filters), {
    enabled,
  });

export default useOrganizations;
