import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
  DocumentIcon,
  PhotoIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { Avatar } from '@mui/material';

import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Fragment, useMemo, useState } from 'react';

import { DeleteDialog } from '_components/delete-dialog';
import { Milestone } from '_models/milestone/milestone';
import { MilestoneImageOrFile } from '_models/milestone/milestone-upload';
import { MilestoneStore } from '_store/milestone-store';
import useAuthStore from '_stores/useAuthStore';
import { Project } from '_types/project';

import Button from './button';
import { DocViewer } from './doc-viewer';
import Spinner from './spinner';

export const MilestoneComments = observer(
  (props: {
    open: boolean;
    handleClickOpen: (item: MilestoneImageOrFile, isImage: boolean) => void;
    selectedValue: MilestoneImageOrFile;
    project: Project;
    data: Milestone;
    loading: boolean;
    imageId?: number;
    commentValue: string;
    setCommentValue: any;
    handleClose: () => void;
    milestoneId: number;
    projectId: number;
  }) => {
    const {
      open,
      handleClickOpen,
      selectedValue,
      project,
      data,
      loading,
      imageId,
      commentValue,
      setCommentValue,
      handleClose,
      milestoneId,
      projectId,
    } = props;
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const { REACT_APP_SUPABASE_URL } = process.env;
    const STORAGE_BUCKET_URL = `${REACT_APP_SUPABASE_URL}/storage/v1/object/public`;
    const STORAGE_BUCKET_KEY_AVATARS = 'project';
    const {
      deleteMilestoneImageOrFile,
      getMilestoneById,
      updateComments,
      getCommentsByImageOrFile,
      milestoneImage,
      isImageOrFile,
    } = MilestoneStore;
    const imageOrFileItems =
      (isImageOrFile
        ? data?.project_milestone_image
        : data?.project_milestone_file) || [];

    const profile = useAuthStore($ => $.profile);
    const canPerformAction = useAuthStore($ => $.canPerformAction);

    const handlePrevious = async () => {
      const { id: mId } = selectedValue;
      await getCommentsByImageOrFile(mId!);
      const current = imageOrFileItems.findIndex(i => i.id === mId);
      if (current === 0) {
        return;
      }
      const newItem = imageOrFileItems[current - 1];
      await handleClickOpen(newItem, isImageOrFile);
    };

    const handleNext = async () => {
      const { id: mId } = selectedValue;
      await getCommentsByImageOrFile(mId!);
      const current = imageOrFileItems!.findIndex(i => i.id === mId);
      if (current === imageOrFileItems!.length - 1) {
        return;
      }
      const newItem = imageOrFileItems![current + 1];
      handleClickOpen(newItem, isImageOrFile);
    };

    const handleConfirmDelete = () => {
      setOpenConfirmDelete(true);
    };

    const handleDeleteCompleted = async () => {
      setOpenConfirmDelete(false);
      if (imageId) {
        deleteMilestoneImageOrFile(projectId, imageId, true);
      }
      handleClose();
      await getMilestoneById(+milestoneId!);
    };

    const closeConfirmDelete = () => {
      setOpenConfirmDelete(false);
    };

    const onHandleSubmit = async () => {
      if (!imageId || !profile) return;
      const commentsArray = milestoneImage?.comments || [];
      const jsonData = {
        comment: commentValue,
        user_id: profile.id,
        timestamp: new Date(),
      };
      commentsArray.push(jsonData);
      await updateComments(commentsArray, imageId, isImageOrFile);
      await setCommentValue('');
      await getCommentsByImageOrFile(imageId);
    };

    const isImage = useMemo(
      () => selectedValue.file_type?.startsWith('image'),
      [selectedValue]
    );

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          open={open}
          className="relative z-10"
          onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                  <div className="flex">
                    <div
                      className={classNames(
                        'min-w-[800px] min-h-[800px] flex-1 relative p-5',
                        isImage ? 'bg-dark-gray-1' : 'bg-light-gray-1'
                      )}>
                      <div
                        onClick={handlePrevious}
                        className="w-10 h-10 flex items-center justify-center bg-white rounded-full opacity-80 top-[50%] left-6 absolute cursor-pointer">
                        <ArrowLeftIcon className="w-5 h-5" />
                      </div>
                      <DocViewer
                        fileUrl={`${STORAGE_BUCKET_URL}/${STORAGE_BUCKET_KEY_AVATARS}/${selectedValue.file_url}`}
                        fileType={selectedValue.file_type}
                        fileName={selectedValue.name}
                      />
                      <div
                        onClick={handleNext}
                        className="w-10 h-10 flex items-center justify-center bg-white rounded-full opacity-80 top-[50%] right-6 absolute cursor-pointer">
                        <ArrowRightIcon className="w-5 h-5" />
                      </div>
                    </div>
                    <div className="w-[349px] relative">
                      <div className="flex justify-between p-4">
                        <div>
                          <div className="flex text-primary text-xs items-center">
                            <span>{project && project.name}</span>
                            <ChevronRightIcon className="w-4 h-4 ml-1" />
                          </div>
                          <div className="font-semibold mt-2">{data.name}</div>
                        </div>
                        <div className="flex items-center">
                          <Button
                            className="border p-2 rounded-md border-light-gray-2 hover:bg-gray-50 focus:outline-none"
                            onClick={handleClose}>
                            <XMarkIcon className="w-5 h-5" />
                          </Button>
                        </div>
                      </div>
                      <div className="w-full border-t border-light-gray-3 my-4" />
                      <div className="px-4">
                        <div className="border border-light-gray-1 rounded-lg p-4 flex justify-between">
                          <div className="flex">
                            {isImage ? (
                              <PhotoIcon className="w-5 h-5 text-mid-gray-5" />
                            ) : (
                              <DocumentIcon className="w-5 h-5 text-mid-gray-5" />
                            )}
                            <div className="ml-4">
                              <div className="font-semibold">
                                {selectedValue.name}
                              </div>
                            </div>
                          </div>
                          <TrashIcon
                            className="w-5 h-5 text-red-600 cursor-pointer"
                            onClick={handleConfirmDelete}
                          />
                        </div>
                      </div>
                      <div className="w-full border-t border-light-gray-3 my-4" />
                      <div className="px-4 text-dark-gray-2 flex">
                        <Avatar
                          alt={
                            milestoneImage?.uploaded_by_user?.first_name ?? ''
                          }
                          sx={{ width: 32, height: 32 }}
                          src={
                            milestoneImage?.uploaded_by_user?.profile_image_url
                              ? milestoneImage?.uploaded_by_user
                                  ?.profile_image_url
                              : '/broken-image.jpg'
                          }
                        />
                        <div className="ml-4">
                          <div className="font-semibold">
                            Uploaded by{' '}
                            {milestoneImage?.uploaded_by_user?.first_name}{' '}
                            {milestoneImage?.uploaded_by_user?.last_name}
                          </div>
                          <div className="text-xs mt-1">
                            {moment(
                              milestoneImage?.uploaded_by_user?.updated_at
                            ).format('MMMM Do YYYY LT')}
                          </div>
                        </div>
                      </div>
                      <div className="w-full border-t border-light-gray-3 my-4" />
                      <div className="p-4 flex-1 pb-20">
                        {loading ? (
                          <div className="w-full py-10 flex justify-center">
                            <Spinner className="w-8 h-8 z-15" />
                          </div>
                        ) : (
                          (milestoneImage?.comments || []).map(c => (
                            <div
                              className="flex items-end mt-5"
                              key={c.comment}>
                              <div className="mb-5">
                                <Avatar
                                  sx={{ width: 32, height: 32 }}
                                  alt={c.user?.first_name ?? ''}
                                  src={
                                    c.user?.profile_image_url
                                      ? c.user?.profile_image_url
                                      : '/broken-image.jpg'
                                  }
                                />
                              </div>
                              <div className="ml-2">
                                <div className="bg-light-gray-4 py-2 px-4 rounded-t-md rounded-r-md">
                                  {c.comment}
                                </div>
                                <div className="text-mid-gray-3 mt-1 text-xs">
                                  {moment(c.timestamp).fromNow()}
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                      {canPerformAction() ? (
                        <div className="h-16 flex items-center bg-light-gray-3 absolute bottom-0 left-0 right-0 border-t border-light-gray-3">
                          <Formik initialValues={{}} onSubmit={onHandleSubmit}>
                            {() => {
                              return (
                                <Form className="flex justify-start w-full px-2">
                                  <Field
                                    id="comment"
                                    name="comment"
                                    type="text"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => setCommentValue(e.target.value)}
                                    placeholder="Add a comment"
                                    className="flex-1 py-2 px-3 w-full rounded-lg border border-gray-200 focus:outline-none focus:border-primary focus:ring-primary focus:ring-1 disabled:opacity-70"
                                  />
                                  <Button
                                    type="submit"
                                    disabled={!commentValue}>
                                    Send
                                  </Button>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          <DeleteDialog
            openConfirmDelete={openConfirmDelete}
            closeConfirmDelete={closeConfirmDelete}
            handleDeleteCompleted={handleDeleteCompleted}
          />
        </Dialog>
      </Transition.Root>
    );
  }
);
