import { ProjectUpdate } from '_models/project-update';
import { ResponseViewModel } from '_models/response-viewmodel';

import { supabaseService } from './supabase-service';

export const ProjectUpdateService = {
  async insert(
    projectUpdate: ProjectUpdate
  ): Promise<ResponseViewModel<ProjectUpdate>> {
    return supabaseService.insert('project_update', projectUpdate);
  },

  async update(
    id: number,
    userId: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patchConditional(
      'project_update',
      { column: 'id', value: id },
      { payload: { user_id: userId } }
    );
  },
};
