import { ChevronRightIcon } from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';

const BreadCrumbs = () => {
  const items = useBreadCrumbsStore($ => $.items);

  return (
    <div className="flex gap-1 items-center">
      {items.map((item, index) => (
        <Fragment key={index}>
          {index !== 0 ? <ChevronRightIcon className="w-4 h-3" /> : null}
          {item.link ? (
            <Link
              to={item.link}
              className={classNames(
                'text-mid-gray-2',
                item.highlight ? 'text-black' : null
              )}>
              {item.label}
            </Link>
          ) : (
            <div
              className={classNames(
                'text-mid-gray-2',
                item.highlight ? 'text-black' : null
              )}>
              {item.label}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default BreadCrumbs;
