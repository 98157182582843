import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  OrganizationUserWithProfile,
  OrganizationWithUsers,
} from '_hooks/useOrganization';

export interface OrganizationState {
  organizationWithUsers?: OrganizationWithUsers;
  organizationUsers?: OrganizationUserWithProfile[];

  isInviteUserModalVisible: boolean;
  isArchiveOrganizationModalVisible: boolean;

  setOrganizationWithUsers: (
    organizationWithUsers?: OrganizationWithUsers
  ) => void;

  setIsInviteUserModalVisible: (isCreateModalVisible: boolean) => void;
  setIsArchiveOrganizationModalVisible: (
    isArchiveOrganizationModalVisible: boolean
  ) => void;
}

const useCurrentOrganizationStaffsStore = create<OrganizationState>()(
  immer((set, _get) => {
    return {
      isInviteUserModalVisible: false,
      isArchiveOrganizationModalVisible: false,

      setOrganizationWithUsers: (
        organizationWithUsers?: OrganizationWithUsers
      ) => {
        set({ organizationWithUsers });
      },

      setIsInviteUserModalVisible: (isInviteUserModalVisible: boolean) => {
        set({ isInviteUserModalVisible });
      },

      setIsArchiveOrganizationModalVisible: (
        isArchiveOrganizationModalVisible: boolean
      ) => {
        set({ isArchiveOrganizationModalVisible });
      },
    };
  })
);

export default useCurrentOrganizationStaffsStore;
