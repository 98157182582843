import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Filter } from '_hooks/useProjects';

export interface ProjectListState {
  filters?: Filter;

  setFilters: (filters: Filter) => void;
}

const useProjectListStore = create<ProjectListState>()(
  immer((set, _get) => {
    return {
      filters: {},
      setFilters: (filters: Filter) => {
        set({ filters });
      },
    };
  })
);

export default useProjectListStore;
