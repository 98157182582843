import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import { Profile } from '_types/profile';
import { ProjectUserInvitation } from '_types/project';

type ProjectUserInvitationWithProfile = ProjectUserInvitation & {
  profile: Profile;
};

export const fetchProjectUserInvitationsByProjectId = async (
  projectId?: string | number
) => {
  if (!projectId) {
    throw new Error('missing project id');
  }
  const { data: invitations, error } = await supabase
    .from('project_user_invitation')
    .select('*, profile(*)')
    .eq('project_id', projectId)
    .eq('status', 'NEW')
    .returns<ProjectUserInvitationWithProfile[]>();

  if (error) {
    throw error;
  }

  return invitations;
};

const useProjectUserInvitationsByProjectId = (projectId?: number | string) =>
  useQuery(
    ['projectUserInvitations', projectId],
    () => fetchProjectUserInvitationsByProjectId(projectId),
    {
      enabled: !!projectId,
    }
  );

export default useProjectUserInvitationsByProjectId;
