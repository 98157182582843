import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';
import { Status } from '_types/common';
import { Profile } from '_types/profile';
import { Project } from '_types/project';
import { ProjectUser } from '_types/projectUser';

export type ProjectUserWithProfile = ProjectUser & {
  profile: Profile;
};

export type ProjectInfo = Project & {
  project_user: ProjectUserWithProfile[];
};

export interface Filter {
  status?: Status | null;
  userIds?: string[];
  projectTypeId?: number | null;
  keyword?: string;
}

export const fetchProjects = async (
  filter?: Filter
): Promise<ProjectInfo[]> => {
  const organization = useAuthStore.getState().selectedOrganization;
  if (!organization) {
    return [];
  }

  let query;
  if (organization.organization_user[0].role === 'DESIGNER') {
    query = supabase
      .from('project')
      .select(
        '*, project_user_for_filter:project_user!inner(user_id), project_user(*, profile(*))'
      )
      .eq(
        'project_user_for_filter.user_id',
        organization.organization_user[0].user_id
      )
      .eq('organization_id', organization.id);
  } else {
    query = supabase
      .from('project')
      .select('*, project_user(*, profile(*))')
      .eq('organization_id', organization.id);
  }

  if (filter) {
    if (filter.status) {
      query = query.eq('status', filter.status);
    }
    if (filter.userIds && filter.userIds.length > 0) {
      query = query.eq('project_user.user_id', filter.userIds[0]);
    }
    if (filter.projectTypeId != null) {
      query = query.eq('type', filter.projectTypeId);
    }
    if (filter.keyword) {
      query = query.textSearch('fts', `${filter.keyword.replace(/ /g, '+')}:*`);
    }
  }

  const { data: projects, error } = await query.order('created_at', {
    ascending: false,
  });

  if (error) {
    throw error;
  }

  // TODO: somehow profile is incompatible
  // https://github.com/orgs/supabase/discussions/7610

  return projects as unknown as ProjectInfo[];
};

const useProjects = (filters?: Filter, enabled?: boolean) =>
  useQuery(['projects', filters], () => fetchProjects(filters), {
    enabled,
  });

export default useProjects;
