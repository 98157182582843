import { PlusSmallIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { Collapse } from '@mui/material';

import { useCallback, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import Button from '_components/button';
import ConfirmationDialog from '_components/ConfirmationDialog';
import { ReactComponent as GripDots } from '_images/icons/dots.svg';
import { ProjectTemplateStage } from '_types/projectType';

import useTemplateDetailStore from '../store';
import MilestoneEditor from './MilestoneEditor';

interface Props {
  stage: ProjectTemplateStage;
  isOpen?: boolean;
}

const StageEditor = ({ stage, isOpen = false }: Props) => {
  const setStageName = useTemplateDetailStore($ => $.setStageName);
  const addMilestone = useTemplateDetailStore($ => $.addMilestone);
  const removeStage = useTemplateDetailStore($ => $.removeStage);
  const reorderMilestones = useTemplateDetailStore($ => $.reorderMilestones);

  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] =
    useState(false);

  const [mouseOverId, setMouseOverId] = useState<string | null>(null);
  const [draggingId, setDraggingId] = useState<string | null>(null);

  const handleConfirmRemoveStage = useCallback(() => {
    removeStage(stage.id);
  }, [removeStage, stage.id]);

  const onDragStart = useCallback((start: DragStart) => {
    setDraggingId(start.draggableId);
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setDraggingId(null);
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      reorderMilestones(
        stage.id,
        result.source.index,
        result.destination.index
      );
    },
    [reorderMilestones, stage]
  );

  return (
    <>
      <Collapse timeout="auto" in={isOpen} unmountOnExit>
        <div className="p-2.5">
          <div className="flex items-center">
            <div className="w-[120px]">Title:</div>
            <input
              placeholder="e.g. Condo"
              className="px-3 py-1 w-64 block rounded-md border-gray-300 outline-none border border-light-gray-2"
              value={stage.name}
              onChange={e => {
                setStageName(stage.id, e.target.value);
              }}
            />
            <XCircleIcon
              className="ml-2.5 w-6 h-6 text-mid-gray-5 cursor-pointer"
              onClick={() => setIsConfirmDeleteModalVisible(true)}
            />
          </div>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="milestone-dropable">
              {dropableProvided => (
                <div
                  {...dropableProvided.droppableProps}
                  ref={dropableProvided.innerRef}>
                  {stage.milestones.map((milestone, index) => (
                    <Draggable
                      key={milestone.id}
                      draggableId={milestone.id}
                      index={index}>
                      {dragableProvided => (
                        <div
                          className="mt-2.5"
                          ref={dragableProvided.innerRef}
                          {...dragableProvided.draggableProps}
                          style={{ ...dragableProvided.draggableProps.style }}>
                          <div
                            className="pl-2.5 relative flex items-center"
                            onMouseOver={() => {
                              if (!draggingId) {
                                setMouseOverId(milestone.id);
                              }
                            }}
                            onMouseLeave={() => {
                              setMouseOverId(null);
                            }}>
                            {milestone.id === mouseOverId ||
                            milestone.id === draggingId ? (
                              <GripDots className="w-4 h-4 absolute top-[7px] -left-1.5" />
                            ) : null}
                            <div
                              className="w-[120px]"
                              {...dragableProvided.dragHandleProps}>
                              Milestone {index + 1}:
                            </div>
                            <MilestoneEditor
                              stageId={stage.id}
                              milestone={milestone}
                              showDeleteButton={
                                milestone.id === mouseOverId ||
                                milestone.id === draggingId
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {dropableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="mt-2.5 flex justify-end">
            <Button
              onClick={() => addMilestone(stage.id)}>
              <PlusSmallIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Add Milestone
            </Button>
          </div>
        </div>
      </Collapse>
      <ConfirmationDialog
        isVisible={isConfirmDeleteModalVisible}
        title="Confirm Delete"
        content={
          <div>
            <div>
              Are you sure you want to delete this stage? All milestones for it
              will also be removed.
            </div>
          </div>
        }
        onClose={() => setIsConfirmDeleteModalVisible(false)}
        onConfirm={handleConfirmRemoveStage}
      />
    </>
  );
};

export default StageEditor;
