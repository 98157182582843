import { Field, Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Button from '_components/button';
import Label from '_components/label';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .trim()
      .email('Please enter a valid email id')
      .label('Email')
      .required(),
    password: yup.string().trim().min(6).label('Password').required(),
  })
  .required();

interface LocationState {
  from: {
    pathname: string;
    search: string;
  };
}

export const Authentication = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogining, setIsLogining] = useState(false);

  const user = useAuthStore($ => $.user);
  const profile = useAuthStore($ => $.profile);
  const snackBar = useSnackBarStore();

  useEffect(() => {
    if (user) {
      // user already logged in, send him to previous page
      if (location.state) {
        const { from } = location.state as LocationState;
        const { pathname, search } = from;
        navigate(`${pathname || '/'}${search}`);
      } else {
        navigate('/projects');
      }
    }
  }, [location, navigate, user, profile]);

  const signInWithPassword = useAuthStore($ => $.signInWithPassword);

  const onSubmit = async (values: FormValues) => {
    try {
      setIsLogining(true);
      const { password } = values;
      await signInWithPassword({ email: values.email, password });
      snackBar.show('Logged in successfully', 'success');
    } catch (error) {
      snackBar.show(
        (error as unknown as { message?: string }).message ?? 'Login failed',
        'error'
      );
    } finally {
      setIsLogining(false);
    }
  };

  return (
    <div className="w-full">
      <div className="px-5 sm:w-[27rem] mx-auto mt-32">
        <div className="text-2xl font-semibold text-center tracking-tight">
          Sunnyside Web Login
        </div>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          {(formikProps: FormikProps<FormValues>) => (
            <Form className="mt-8 rounded-md shadow-[0_0_3px_rgba(176,186,191,0.5)]">
              <div className="bg-light-gray-5 p-5">
                <div className="flex justify-start items-center">
                  <Label className="w-24 sm:w-32 text-xs sm:text-sm">
                    Email
                  </Label>
                  <Field
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="account@sunnyside.sg"
                    disabled={isLogining}
                    className="block w-64 py-2 px-3 w-full rounded-lg border border-gray-200 focus:outline-none sm:text-sm"
                  />
                </div>
                <div className="flex justify-start items-center mt-5">
                  <Label className="w-24 sm:w-32 text-xs sm:text-sm">
                    Password
                  </Label>
                  <Field
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="********"
                    disabled={isLogining}
                    className="block w-64 py-2 px-3 w-full rounded-lg border border-gray-200 focus:outline-none sm:text-sm disabled:opacity-70"
                  />
                </div>
              </div>
              <div className="p-5 flex justify-between items-center border-t">
                <Link
                  to="/forgot-password"
                  className="underline text-blue-500 text-xs">
                  Forgot password?
                </Link>
                <Button
                  type="submit"
                  disabled={isLogining || !formikProps.isValid}>
                  Login
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
