import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface BreadCrumbItem {
  label: string;
  link?: string;
  highlight?: boolean;
}

interface BreadCrumbsState {
  items: BreadCrumbItem[];

  setItems: (items: BreadCrumbItem[]) => void;

  addItem: (item: BreadCrumbItem) => void;
}

const useBreadCrumbsStore = create<BreadCrumbsState>()(
  immer(set => ({
    items: [],

    setItems: (items: BreadCrumbItem[]) => {
      set({ items });
    },

    addItem: (item: BreadCrumbItem) => {
      set(state => ({
        items: [...state.items, item],
      }));
    },
  }))
);

export default useBreadCrumbsStore;
