import './index.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { queryClient } from '_clients/queryClient';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  // </React.StrictMode>
);

reportWebVitals();

if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'production') {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function (registration) {
      // eslint-disable-next-line no-console
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      // eslint-disable-next-line no-console
      console.log('Service worker registration failed, error:', err);
    });
}
