import { Field, Form, Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';
import { forgotPasswordValidator } from '_validators/forgot-password-validator';

interface FormValues {
  email: string;
}

export function ForgotPassword() {
  const snackBar = useSnackBarStore();

  const [isBusy, setIsBusy] = useState(false);
  const [resetPaswordEmailSent, setResetPaswordEmailSent] = useState(false);
  const handleSubmit = async (values: FormValues) => {
    try {
      setIsBusy(true);
      const { error } = await supabase.functions.invoke(
        'resend-invitation-email',
        {
          body: {
            email: values.email,
            action: 'RESET_PASSWORD',
          },
        }
      );
      if (error) {
        throw error;
      }
      snackBar.show('Reset password mail sent successfully', 'success');
      setResetPaswordEmailSent(true);
    } catch (error) {
      const errorMessage = await getErrorMessage(error);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  };

  if (resetPaswordEmailSent) {
    return (
      <div className="px-5 flex flex-col items-center mt-32 w-full sm:w-[480px] mx-auto">
        <div className="text-2xl font-semibold text-center tracking-tight">
          Forgot Password
        </div>
        <div className="rounded-md shadow-[0_0_3px_rgba(176,186,191,0.5)] mt-8">
          <div className="bg-light-gray-5 p-5 leading-6">
            Please check your email and follow the instructions to reset your
            password.
          </div>
          <div className="p-5 flex justify-center border-t">
            <Link to="/login">
              <Button type="submit">Return to Sign-In</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="px-5 flex flex-col items-center mt-32 w-full sm:w-[480px] mx-auto">
      <div className="text-2xl font-semibold text-center tracking-tight">
        Forgot Password
      </div>
      <Formik
        enableReinitialize
        initialValues={{ email: '' }}
        validationSchema={forgotPasswordValidator}
        onSubmit={handleSubmit}>
        {(formikProps: FormikProps<FormValues>) => (
          <Form className="mt-8 rounded-md shadow-[0_0_3px_rgba(176,186,191,0.5)] w-full">
            <div className="bg-light-gray-5 p-5">
              <div className="flex items-start">
                <Label className="w-16 sm:w-24 text-xs sm:text-sm mt-2.5">
                  Email
                </Label>
                <div className="flex-1">
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    autoFocus
                    placeholder="Email"
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    className="block w-full sm:w-64 py-2 px-3 rounded-lg border border-gray-200 focus:outline-none sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="p-5 flex justify-center border-t">
              <Button
                type="submit"
                disabled={
                  !(formikProps.isValid && formikProps.dirty) || isBusy
                }>
                Reset Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
