import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuthStore from '_stores/useAuthStore';

const DownloadApp = () => {
  const navigate = useNavigate();

  const signOut = useAuthStore($ => $.signOut);
  const profile = useAuthStore($ => $.profile);

  useEffect(() => {
    if (profile?.role !== 'CLIENT') {
      navigate('/projects');
    }
  }, [navigate, profile]);

  return (
    <div className="px-5 w-full sm:w-[27rem] mx-auto mt-32">
      <div className="text-2xl font-semibold text-center tracking-tight">
        Sunnyside Web Login
      </div>
      <div className="text-dark-gray-2 leading-6 bg-light-gray-5 p-5 mt-8 rounded-2xl shadow-[0_0_3px_rgba(176,186,191,0.5)]">
        Please login to the Sunnyside App instead to view your project and chat
        with your project designers.
      </div>
      <div className="mt-8">
        <h3 className="font-semibold text-center text-lg sm:text-2xl tracking-tight">
          Don't have the app yet? Get it here!
        </h3>
        <div className="flex justify-center gap-3 mt-6">
          <a
            href="https://apps.apple.com/vn/app/sunnyside-homes/id1666287931"
            target="_blank"
            rel="noreferrer">
            <img alt="App Store" src="/app-store-link.png" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.sunnysideapp"
            target="_blank"
            rel="noreferrer">
            <img alt="Play Store" src="/google-play-link.png" />
          </a>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <div
          onClick={signOut}
          className="text-blue-400 underline font-medium cursor-pointer">
          Sign Out
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
