import classnames from 'classnames';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Label = ({ children, className }: Props) => {
  return (
    <label className={classnames('text-dark-gray-2', className)}>
      <span className="inline-block">
        {children}
      </span>
    </label>
  );
};

export default Label;
