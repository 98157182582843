import { PlusSmallIcon } from '@heroicons/react/24/solid';

import { uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '_components/button';
import useProjects from '_hooks/useProjects';
import useAuthStore from '_stores/useAuthStore';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import useProjectListStore from '_stores/useProjectListStore';
import { Profile } from '_types/profile';

import { ProjectList } from './project-list';
import { SearchFilter } from './search-filter';

export const ProjectPage = () => {
  const navigate = useNavigate();
  const [selectedStatus] = useState<string | number>(0);

  const filters = useProjectListStore($ => $.filters);

  const canPerformAction = useAuthStore($ => $.canPerformAction);
  const selectedOrganization = useAuthStore($ => $.selectedOrganization);

  const { data: projects, isLoading: isLoadingProjects } = useProjects(
    filters,
    !!filters
  );

  const setItems = useBreadCrumbsStore($ => $.setItems);

  // update breadcrumbs
  useEffect(() => {
    setItems([{ label: 'Projects', highlight: true }]);
  }, [setItems]);

  const handleCreateProjectOpen = () => {
    navigate('/projects/create');
  };

  const filteredUsers = useMemo(() => {
    if (!projects) {
      return [];
    }
    const userDetails: Profile[] = [];
    projects.forEach(project =>
      project.project_user?.forEach(user => {
        userDetails.push(user.profile);
      })
    );

    return uniqBy(userDetails, (p: Profile) => p.id);
  }, [projects]);

  return (
    <div className="mt-12 mb-20">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-2xl">
          {selectedStatus === 'INACTIVE'
            ? 'Archived Projects'
            : selectedStatus === 'ACTIVE'
            ? 'Active Projects'
            : 'Projects'}
        </div>
        {/* <Button
          type="button"
          onClick={handleCreateProjectOpen}
          disabled={!canPerformAction()}
          className="inline-flex items-center rounded-md border border-transparent px-4 py-1.5 font-semibold text-white shadow-sm bg-primary hover:bg-dark-blue focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:opacity-50">
          <PlusSmallIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Create project
        </Button> */}
        <Button
          onClick={handleCreateProjectOpen}
          disabled={!canPerformAction()}
          icon={
            <PlusSmallIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          }>
          Create Project
        </Button>
      </div>
      <div className="mt-5">
        <SearchFilter filteredUsers={filteredUsers} />
      </div>
      <div className="mt-7">
        <ProjectList projects={projects ?? []} isLoading={isLoadingProjects} />
      </div>
      {!selectedOrganization ? (
        <div className="py-5 px-2.5 leading-6">
          You do not belong to any organisation. Please check with your Admin to
          resolve this issue.
        </div>
      ) : null}
    </div>
  );
};
