import { Profile } from '_types/profile';

import { ResponseViewModel } from '../models/response-viewmodel';
import { supabaseService } from './supabase-service';

const STORAGE_BUCKET_KEY_AVATARS = 'avatars';

export const profileService = {
  async update(profile: Profile): Promise<ResponseViewModel<boolean>> {
    return supabaseService.update('profile', profile);
  },

  async getUser(
    column: string,
    id: string
  ): Promise<ResponseViewModel<Profile>> {
    return supabaseService.getSingle('profile', column, id);
  },

  async getUsers(): Promise<ResponseViewModel<Profile[]>> {
    return supabaseService.getAll<Profile>('profile', '*', 'first_name');
  },

  async upload(
    filepath: string,
    file: File
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.uploadFile(
      STORAGE_BUCKET_KEY_AVATARS,
      filepath,
      file
    );
  },

  async getUsersByIds(
    userIds: string[]
  ): Promise<ResponseViewModel<Profile[]>> {
    const filters: any[] = [];
    filters.push({ column: 'id', value: userIds, operand: 'in' });
    return supabaseService.getFilteredList('profile', filters);
  },

  async dismissNotifications(
    userId: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.update(
      'notification_acknowledgements',
      { user_id: userId, date_read: new Date() },
      'user_id'
    );
  },

  async removeProfileImage(id: string): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patchConditional(
      'profile',
      { column: 'id', value: id },
      { profile_image_url: null }
    );
  },

  async removeProfileStorageFile(filePath: string[]) {
    return supabaseService.removeFile('avatars', filePath);
  },

  async removeProfile(id: string) {
    return supabaseService.deleteByMatch('profile', {
      id,
    });
  },

  async removeAuthUser(id: string) {
    return supabaseService.deleteUser(id);
  },
};
