import { useInfiniteQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import { Profile } from '_types/profile';
import { Project, ProjectUpdate } from '_types/project';

const PAGE_SIZE = 500;

export interface ProjectUpdatePayload {
  user_id?: string;
  milestone?: string;
  checklist_item_title?: string;
  original_file?: string;
  new_file?: string;
}

export type ProjectUpdatePreloaded = ProjectUpdate & {
  profile: Profile | null;
  project: Project | null;
  targetUser?: Profile;
  payload: ProjectUpdatePayload | null;
};

interface Filter {
  projectId?: number;
}

interface Options {
  page: number;
  filter?: Filter;
}

interface Result {
  projectUpdates: ProjectUpdatePreloaded[];
  pageInfo: {
    page: number;
    totalCount: number;
  };
}

export const fetchProjectUpdates = async ({
  page,
  filter,
}: Options): Promise<Result> => {
  const pageSize = PAGE_SIZE;
  const from = page * pageSize;
  const to = from + pageSize - 1;

  let query = supabase
    .from('project_update')
    .select('*, profile!inner(*)', { count: 'exact' });

  if (filter) {
    if (filter.projectId) {
      query = query.eq('project_id', filter.projectId);
    }
  }

  const {
    data: projectUpdates,
    count,
    error,
  } = await query.order('created_at', { ascending: false }).range(from, to);

  if (error) {
    throw error;
  }

  if (!projectUpdates) {
    return {
      projectUpdates: [],
      pageInfo: {
        page,
        totalCount: 0,
      },
    };
  }

  let projectUpdatesWithPreloaded;

  // type assert for payload
  projectUpdatesWithPreloaded = projectUpdates.map(pu => ({
    ...pu,
    payload: pu.payload as ProjectUpdatePayload | null,
  }));

  // Fill targetUser to each item
  const userIds = projectUpdatesWithPreloaded
    .map(pu => pu.payload?.user_id)
    .filter(id => !!id);

  const { data: profiles, error: getProfilesError } = await supabase
    .from('profile')
    .select()
    .in('id', userIds);
  if (getProfilesError) {
    throw getProfilesError;
  }

  projectUpdatesWithPreloaded = projectUpdatesWithPreloaded.map(pu => ({
    ...pu,
    targetUser: profiles.find(
      p => p.id === (pu.payload as ProjectUpdatePayload)?.user_id
    ),
  }));

  // has to cast to unkown: https://github.com/orgs/supabase/discussions/7610
  return {
    projectUpdates:
      projectUpdatesWithPreloaded as unknown as ProjectUpdatePreloaded[],
    pageInfo: {
      page,
      totalCount: count || 0,
    },
  };
};

const useProjectUpdates = (projectId: number) =>
  useInfiniteQuery(
    ['projects', projectId, 'projectUpdates'],
    ({ pageParam }: { pageParam?: Options }) =>
      fetchProjectUpdates(pageParam ?? { page: 0, filter: { projectId } }),
    {
      getNextPageParam: ({ pageInfo, projectUpdates }) => {
        if (projectUpdates.length < PAGE_SIZE) {
          return undefined;
        }

        return { page: pageInfo.page + 1, filter: { projectId } };
      },
    }
  );

export default useProjectUpdates;
