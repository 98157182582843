import { CloseOutlined } from '@mui/icons-material';
import { Avatar, IconButton } from '@mui/material';

import { Field, FieldProps, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';
import useAuthStore from '_stores/useAuthStore';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import CoverImageWrapper from '_styles/cover-image-styles';
import getErrorMessage from '_utils/getErrorMessage';
import { profileValidator } from '_validators/profile-validator';

import theme from '../../theme/theme';
import PhoneChangeOTPModal from './PhoneChangeOTPModal';

const { REACT_APP_SUPABASE_URL } = process.env;

const STORAGE_BUCKET_URL = `${REACT_APP_SUPABASE_URL}/storage/v1/object/public`;
const STORAGE_BUCKET_KEY_AVATARS = 'avatars';

interface FormValues {
  email: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER';
}

export const Profile = observer(() => {
  const snackBar = useSnackBarStore();

  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPhoneChangeOTPModalVisible, setIsPhoneChangeOTPModalVisible] =
    useState(false);
  const [phone, setPhone] = useState('');
  const refetchProfile = useAuthStore($ => $.refetchProfile);

  const user = useAuthStore($ => $.user);
  const profile = useAuthStore($ => $.profile);

  const id = useMemo(() => user?.id ?? '', [user]);
  const appMetadata = useMemo(() => user?.app_metadata, [user]);

  const [profileImageURL, setProfileImageURL] = useState(
    profile && profile.profile_image_url
  );
  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  // update breadcrumbs
  useEffect(() => {
    setItems([{ label: 'Home', link: '/' }, { label: 'Profile' }]);
  }, [addItem, setItems]);

  useEffect(() => {
    if (!id) {
      return;
    }
    (async function () {
      if (!appMetadata) {
        return;
      }
      setIsMobileLogin(appMetadata.provider === 'phone');
    })();
  }, [id, appMetadata]);

  useEffect(() => {
    if (!profile) {
      return;
    }
    setProfileImageURL(profile.profile_image_url);
  }, [profile]);

  const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const fileExt = file.name.split('.').pop();
    const fileName = `${id}_avatar.${fileExt}`;

    const { error } = await supabase.storage
      .from(STORAGE_BUCKET_KEY_AVATARS)
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: true,
      });

    if (error) {
      snackBar.show(error.message, 'error');
      return;
    }

    setProfileImageURL(
      `${STORAGE_BUCKET_URL}/${STORAGE_BUCKET_KEY_AVATARS}/${fileName}?t=${Date.now()}`
    );
    snackBar.show('Image uploaded successfully', 'success');
  };

  const onProfileSubmit = async (values: FormValues) => {
    const profileData = {
      gender: values.gender,
      first_name: values.first_name,
      last_name: values.last_name,
      profile_image_url: profileImageURL ?? '',
      first_time_login: false,
    };
    setIsSaving(true);
    try {
      const { error: updateProfileError } = await supabase
        .from('profile')
        .update(profileData)
        .eq('id', id)
        .select();
      if (updateProfileError) {
        snackBar.show(updateProfileError.message, 'error');
        return;
      }

      if (
        !isMobileLogin &&
        values.mobile_number &&
        '65' + values.mobile_number !== profile?.mobile_number
      ) {
        const phone = '+65' + values.mobile_number;
        const { error } = await supabase.auth.updateUser({
          phone,
        });
        if (error) {
          throw error;
        }
        setIsPhoneChangeOTPModalVisible(true);
        setPhone(phone);
      } else {
        await refetchProfile();
      }
      snackBar.show('Profile updated successfully', 'success');
    } catch (error) {
      const errorMessage = await getErrorMessage(error);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const btn = {
    opacity: 0,
    '&:hover': {
      opacity: 1,
      color: 'white',
    },
  };

  const handleRemoveImage = async () => {
    setProfileImageURL('');
    snackBar.show('Image removed successfully', 'success');
  };

  return (
    <div className="shadow-outer rounded-lg overflow-hidden mt-12">
      <Formik<FormValues>
        initialValues={{
          first_name: profile?.first_name ?? '',
          last_name: profile?.last_name ?? '',
          gender: profile?.gender ?? 'MALE',
          email: profile?.email ?? '',
          mobile_number: profile?.mobile_number
            ? profile.mobile_number.substring(2)
            : '',
        }}
        onSubmit={onProfileSubmit}
        validationSchema={profileValidator}>
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          handleSubmit,
        }) => {
          return (
            <>
              <div className="p-5 border-b text-lg font-semibold">
                {profile?.first_time_login
                  ? 'Finish Setting Up Your Account'
                  : 'Edit User Profile'}
              </div>
              <form onSubmit={handleSubmit}>
                <div className="bg-light-gray-5 p-5">
                  <div className="relative flex items-center">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      Profile
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>

                  <div className="flex justify-start items-center mt-5">
                    <div className="w-32">
                      <Label>
                        <div>Profile Photo</div>
                        <div>(Optional)</div>
                      </Label>
                    </div>
                    <Field name="profile_image_url">
                      {() => (
                        <div className="ml-8">
                          <label
                            htmlFor="outlined-button-file1"
                            className="cursor-pointer">
                            {profileImageURL && (
                              <CoverImageWrapper>
                                <IconButton
                                  color="inherit"
                                  component="span"
                                  onClick={handleRemoveImage}>
                                  <CloseOutlined sx={btn} />
                                </IconButton>
                              </CoverImageWrapper>
                            )}
                            <Avatar
                              sx={{
                                width: 100,
                                height: 100,
                                bgcolor: `${theme.palette.primary.light} !important`,
                                color: 'white !important',
                              }}
                              alt={`${values.first_name} ${values.last_name}`}
                              src={
                                profileImageURL
                                  ? profileImageURL
                                  : '/broken-image.jpg'
                              }
                            />
                          </label>
                          <input
                            id="outlined-button-file1"
                            type="file"
                            accept="image/*"
                            onChange={handleUploadImage}
                            name="profile_image_url"
                            className="hidden"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex justify-start items-center mt-5">
                    <Label className="w-32">First Name*</Label>
                    <Field
                      name="first_name"
                      type="text"
                      title="First Name"
                      autoComplete="first_name"
                      placeholder="First Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-64 ml-8 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.first_name && touched.first_name && (
                      <span className="ml-6 text-red-500">
                        {errors.first_name}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-start items-center mt-5">
                    <Label className="w-32">Last Name*</Label>
                    <Field
                      id="last_name"
                      name="last_name"
                      type="text"
                      title="Last Name"
                      autoComplete="last_name"
                      placeholder="Last Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-64 ml-8 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.last_name && touched.last_name && (
                      <span className="ml-6 text-red-500">
                        {errors.last_name}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-start items-center mt-5">
                    <Label className="w-32">Gender*</Label>
                    <Field
                      as="select"
                      id="gender"
                      name="gender"
                      autoComplete="gender"
                      placeholder="Gender"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-32 ml-8 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                      <option value="">Select</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </Field>
                    {errors.gender && touched.gender && (
                      <span className="ml-6 text-red-500">{errors.gender}</span>
                    )}
                  </div>
                  <div className="flex justify-start items-center mt-5">
                    <Label className="w-32">Email</Label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      disabled={values.email !== ''}
                      placeholder="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-64 ml-8 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.email && touched.email && (
                      <span className="ml-6 text-red-500">{errors.email}</span>
                    )}
                  </div>
                  <div className="relative flex py-2 items-center mt-5">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      Alternative App Login
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>
                  <div className="flex justify-start mt-5">
                    <Label className="w-32 flex items-center">Mobile No.</Label>
                    <Field
                      name="mobile_number"
                      onBlur={handleBlur}
                      onChange={handleChange}>
                      {({ field }: FieldProps) => (
                        <div className="ml-8 relative mt-2 rounded-lg border border-gray-200">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">
                              +65
                            </span>
                          </div>
                          <input
                            {...field}
                            type="text"
                            placeholder="eg: 91234567"
                            disabled={!!user?.phone_confirmed_at}
                            value={field.value ?? ''}
                            className="block w-56 rounded-lg border-0 py-1.5 pl-12 pr-12 focus:outline-none leading-6"
                          />
                        </div>
                      )}
                    </Field>
                    {errors.mobile_number && touched.mobile_number && (
                      <span className="ml-6 text-red-500">
                        {errors.mobile_number}
                      </span>
                    )}
                  </div>
                </div>
                <div className="border-t flex justify-end p-5">
                  <Button type="submit" disabled={isSaving}>
                    {isSaving ? (
                      <div className="inline-flex justify-start items-center gap-x-2">
                        <Spinner className="h-4 w-4" />
                        Saving..
                      </div>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
      <PhoneChangeOTPModal
        isVisible={isPhoneChangeOTPModalVisible}
        onClose={() => setIsPhoneChangeOTPModalVisible(false)}
        phone={phone}
      />
    </div>
  );
});
