import {
  CalendarIcon,
  CheckCircleIcon,
  ClipboardDocumentListIcon,
  DocumentIcon,
  PhotoIcon,
} from '@heroicons/react/20/solid';
import { EyeSlashIcon } from '@heroicons/react/20/solid';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  EyeIcon,
} from '@heroicons/react/24/solid';
import { Collapse } from '@mui/material';

import classNames from 'classnames';
import { filter, union } from 'lodash';
import moment, { min } from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { supabase } from '_clients/supabaseClient';
import Spinner from '_components/spinner';
import useProjectStages from '_hooks/useProjectStages';
import { MilestoneHideStatus } from '_models/milestone/milestone-status';
import { StageStatus } from '_models/milestone/stage';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Status } from '_types/common';
import getErrorMessage from '_utils/getErrorMessage';

export const ProjectMilestoneList = (props: { projectId: number }) => {
  const [openIndex, setOpenIndex] = useState<number | null>();
  const navigate = useNavigate();
  const { projectId } = props;
  const [busyMilestoneIds, setBusyMilesoneIds] = useState<number[]>([]);

  const canPerformAction = useAuthStore($ => $.canPerformAction);

  const { data: projectStages, refetch } = useProjectStages(projectId);

  const snackBar = useSnackBarStore();

  const handleChangeStatus = async (milestoneId?: number, status?: string) => {
    if (!milestoneId) return;
    try {
      setBusyMilesoneIds(val => union(val, [milestoneId]));
      const newStatus = status === 'INCOMPLETE' ? 'COMPLETE' : 'INCOMPLETE';
      const { error } = await supabase.functions.invoke(
        'update-project-milestone',
        {
          body: {
            milestoneArgs: {
              id: milestoneId,
              status: newStatus,
            },
          },
        }
      );
      if (error) {
        throw error;
      }

      await refetch();
    } catch (err) {
      if (err) {
        const errorMessage = await getErrorMessage(err);
        snackBar.show(errorMessage, 'error');
      }
    } finally {
      setBusyMilesoneIds(val => filter(val, id => id !== milestoneId));
    }
  };

  const handleHideMilestone = async (
    id?: number,
    hideStatus?: Status | null
  ) => {
    if (!id) return;
    try {
      const newHideStatus =
        hideStatus === MilestoneHideStatus.ACTIVE
          ? MilestoneHideStatus.INACTIVE
          : MilestoneHideStatus.ACTIVE;
      const { error } = await supabase.functions.invoke(
        'update-project-milestone',
        {
          body: {
            milestoneArgs: {
              id,
              hide_status: newHideStatus,
            },
          },
        }
      );
      if (error) {
        throw error;
      }
      refetch();
    } catch (err) {
      if (err) {
        const errorMessage = await getErrorMessage(err);
        snackBar.show(errorMessage, 'error');
      }
    }
  };

  const changeStageHideStatus = async (id: number, status: Status) => {
    await supabase
      .from('project_stage')
      .update({
        status,
      })
      .eq('id', id);
    await refetch();
  };

  const handleNavigation = (milestoneId: number, stageId: number) => {
    navigate({
      pathname: `/projects/${projectId}/milestone/${milestoneId}`,
      search: `?selectedTab=milestone&stage_id=${stageId}`,
    });
  };

  return (
    <div>
      {projectStages?.map((stage, index) => (
        <div key={index} className="mt-5">
          <div
            key={index}
            className={classNames(
              'flex bg-light-gray-5 p-2 rounded-md flex justify-between',
              stage.status === StageStatus.INACTIVE ? 'opacity-50' : ''
            )}>
            <div className="flex justify-start">
              <button
                disabled={stage.status === StageStatus.INACTIVE ? true : false}
                onClick={() =>
                  setOpenIndex(openIndex === index ? null : index)
                }>
                {openIndex === index ? (
                  <ArrowDownCircleIcon className="w-5 h-5 text-mid-gray-5" />
                ) : (
                  <ArrowUpCircleIcon className="w-5 h-5 text-mid-gray-5" />
                )}
              </button>
              <div className="text-mid-gray-1 font-semibold ml-2">
                {stage.name}
              </div>
            </div>
            <div>
              {stage.status === 'ACTIVE' ? (
                <EyeSlashIcon
                  className="h-5 w-5 text-light-gray-1 cursor-pointer"
                  onClick={() => {
                    changeStageHideStatus(stage.id, 'INACTIVE');
                  }}
                />
              ) : (
                <EyeIcon
                  className="h-5 w-5 text-dark-gray-1 cursor-pointer"
                  onClick={() => {
                    changeStageHideStatus(stage.id, 'ACTIVE');
                  }}
                />
              )}
            </div>
          </div>
          {stage.project_milestone.map((m, i) => (
            <Collapse
              key={i}
              in={openIndex === index}
              timeout="auto"
              unmountOnExit>
              <div className="p-3 rounded-md flex justify-between border border-light-gray-3 mt-2">
                <div>
                  {m.id && busyMilestoneIds.includes(m.id) ? (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full">
                      <Spinner className="w-5 h-5 fill-white text-primary"></Spinner>
                    </div>
                  ) : (
                    <CheckCircleIcon
                      role="button"
                      className={classNames(
                        'h-6 w-6 cursor-pointer ',
                        m.status === 'COMPLETE'
                          ? 'text-green-500'
                          : 'text-light-gray-1'
                      )}
                      onClick={() => {
                        if (!canPerformAction()) {
                          return;
                        }
                        if (m.hide_status === MilestoneHideStatus.ACTIVE) {
                          handleChangeStatus(m.id, m.status);
                        }
                      }}
                    />
                  )}
                </div>
                <div
                  className={classNames(
                    'ml-2 grid grid-cols-12 flex-1 items-center',
                    m.hide_status === MilestoneHideStatus.ACTIVE
                      ? 'opacity-100'
                      : 'opacity-50'
                  )}>
                  <div className="col-span-6">
                    <span
                      onClick={() => {
                        if (
                          m.id &&
                          m.hide_status === MilestoneHideStatus.ACTIVE
                        ) {
                          handleNavigation(m.id, stage.id);
                        }
                      }}
                      className={classNames(
                        'font-semibold text-dark-gray-1',
                        m.hide_status === MilestoneHideStatus.ACTIVE
                          ? 'cursor-pointer'
                          : null
                      )}>
                      {m.name}
                    </span>
                  </div>

                  <div className="col-span-3 grid grid-cols-3 text-mid-gray-5">
                    <div className="flex justify-start items-center gap-1">
                      <ClipboardDocumentListIcon className="w-4 h-4" />
                      <span className="text-xs">
                        {
                          m.project_checklist_item.filter(
                            item => item.status === 'COMPLETE'
                          ).length
                        }
                        /{m.project_checklist_item.length}
                      </span>
                    </div>
                    <div className="flex justify-start items-center gap-1">
                      <DocumentIcon className="w-4 h-4" />
                      <span className="text-xs">
                        {m.project_milestone_file?.length}
                      </span>
                    </div>
                    <div className="flex justify-start items-center gap-1">
                      <PhotoIcon className="w-4 h-4" />
                      <span className="text-xs">
                        {m.project_milestone_image?.length}
                      </span>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'col-span-3 flex justify-start items-center gap-1',
                      min(
                        m.project_checklist_item.map(item => {
                          return moment(item.due_date);
                        })
                      ) > moment()
                        ? null
                        : 'text-red-500'
                    )}>
                    <CalendarIcon className="w-4 h-4" />
                    <span className="text-xs">
                      {m.project_checklist_item.length > 0 &&
                      min(
                        m.project_checklist_item.map(item =>
                          moment(item.due_date)
                        )
                      )
                        ? min(
                            m.project_checklist_item.map(item =>
                              moment(item.due_date)
                            )
                          ).format('DD MMM YYYY')
                        : 'N/A'}
                    </span>
                  </div>
                </div>
                <div>
                  {m.hide_status === MilestoneHideStatus.ACTIVE ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-light-gray-1 cursor-pointer"
                      onClick={() => {
                        handleHideMilestone(m.id, m.hide_status);
                      }}
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-dark-gray-1 cursor-pointer"
                      onClick={() => {
                        handleHideMilestone(m.id, m.hide_status);
                      }}
                    />
                  )}
                </div>
              </div>
            </Collapse>
          ))}
        </div>
      ))}
    </div>
  );
};
