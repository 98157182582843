import { supabase } from '_clients/supabaseClient';
import { ProjectUserList } from '_models/project/project-user-list';
import { FilterValue } from '_store/project-store';
import useAuthStore from '_stores/useAuthStore';
import { Profile } from '_types/profile';
import { Project } from '_types/project';

import { Country } from '../models/country';
import { ProjectType } from '../models/project/project-type';
import { ProjectUser } from '../models/project/project-user';
import { ProjectUpdate } from '../models/project-update';
import { ResponseViewModel } from '../models/response-viewmodel';
import { chatService } from './chat-service';
import { profileService } from './profile-service';
import { supabaseService } from './supabase-service';

const STORAGE_BUCKET_KEY_AVATARS = 'project';
async function getUsers(result: ResponseViewModel<ProjectUpdate[]>) {
  const projectUpdateData = result.data;
  const userIds = (projectUpdateData || [])
    .filter(d => d.payload)
    .map(d => d.payload.user_id)
    .filter(u => u);
  const { data: users } = await profileService.getUsersByIds(userIds);
  const projectUpdateDataWithUsers: ProjectUpdate[] | undefined =
    projectUpdateData?.map(d => ({
      ...d,
      user: users?.find(u => d.payload && d.payload.user_id === u.id),
    }));
  return projectUpdateDataWithUsers;
}
export const ProjectService = {
  async update(project: Project): Promise<ResponseViewModel<boolean>> {
    await supabase.from('project').upsert(project).eq('id', project.id);
    return ResponseViewModel.fromModel(true);
    // return supabaseService.update('project', project);
  },

  async updateImagePath(
    id: number,
    imagePath: string | null
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patch('project', id, {
      cover_image_url: imagePath,
    });
  },

  async getProjectType(): Promise<ResponseViewModel<ProjectType[]>> {
    return supabaseService.getAll<ProjectType>('project_type');
  },

  async getCountryList(): Promise<ResponseViewModel<Country[]>> {
    const columns = 'id, name';
    return supabaseService.getAll<ProjectType>('country', columns);
  },

  async getProject(id: number): Promise<ResponseViewModel<Project>> {
    return supabaseService.getSingle<Project>('project', 'id', id);
  },

  async searchUserBy(
    filters: { column: string; value: number | string; operand: string }[]
  ): Promise<ResponseViewModel<Profile[]>> {
    return supabaseService.getFilteredList<Profile>('profile', filters);
  },

  async upload(
    filepath: string,
    file: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.uploadFile(
      STORAGE_BUCKET_KEY_AVATARS,
      filepath,
      file
    );
  },

  async removeProjectFile(filePath: string[]) {
    return supabaseService.removeFile('project', filePath);
  },

  async addUserToProject(
    projectUser: ProjectUser
  ): Promise<ResponseViewModel<ProjectUser>> {
    return supabaseService.insert('project_user', projectUser);
  },

  async updateStatusOfProjectUser({
    id,
    status,
  }: {
    id: string;
    status: string;
  }): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patchConditional(
      'project_user',
      { column: 'user_id', value: id },
      { status }
    );
  },

  async getProjectUsersByProjectId(
    projectId: number
  ): Promise<ResponseViewModel<ProjectUserList[]>> {
    return supabaseService.getRecordsByInnerJoin(
      'project_user',
      '*, profile!inner(*),project!inner(*)',
      'project_id',
      projectId
    );
  },

  async getProjectUpdateByProjectId(
    projectId: number
  ): Promise<ResponseViewModel<ProjectUpdate[]>> {
    const { data: projectUpdateData, errors } =
      await supabaseService.getRecordsByInnerJoin<ProjectUpdate>(
        'project_update',
        '*, profile!inner(*),project!inner(*)',
        'project_id',
        projectId,
        'updated_at',
        {
          ascending: false,
        }
      );
    if (errors && errors.length > 0) {
      return { data: projectUpdateData, errors };
    }
    const projectUpdateDataWithUsers = await getUsers({
      data: projectUpdateData,
      errors: [],
    });
    return { data: projectUpdateDataWithUsers!, errors };
  },

  async getProjectUpdatesByProjectIds(
    projectIds: (number | undefined)[],
    userId: string,
    dateAcked: Date,
    skip = 0
  ): Promise<{ data: ResponseViewModel<ProjectUpdate[]>; count: number }> {
    const filters: any[] = [];
    filters.push({ column: 'project_id', value: projectIds, operand: 'in' });
    filters.push({ column: 'action_taker_id', value: userId, operand: 'neq' });
    filters.push({
      column: 'updated_at',
      value: dateAcked.toISOString(),
      operand: 'gt',
    });
    const extra = { count: 0, skip };
    const result = await supabaseService.getFilteredList<ProjectUpdate>(
      'project_update',
      filters,
      '*, profile!inner(profile_image_url, first_name, last_name)',
      500,
      extra
    );
    if (result.errors && result.errors.length > 0) {
      return { data: result, count: 0 };
    }
    const projectUpdateDataWithUsers: ProjectUpdate[] | undefined =
      await getUsers(result);
    return {
      data: { data: projectUpdateDataWithUsers ?? [], errors: [] },
      count: extra.count,
    };
  },

  async getNotificationAckDate(id: string): Promise<Date> {
    const { data, errors } = await supabaseService.getSingle<{
      date_read: Date;
    }>('notification_acknowledgements', 'user_id', id);
    if (!data || (errors && errors.length > 0)) {
      return new Date(1900, 1, 1);
    }
    return new Date(data!.date_read);
  },

  async getProjectList(
    filters: { column: string; value: FilterValue; operand: string }[]
  ): Promise<ResponseViewModel<Project[]>> {
    return supabaseService.getFilteredList(
      'project',
      filters,
      '*, project_user_temp:project_user!inner(*), project_user(*, profile(*))'
    );
  },

  async removeProjectUser(id: string, project_id: number) {
    return supabaseService.deleteByMatch('project_user', {
      user_id: id,
      project_id: project_id,
    });
  },

  async deleteProject(id: number) {
    const profile = useAuthStore.getState().profile;
    if (!profile) {
      return;
    }

    await chatService.setFrozenChatForProjectId({ id }, true, profile);

    const response = await supabaseService.deleteByMatch('project', { id: id });
    if (response.errors && response.errors.length > 0) {
      return response;
    }

    return response;
  },

  async inviteUserByPhone(phone: string): Promise<ResponseViewModel<string>> {
    return supabaseService.inviteUserByPhone(phone);
  },
};
