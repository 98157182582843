import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import { ChangeEvent, useMemo } from 'react';

import FilterAutocomplete from '_components/autocomplete';
import FilterSelect from '_components/select';
import useProjectTypes from '_hooks/useProjectTypes';
import useProjectListStore from '_stores/useProjectListStore';
import { Status, Statuses } from '_types/common';
import { Profile } from '_types/profile';
interface Props {
  filteredUsers: Profile[];
}

export const SearchFilter = ({ filteredUsers }: Props) => {
  const { data: projectTypes } = useProjectTypes();

  const filters = useProjectListStore($ => $.filters);
  const setFilters = useProjectListStore($ => $.setFilters);

  const handleStatusChange = (status: Status | null): void => {
    setFilters({
      ...filters,
      status,
    });
  };

  const handleTypeChange = (type: number | null): void => {
    setFilters({
      ...filters,
      projectTypeId: type,
    });
  };

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.persist();
    const val = e.target.value.replaceAll(/ /g, '+');
    setFilters({
      ...filters,
      keyword: val,
    });
  };

  const handleUserChange = async (userId: string): Promise<void> => {
    setFilters({
      userIds: [userId],
    });
  };

  const projectTypeOptions = useMemo(() => {
    const options: { key: number | null; label: string }[] = [
      { key: null, label: 'All' },
    ];
    projectTypes?.forEach(type =>
      options.push({
        key: type.id,
        label: type.name ?? '',
      })
    );
    return options;
  }, [projectTypes]);

  return (
    <div className="flex justify-between">
      <div>
        <div className="relative mt-1 rounded-md border w-72">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            name="search"
            id="search-input"
            value={filters?.keyword ?? ''}
            onChange={handleQueryChange}
            className="p-2 block w-full rounded-md border-gray-300 pl-10 outline-primary"
            placeholder="Search Project Name/Address"
          />
        </div>
      </div>
      <div className="flex justify-end gap-x-5">
        <FilterSelect<number | null>
          onChange={handleTypeChange}
          options={projectTypeOptions}
          label="Project Type"
        />
        <FilterSelect<Status | null>
          onChange={handleStatusChange}
          options={[
            {
              key: null,
              label: 'All',
            },
            {
              key: Statuses.Active,
              label: 'Active',
            },
            {
              key: Statuses.Inactive,
              label: 'InActive',
            },
          ]}
          label="Status"
        />
        <FilterAutocomplete
          onChange={handleUserChange}
          options={filteredUsers.map(u => ({
            key: u.id,
            name: `${u.first_name} ${u.last_name}`,
          }))}
          label="All Users"
        />
      </div>
    </div>
  );
};
