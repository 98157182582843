import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Filter } from '_hooks/useOrganizations';

export interface OrganizationState {
  filters?: Filter;
  isCreateModalVisible: boolean;

  setFilters: (filters: Filter) => void;
  setIsCreateModalVisible: (isCreateModalVisible: boolean) => void;
}

const useOrganizationStore = create<OrganizationState>()(
  immer((set, _get) => {
    return {
      filters: {},
      isCreateModalVisible: false,
      setFilters: (filters: Filter) => {
        set({ filters });
      },
      setIsCreateModalVisible: (isCreateModalVisible: boolean) => {
        set({ isCreateModalVisible });
      },
    };
  })
);

export default useOrganizationStore;
