import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { ProjectType } from '_types/projectType';

export interface OrganizationState {
  originalProjectType?: ProjectType;
  projectType?: ProjectType;
  openingStageId?: string;
  isEditModalVisible: boolean;
  isConfirmRemoveModalVisible: boolean;

  setProjectType: (projectType?: ProjectType) => void;
  setOpeningStageId: (openingStageId?: string) => void;
  setIsEditModalVisible: (isEditModalVisible: boolean) => void;
  setIsConfirmRemoveModalVisible: (
    isConfirmRemoveModalVisible: boolean
  ) => void;

  setStageName: (stageId: string, name: string) => void;
  setMilestoneName: (
    stageId: string,
    milestoneId: string,
    name: string
  ) => void;

  addStage: () => void;
  addMilestone: (stageId: string) => void;

  reorderStages: (startIndex: number, endIndex: number) => void;
  reorderMilestones: (
    stageId: string,
    startIndex: number,
    endIndex: number
  ) => void;

  removeStage: (stageId: string) => void;
  removeMilestone: (stageId: string, milestoneId: string) => void;
}

const useTemplateDetailStore = create<OrganizationState>()(
  immer((set, _get) => {
    return {
      isEditModalVisible: false,
      isConfirmRemoveModalVisible: false,

      projectType: undefined,

      setProjectType: (projectType?: ProjectType) => {
        set({ originalProjectType: projectType, projectType });
      },
      setOpeningStageId: (openingStageId?: string) => {
        set({ openingStageId });
      },
      setIsEditModalVisible: (isEditModalVisible: boolean) => {
        set({ isEditModalVisible });
      },

      setIsConfirmRemoveModalVisible: (
        isConfirmRemoveModalVisible: boolean
      ) => {
        set({ isConfirmRemoveModalVisible });
      },

      addStage: () => {
        set(state => {
          if (!state.projectType) return;
          const emptyStage = state.projectType.template.find(m => !m.name);
          if (emptyStage) {
            return;
          }

          const newStageId = uuidv4();
          state.projectType.template = [
            ...state.projectType.template,
            {
              id: newStageId,
              name: '',
              sequence: 0,
              milestones: [
                { id: uuidv4(), name: '', sequence: 0, checklist: [] },
              ],
            },
          ];
          state.openingStageId = newStageId;
        });
      },

      addMilestone: (stageId: string) => {
        set(state => {
          if (!state.projectType) return;

          const index = state.projectType.template.findIndex(
            stage => stage.id === stageId
          );

          state.projectType.template[index].milestones = [
            ...state.projectType.template[index].milestones,
            {
              id: uuidv4(),
              name: '',
              sequence: 0,
              checklist: [],
            },
          ];
        });
      },

      setStageName: (stageId: string, name: string) => {
        set(state => {
          if (!state.projectType) return;
          const index = state.projectType.template.findIndex(
            stage => stage.id === stageId
          );

          state.projectType.template[index].name = name;
        });
      },

      setMilestoneName: (
        stageId: string,
        milestoneId: string,
        name: string
      ) => {
        set(state => {
          if (!state.projectType) return;
          const index = state.projectType.template.findIndex(
            stage => stage.id === stageId
          );
          const milestoneIndex = state.projectType.template[
            index
          ].milestones.findIndex(m => m.id === milestoneId);

          state.projectType.template[index].milestones[milestoneIndex].name =
            name;
        });
      },

      reorderStages: (startIndex: number, endIndex: number) => {
        set(state => {
          if (!state.projectType) return;
          const stages = state.projectType.template;

          const newStages = Array.from(stages);
          const [removed] = newStages.splice(startIndex, 1);
          newStages.splice(endIndex, 0, removed);

          state.projectType.template = newStages;
        });
      },

      reorderMilestones: (
        stageId: string,
        startIndex: number,
        endIndex: number
      ) => {
        set(state => {
          if (!state.projectType) return;

          const stageIndex = state.projectType.template.findIndex(
            stage => stage.id === stageId
          );
          if (stageIndex < 0) {
            return;
          }

          const milestones = state.projectType.template[stageIndex].milestones;

          const newMilestones = Array.from(milestones);
          const [removed] = newMilestones.splice(startIndex, 1);
          newMilestones.splice(endIndex, 0, removed);

          state.projectType.template[stageIndex].milestones = newMilestones;
        });
      },

      removeStage: (stageId: string) => {
        set(state => {
          if (!state.projectType) return;
          const index = state.projectType.template.findIndex(
            stage => stage.id === stageId
          );
          state.projectType.template.splice(index, 1);
        });
      },

      removeMilestone: (stageId: string, milestoneId: string) => {
        set(state => {
          if (!state.projectType) return;
          const index = state.projectType.template.findIndex(
            stage => stage.id === stageId
          );
          const milestoneIndex = state.projectType.template[
            index
          ].milestones.findIndex(m => m.id === milestoneId);
          state.projectType.template[index].milestones.splice(
            milestoneIndex,
            1
          );
        });
      },
    };
  })
);

export default useTemplateDetailStore;
