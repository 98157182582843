import {
  ArchiveBoxIcon,
  PencilSquareIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';

import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Spinner from '_components/spinner';
import useOrganisation from '_hooks/useOrganization';
import useOrganizationUser from '_hooks/useOrganizationUser';
import useAuthStore from '_stores/useAuthStore';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Status } from '_types/common';
import { ROLE_LABEL } from '_types/profile';
import getDisplayNameFromProfile from '_utils/getDisplayNameFromProfile';
import getErrorMessage from '_utils/getErrorMessage';

import ConfirmRemoveUserModal from './ConfirmRemoveUserModal';
import EditAccountModal from './EditAccountModal';
import EditProfileModal from './EditProfileModal';
import ResendInvitationModal from './ResendInvitationModal';
import useOrganizationUserDetailStore from './store';

export const OrganizationUserDetail = () => {
  const params = useParams<{ organizationId: string; userId: string }>();
  const snackBar = useSnackBarStore();
  const selectedOrganization = useAuthStore($ => $.selectedOrganization);
  const currentUserProfile = useAuthStore($ => $.profile);
  const canPerformAction = useAuthStore($ => $.canPerformAction);

  const { data: organizationUser, isLoading } = useOrganizationUser(
    params.organizationId ?? selectedOrganization?.id,
    params.userId
  );

  const setOrganizationUserWithDetails = useOrganizationUserDetailStore(
    $ => $.setOrganizationUserWithDetails
  );
  const organizationUserWithDetails = useOrganizationUserDetailStore(
    $ => $.organizationUserWithDetails
  );
  const setIsConfirmRemoveModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsConfirmRemoveModalVisible
  );
  const setIsEditAccountModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsEditAccountModalVisible
  );
  const setIsEditProfileModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsEditProfileModalVisible
  );
  const setIsResendInvitationModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsResendInvitationModalVisible
  );
  const invitationResent = useOrganizationUserDetailStore(
    $ => $.invitationResent
  );
  const clearState = useOrganizationUserDetailStore($ => $.clearState);

  const { data: organization } = useOrganisation(
    organizationUserWithDetails?.organization_id
  );

  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [clearState]);

  // update breadcrumbs
  useEffect(() => {
    if (params.organizationId) {
      setItems([
        { label: 'Manage' },
        {
          label: 'Organisations',
          link: '/organizations',
        },
      ]);
      if (!organization) {
        return;
      }
      addItem({
        label: organizationUserWithDetails?.organization.name ?? '',
        link: `/organizations/${organization?.id}`,
      });
      addItem({
        label: getDisplayNameFromProfile(organizationUserWithDetails?.profile),
        highlight: true,
      });
    } else {
      setItems([
        { label: 'People' },
        {
          label: 'Staff',
          link: '/people/staff',
        },
      ]);
      addItem({
        label: getDisplayNameFromProfile(organizationUserWithDetails?.profile),
        highlight: true,
      });
    }
  }, [addItem, organization, organizationUserWithDetails, params, setItems]);

  useEffect(() => {
    setOrganizationUserWithDetails(organizationUser);
  }, [setOrganizationUserWithDetails, organizationUser]);

  const displayName = useMemo(() => {
    if (!organizationUserWithDetails || !organizationUserWithDetails.profile) {
      return null;
    }
    if (
      !organizationUserWithDetails.profile.first_name &&
      !organizationUserWithDetails.profile.last_name
    ) {
      return organizationUserWithDetails.profile.email;
    }

    return `${organizationUserWithDetails.profile.first_name} ${organizationUserWithDetails.profile.last_name}`.trim();
  }, [organizationUserWithDetails]);

  const statusNode = useMemo(() => {
    if (!organizationUserWithDetails) return null;

    let canInvite = true;
    if (organizationUserWithDetails.profile.invited_at) {
      const deltaTime = Math.abs(
        moment(organizationUserWithDetails.profile.invited_at).diff(
          moment(),
          'seconds'
        )
      );
      if (deltaTime < 180) {
        canInvite = false;
      }
    }

    if (organizationUserWithDetails.status === 'INACTIVE') {
      return (
        <div className="h-6 px-2.5 rounded bg-mid-gray-1 text-white text-xs font-medium flex items-center">
          Archived
        </div>
      );
    }

    if (!organizationUserWithDetails.profile?.confirmed_at) {
      return (
        <div className="flex items-center gap-2">
          <div className="h-6 px-2.5 border rounded border-red-500 text-red-500 text-xs font-medium flex items-center">
            Pending
          </div>
          {invitationResent ? (
            <Button className="h-6 px-2.5 rounded bg-green-500 text-white text-xs font-medium flex items-center ml-4">
              Invite Sent!
            </Button>
          ) : (
            <Tippy
              className="text-xs"
              disabled={canInvite}
              content={`An invite has been sent recently. Please wait for a while to send another invitation.`}>
              <div>
                <Button
                  disabled={!canInvite}
                  className="h-6 px-2.5"
                  onClick={() => setIsResendInvitationModalVisible(true)}>
                  Resend Invite
                </Button>
              </div>
            </Tippy>
          )}
        </div>
      );
    }
    return (
      <div className="h-6 px-2.5 rounded bg-green-500 text-white text-xs font-medium flex items-center">
        Active
      </div>
    );
  }, [
    organizationUserWithDetails,
    invitationResent,
    setIsResendInvitationModalVisible,
  ]);

  const handleUpdateOrganizationUserStatus = useCallback(
    async (status: Status) => {
      if (!organizationUserWithDetails) return;
      try {
        setIsBusy(true);
        const { error } = await supabase.functions.invoke(
          'update-organization-user-status',
          {
            body: {
              organizationId: organizationUserWithDetails.organization_id,
              userId: organizationUserWithDetails.user_id,
              status: status,
            },
          }
        );
        if (error) {
          throw error;
        }
        queryClient.invalidateQueries(['organizationUsers']);
      } catch (err) {
        const errorMessage = await getErrorMessage(err);
        snackBar.show(errorMessage, 'error');
      } finally {
        setIsBusy(false);
      }
    },
    [organizationUserWithDetails, snackBar]
  );

  const canEditUserOrganization = useMemo(() => {
    if (!currentUserProfile) return false;

    if (currentUserProfile.role === 'SUPER_ADMIN') {
      return true;
    }

    if (organizationUser?.profile.role === 'CLIENT') {
      return false;
    }

    if (selectedOrganization?.organization_user?.[0]?.role !== 'ADMIN') {
      return false;
    }

    // make sure organization and actor is not archived,
    if (!canPerformAction()) {
      return false;
    }

    // cannot edit other admin info
    if (organizationUser?.role === 'ADMIN') {
      return false;
    }

    return true;
  }, [
    canPerformAction,
    currentUserProfile,
    organizationUser,
    selectedOrganization,
  ]);

  return (
    <div className="relative">
      {isBusy ? (
        <div className="absolute inset-0 bg-white opacity-75 flex justify-center items-center">
          <Spinner className="w-8 h-8" />
        </div>
      ) : null}

      {isLoading ||
      !organizationUserWithDetails ||
      !organizationUserWithDetails.profile ? (
        <div className="flex justify-center items-center border-b pb-8 mt-12 h-[400px] w-full">
          <Spinner className="w-10 h-10" />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center border-b pb-8 mt-12">
            <div className="flex items-center gap-5">
              <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-200">
                {organizationUserWithDetails.profile.profile_image_url ? (
                  <img
                    src={
                      organizationUserWithDetails.profile?.profile_image_url ??
                      ''
                    }
                    className="w-full h-full object-cover "
                    alt="avatar"
                  />
                ) : null}
              </div>
              <div>
                <div className="font-semibold text-2xl">{displayName}</div>
                <div className="text-mid-gray-1">
                  {organizationUserWithDetails.profile?.email}
                </div>
              </div>
            </div>
            <div className="flex gap-2.5 items-center">
              {canEditUserOrganization ? (
                <>
                  {organizationUserWithDetails?.status === 'INACTIVE' ? (
                    <Button
                      onClick={() =>
                        handleUpdateOrganizationUserStatus('ACTIVE')
                      }
                      color="secondary"
                      className="font-semibold border-mid-gray-1 text-mid-gray-1">
                      <ArchiveBoxIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Unarchive
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        handleUpdateOrganizationUserStatus('INACTIVE')
                      }
                      color="gray">
                      <ArchiveBoxIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Archive
                    </Button>
                  )}
                  <Button
                    type="button"
                    onClick={() => setIsConfirmRemoveModalVisible(true)}
                    className="inline-flex items-center rounded-md border border-transparent px-4 py-1.5 font-semibold text-white bg-red-400 hover:bg-red-300 shadow-sm focus:outline-none ">
                    <XCircleIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Remove
                  </Button>
                </>
              ) : null}
              <div className="ml-2">
                <div className="leading-6">
                  {moment(
                    organizationUserWithDetails.profile.created_at
                  ).format('DD MMM YYYY')}
                </div>
                <div className="text-mid-gray-1 text-xs">Join Date</div>
              </div>
            </div>
          </div>
          <div className="flex flex-start mt-5">
            <div className="w-32">
              <div
                className="border-l-2 pl-4 font-semibold text-dark-gray-2 h-9 flex items-center cursor-pointer border-primary text-primary"
                onClick={() => {
                  console.log('details');
                }}>
                <div className="flex flex-start items-center">
                  <span>Details</span>
                </div>
              </div>
            </div>
            <div className="mb-10 ml-8 flex-1">
              <div className="border border-light-gray-3 rounded-xl overflow-hidden">
                <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
                  <div className="font-semibold">Account Info</div>
                  <div>
                    {currentUserProfile?.role === 'SUPER_ADMIN' ? (
                      <Button
                        color="secondary"
                        onClick={() => setIsEditAccountModalVisible(true)}>
                        <PencilSquareIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Edit
                      </Button>
                    ) : null}
                  </div>
                </div>
                <div className="p-5 flex flex-col gap-4 border-t">
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Role
                    </div>
                    <div className="text-dark-gray-2 capitalize">
                      {ROLE_LABEL[organizationUserWithDetails.role]}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Mobile No.
                    </div>
                    <div className="text-dark-gray-2">
                      {organizationUserWithDetails.profile.mobile_number ??
                        '(no data)'}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Email
                    </div>
                    <div className="text-dark-gray-2">
                      {organizationUserWithDetails.profile.email}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Status
                    </div>
                    <div>{statusNode}</div>
                  </div>
                </div>
              </div>

              <div className="border border-light-gray-3 rounded-xl overflow-hidden mt-8">
                <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
                  <div className="font-semibold">Profile Info</div>
                  <div>
                    {currentUserProfile?.role === 'SUPER_ADMIN' ? (
                      <Button
                        color="secondary"
                        onClick={() => setIsEditProfileModalVisible(true)}>
                        <PencilSquareIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Edit
                      </Button>
                    ) : null}
                  </div>
                </div>
                <div className="p-5 flex flex-col gap-4 border-t">
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      First Name
                    </div>
                    <div className="text-dark-gray-2 capitalize">
                      {organizationUserWithDetails.profile.first_name ??
                        '(no data)'}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Last Name
                    </div>
                    <div className="text-dark-gray-2">
                      {organizationUserWithDetails.profile.last_name ??
                        '(no data)'}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Gender
                    </div>
                    <div className="text-dark-gray-2">
                      {organizationUserWithDetails.profile.gender ??
                        '(no data)'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ConfirmRemoveUserModal />
      <EditAccountModal />
      <EditProfileModal />
      <ResendInvitationModal />
    </div>
  );
};

export default OrganizationUserDetail;
