import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Spinner from '_components/spinner';
import useOrganizationUsers from '_hooks/useOrganizationUsers';
import useProjectUserInvitationsByProjectId from '_hooks/useProjectUserInvitationsByProjectId';
import useProjectUsers, {
  ProjectUserWithProfile,
} from '_hooks/useProjectUsers';

import ProjectUserItem from './ProjectUserItem';

export const ProjectUsersList = () => {
  const { id } = useParams();

  const { data: projectUsers, isInitialLoading: isLoadingProjectUsers } =
    useProjectUsers(id);
  const {
    data: organizationUsers,
    isInitialLoading: isLoadingOrganizationUsers,
  } = useOrganizationUsers(projectUsers?.map(u => u.user_id));

  const { data: invitations } = useProjectUserInvitationsByProjectId(id);

  const projectUsersGroupedByRole = useMemo(() => {
    if (!projectUsers || !organizationUsers) return [];

    const map: { [key: string]: ProjectUserWithProfile[] } = {};

    projectUsers.forEach(user => {
      if (!user || !user.profile) return;

      let role;
      if (
        user.profile.role !== 'SUPER_ADMIN' &&
        user.profile.role !== 'CLIENT'
      ) {
        role = organizationUsers.find(u => u.user_id === user.user_id)?.role;
      } else {
        role = user.profile.role;
      }

      const key = role ?? 'UNKNOWN';

      if (!map[key]) {
        map[key] = [];
      }
      map[key].push(user);
    });

    if (invitations && invitations.length > 0) {
      if (!map['CLIENT']) map['CLIENT'] = [];
      invitations.forEach(invitation => {
        map['CLIENT'].push({
          user_id: invitation.user_id,
          project_id: invitation.project_id,
          profile: invitation.profile,
          created_at: '',
          status: 'invited',
          updated_at: '',
        });
      });
    }

    return Object.keys(map).map(k => ({ role: k, users: map[k] }));
  }, [invitations, organizationUsers, projectUsers]);

  if (isLoadingProjectUsers || isLoadingOrganizationUsers) {
    return (
      <div className="w-full py-10 flex justify-center">
        <Spinner className="w-6 h-6 z-15" />
      </div>
    );
  }

  return (
    <div className="p-5 flex flex-col gap-4">
      {projectUsersGroupedByRole.map(grouped => {
        return (
          <div className="flex justify-start" key={grouped.role}>
            <div className="capitalize text-mid-gray-1 font-semibold mt-1 w-[80px]">
              {grouped.role.toLowerCase()}
            </div>
            <div className="ml-12 flex flex-col gap-y-4">
              {grouped.users.map((projectUser, i) => {
                return (
                  <ProjectUserItem
                    key={projectUser.project_id + projectUser.user_id}
                    projectUser={projectUser}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
