import { CheckIcon } from '@heroicons/react/24/solid';

import { Field, Form, Formik, FormikProps } from 'formik';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

interface FormValues {
  email: string;
  password: string;
  confirm_password: string;
  otp: string;
}

const validationSchema = yup
  .object()
  .shape({
    password: yup.string().trim().min(6).label('Password').required(),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    otp: yup.string().length(6).label('OTP').required(),
  })
  .required();

function ResetPassword() {
  const navigate = useNavigate();

  const [isBusy, setIsBusy] = useState(false);
  const [isSetPasswordDone, setIsSetPasswordDone] = useState(false);
  const [isVerifyOtpDone, setIsVerifyOtpDone] = useState(false);

  const snackBar = useSnackBarStore();
  const restore = useAuthStore($ => $.restore);

  const { search } = useLocation();

  const email = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('email');
  }, [search]);

  const onSetPasswordSubmit = async (values: FormValues) => {
    if (!email) {
      return;
    }
    try {
      setIsBusy(true);
      if (!isVerifyOtpDone) {
        // cleanup any persiting session
        await supabase.auth.signOut();

        const { error: verifyOtpError } = await supabase.auth.verifyOtp({
          email,
          token: values.otp,
          type: 'recovery',
        });
        if (verifyOtpError) {
          throw verifyOtpError;
        }
        setIsVerifyOtpDone(true);
      }

      const {
        data: { user },
        error,
      } = await supabase.auth.updateUser({
        password: values.password,
      });
      if (error) {
        throw error;
      }

      const { data: profile, error: getProfileError } = await supabase
        .from('profile')
        .select()
        .eq('id', user?.id)
        .single();
      if (getProfileError) {
        throw getProfileError;
      }

      if (profile?.role !== 'CLIENT') {
        await restore();
        if (profile?.first_time_login) {
          navigate('/profile');
        } else {
          navigate('/');
        }
      } else {
        supabase.auth.signOut();
        setIsSetPasswordDone(true);
      }
    } catch (error) {
      const msg = await getErrorMessage(error);
      if (msg.includes('expired')) {
        snackBar.show(
          'Token has expired or is invalid, please submit a new password reset request',
          'error'
        );
        navigate('/forgot-password');
      } else {
        snackBar.show(msg, 'error');
      }
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <div className="px-5 flex flex-col items-center mt-32 w-full sm:w-[600px] mx-auto">
      <div className="text-2xl font-semibold text-center tracking-tight">
        Reset Password
      </div>
      {!email && (
        <div className="mt-4 text-xs text-red-400">
          Link got expired, please check with admin to resend the link
        </div>
      )}
      {isSetPasswordDone ? (
        <div>
          <div className="text-dark-gray-2 leading-6 bg-light-gray-5 p-5 mt-8 rounded-2xl shadow-[0_0_3px_rgba(176,186,191,0.5)]">
            Password reset successfully. You can now login to the Sunnyside App.
          </div>
          <div className="mt-8">
            <h3 className="font-semibold text-center text-lg sm:text-2xl tracking-tight">
              Don't have the app yet? Get it here!
            </h3>
            <div className="flex justify-center gap-3 mt-6">
              <a
                href="https://apps.apple.com/vn/app/sunnyside-homes/id1666287931"
                target="_blank"
                rel="noreferrer">
                <img alt="App Store" src="/app-store-link.png" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.sunnysideapp"
                target="_blank"
                rel="noreferrer">
                <img alt="Play Store" src="/google-play-link.png" />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8 rounded-md w-full shadow-[0_0_3px_rgba(176,186,191,0.5)]">
          <Formik
            enableReinitialize
            initialValues={{
              email: email ?? '',
              password: '',
              confirm_password: '',
              otp: '',
            }}
            onSubmit={onSetPasswordSubmit}
            validationSchema={validationSchema}>
            {(formikProps: FormikProps<FormValues>) => (
              <Form>
                <div className="bg-light-gray-5 p-4 sm:p-8">
                  <div className="flex items-start">
                    <Label className="w-20 sm:w-36 text-xs sm:text-sm mt-2.5">
                      Email
                    </Label>
                    <div className="flex-1">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoFocus
                        disabled
                        placeholder="Email"
                        className="w-full py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    {formikProps.errors.email && formikProps.touched.email && (
                      <span className="ml-6 text-red-500">
                        {formikProps.errors.email}
                      </span>
                    )}
                  </div>

                  <div className="flex items-start mt-5">
                    <Label className="w-20 sm:w-36 text-xs sm:text-sm mt-2.5">
                      Password
                    </Label>
                    <div className="flex-1">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        placeholder="******"
                        className="w-full py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {formikProps.errors.password &&
                        formikProps.touched.password && (
                          <div className="mt-2 text-red-500">
                            {formikProps.errors.password}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="flex items-start mt-5">
                    <Label className="w-20 sm:w-36 text-xs sm:text-sm mt-2.5">
                      Retype Password
                    </Label>
                    <div className="flex-1">
                      <Field
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        placeholder="******"
                        className="w-full py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {formikProps.errors.confirm_password &&
                        formikProps.touched.confirm_password && (
                          <div className="mt-2 text-red-500">
                            {formikProps.errors.confirm_password}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="flex items-start mt-5 relative">
                    <Label className="w-20 sm:w-36 text-xs sm:text-sm mt-2.5">
                      OTP Code
                    </Label>
                    <div className="flex-1">
                      <Field
                        id="otp"
                        name="otp"
                        type="text"
                        disabled={isVerifyOtpDone}
                        placeholder="e.g. 123456"
                        className="w-full py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {formikProps.errors.otp && formikProps.touched.otp && (
                        <div className="mt-2 text-red-500">
                          {formikProps.errors.otp}
                        </div>
                      )}
                    </div>
                    {isVerifyOtpDone ? (
                      <CheckIcon className="absolute right-2 top-2 text-green-500 h-5 w-5" />
                    ) : null}
                  </div>
                </div>
                <div className="p-5 flex justify-center border-t">
                  <Button type="submit" disabled={isBusy}>
                    Reset Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
