import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';

export const fetchSelectedOrganizationClients = async () => {
  const organization = useAuthStore.getState().selectedOrganization;
  if (!organization) {
    return [];
  }

  const { data, error } = await supabase
    .from('profile')
    .select('*, projects:project!inner(*)')
    .eq('project.organization_id', organization.id)
    .eq('role', 'CLIENT')
    .order('created_at', {
      ascending: false,
    });

  if (error) {
    throw error;
  }

  return data;
};

const useSelectedOrganizationClients = () =>
  useQuery(['selectedOrganizationClients'], () =>
    fetchSelectedOrganizationClients()
  );

export default useSelectedOrganizationClients;
