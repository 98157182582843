import { Dialog, Transition } from '@headlessui/react';

import { useFormik } from 'formik';
import { Fragment, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Role } from '_types/profile';
import getErrorMessage from '_utils/getErrorMessage';

import useOrganizationUserDetailStore from './store';

interface FormValues {
  role: Role;
  email: string;
  mobile_no: string;
}

const EditAccountModal = () => {
  const organizationUserWithProfile = useOrganizationUserDetailStore(
    $ => $.organizationUserWithDetails
  );
  const isEditAccountModalVisible = useOrganizationUserDetailStore(
    $ => $.isEditAccountModalVisible
  );
  const setIsEditAccountModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsEditAccountModalVisible
  );
  const snackBar = useSnackBarStore();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const OrganizationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .trim()
          .email('please enter a valid email')
          .label('Email')
          .required('Email is required'),
      }),
    []
  );

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      role: organizationUserWithProfile?.role ?? 'ADMIN',
      email: organizationUserWithProfile?.profile.email ?? '',
      mobile_no: organizationUserWithProfile?.profile.mobile_number ?? '',
    },
    validationSchema: OrganizationSchema,
    onSubmit: async values => {
      if (!organizationUserWithProfile) return;
      try {
        setIsSubmitting(true);

        const { error } = await supabase
          .from('organization_user')
          .update({
            role: values.role,
          })
          .eq('user_id', organizationUserWithProfile.user_id)
          .eq('organization_id', organizationUserWithProfile.organization_id);

        if (error) {
          throw error;
        }

        const { error: updateAccountError } = await supabase.functions.invoke(
          'update-user-account',
          {
            body: {
              userId: organizationUserWithProfile.user_id,
              email: values.email,
              currentEmail: organizationUserWithProfile.profile.email,
              mobileNo: values.mobile_no,
            },
          }
        );
        if (updateAccountError) {
          throw updateAccountError;
        }

        snackBar.show('User updated successfully', 'success');
        queryClient.invalidateQueries({ queryKey: ['organizationUsers'] });
        setIsEditAccountModalVisible(false);
      } catch (err) {
        const errorMessage = await getErrorMessage(err);
        snackBar.show(errorMessage ?? 'User failed to update', 'error');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    setIsEditAccountModalVisible(false);
  };

  useEffect(() => {
    setErrorMessage(undefined);
  }, [formik.values.email]);

  return (
    <Transition.Root appear show={isEditAccountModalVisible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsEditAccountModalVisible(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-xl relative">
                {isSubmitting ? (
                  <div className="absolute inset-0 bg-white opacity-70 z-20 flex justify-center items-center">
                    <Spinner className="w-8 h-8" />
                  </div>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  <div className="font-semibold text-dark-gray-1 text-lg p-5">
                    Edit Account Info
                  </div>
                  <div className="px-5 py-7 bg-light-gray-5 border-t border-light-gray-3">
                    <div className="relative flex items-center">
                      <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                        Role
                      </span>
                      <div className="flex-grow border-t border-light-gray-3 ml-3" />
                    </div>
                    <div className="flex items-center mt-4">
                      <Label className="w-24">User Role</Label>
                      <div>
                        <select
                          name="role"
                          placeholder="Role"
                          disabled={isSubmitting}
                          onChange={formik.handleChange}
                          value={formik.values.role}
                          onBlur={formik.handleBlur}
                          className="block w-48 ml-4 py-1.5 px-2.5 w-full rounded-lg border border-gray-200 sm:text-sm focus:outline-none">
                          <option value="DESIGNER">Designer</option>
                          <option value="ADMIN">Admin</option>
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Mobile No.</Label>
                      <div className="ml-4">
                        <input
                          name="mobile_no"
                          placeholder="e.g. 91234567"
                          disabled={isSubmitting}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile_no}
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 sm:text-sm disabled:bg-light-gray-3 focus:outline-none"
                        />
                        {formik.errors.mobile_no && formik.touched.mobile_no ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.mobile_no}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Email</Label>
                      <div className="ml-4">
                        <input
                          name="email"
                          title="Email"
                          placeholder="e.g. john.tan@company.com"
                          autoComplete="email"
                          disabled={isSubmitting}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 sm:text-sm disabled:bg-light-gray-3 focus:outline-none"
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {errorMessage ? (
                      <div className="mt-1 text-red-400">{errorMessage}</div>
                    ) : null}
                    {/* <div className="text-xs font-medium mt-5">
                      Any changes to mobile number or email will have to be
                      re-verified by the user.
                    </div> */}
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                    <Button color="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button disabled={isSubmitting} type="submit">
                      Save
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditAccountModal;
