import { observer } from 'mobx-react-lite';

import { FileNotFound } from './file-not-found';

export const DocViewer = observer(
  (props: { fileUrl: string; fileName: string; fileType?: string }) => {
    const { fileUrl, fileType, fileName } = props;

    const renderSwitch = (type?: string) => {
      switch (type) {
        case 'image/jpeg':
        case 'image/png': {
          return (
            <img
              src={fileUrl}
              className="max-w-full max-h-full"
              alt={fileName}
            />
          );
        }
        case 'application/pdf':
          return (
            <iframe
              style={{ width: '100%', height: '100%' }}
              src={fileUrl}
              title={fileName}
            />
          );
        default:
          return <FileNotFound fileName={fileName} fileUrl={fileUrl} />;
      }
    };

    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}>
        {renderSwitch(fileType)}
      </div>
    );
  }
);
