// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getErrorMessage = async (error: any) => {
  if (error?.context?.json) {
    const e = await error.context.json();
    return e.error;
  }
  if (error.message) return error.message;
  return null;
};

export default getErrorMessage;
