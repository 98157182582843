import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProjectCard } from '_components/project-card';
import Spinner from '_components/spinner';
import useProject from '_hooks/useProjectBasicInfo';
import { ProjectStore } from '_store/project-store';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';

import { ProjectNavigator } from '../../shared/components/project-navigator';
import { ProgressTracker } from './progress-tracker';
import { ProjectDetailPage } from './project-detail';
import { ProjectMilestoneList } from './project-milestone-list';
import { ProjectTeam } from './project-team';

export const ProjectDetails = observer(() => {
  const { getProjectUpdate } = ProjectStore;
  const { search } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const [isLoading, setIsLoading] = useState(true);

  const projectId = useMemo<number | null>(() => {
    const idAsNumber = Number(id);
    return !isNaN(idAsNumber) ? idAsNumber : null;
  }, [id]);

  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  const { data: project, isLoading: isLoadingProjectDetail } =
    useProject(projectId);

  // update breadcrumbs
  useEffect(() => {
    setItems([{ label: 'Projects', link: '/projects' }]);
    if (!project) {
      return;
    }
    addItem({
      label: project?.name,
      highlight: true,
    });
  }, [addItem, project, setItems]);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    if (!search) {
      navigate({
        pathname: `/projects/${projectId}`,
        search: '?selectedTab=details',
      });
      return;
    }
    (async () => {
      // await getMilestones(projectId);
      await getProjectUpdate(projectId);
      setIsLoading(false);
    })();
  }, [id, getProjectUpdate, search, navigate, projectId]);

  if (!projectId) {
    return (
      <div className="w-full py-10 flex justify-center">
        Missing project ID or invalid
      </div>
    );
  }

  if (isLoading || isLoadingProjectDetail) {
    return (
      <div className="w-full py-10 flex justify-center">
        <Spinner className="w-8 h-8 z-15" />
      </div>
    );
  }

  return (
    <div className="mt-12">
      <ProjectCard project={project ?? undefined} />
      <div className="w-full border-t border-light-gray-3 mt-5 mb-8" />
      <div className="flex flex-start">
        <ProjectNavigator selectedTab={query.get('selectedTab')} />
        <div className="mb-10 ml-8 flex-1">
          {query.get('selectedTab') === 'details' ? (
            <div>
              <ProjectTeam />
              <div className="mt-8">
                {project ? (
                  <ProjectDetailPage projectId={projectId} project={project} />
                ) : null}
              </div>
              <div className="mt-8">
                <ProgressTracker />
              </div>
            </div>
          ) : (
            <ProjectMilestoneList projectId={projectId} />
          )}
        </div>
      </div>
    </div>
  );
});
