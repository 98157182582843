import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/solid';

import { Fragment } from 'react';

import Button from './button';

export const DeleteDialog = (props: {
  openConfirmDelete: boolean;
  closeConfirmDelete: () => void;
  handleDeleteCompleted: () => void;
}) => {
  const { openConfirmDelete, closeConfirmDelete, handleDeleteCompleted } =
    props;
  return (
    <Transition.Root show={openConfirmDelete} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeConfirmDelete}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <div>
                  <div className="flex items-center p-4 bg-light-gray-5">
                    <XCircleIcon className="text-red-500 w-8 h-8" />
                    <div className="ml-2 font-semibold">
                      Are you sure you want to permanently delete this file?
                    </div>
                  </div>
                  <div className="flex-grow border-t border-light-gray-3" />
                  <div className="flex justify-end p-4">
                    <Button color="secondary" onClick={closeConfirmDelete}>
                      Cancel
                    </Button>
                    <Button type="submit" onClick={handleDeleteCompleted}>
                      Delete
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
