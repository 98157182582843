import { MessagePayload } from 'firebase/messaging';
import { useCallback, useEffect, useState } from 'react';

import { onMessageListener, requestForToken } from '_services/firebase-service';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';

export const Notification = () => {
  const snackBar = useSnackBarStore();
  const profile = useAuthStore($ => $.profile);
  const [notification, setNotification] = useState({
    title: '',
    body: '',
  });

  const notify = useCallback(
    () =>
      snackBar.show(`${notification?.title}: ${notification?.body}`, 'info'),
    [notification, snackBar]
  );

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification, notify]);

  useEffect(() => {
    if (!profile) {
      return;
    }
    requestForToken(profile);
  }, [profile]);

  onMessageListener()
    .then((payload: MessagePayload) => {
      setNotification({
        title: payload?.notification?.title || '',
        body: payload?.notification?.body || '',
      });
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log('failed: ', err));

  return <></>;
};
