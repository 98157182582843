import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface LayoutState {
  isBusy: boolean;
  busyMessage?: string;

  setIsBusy: (isBusy: boolean) => void;
  setBusyMessage: (message: string) => void;
}

const useLayoutStore = create<LayoutState>()(
  immer((set, _get) => {
    return {
      isBusy: false,

      setIsBusy: (isBusy: boolean) => {
        set({ isBusy });
      },

      setBusyMessage: (message: string) => {
        set({ busyMessage: message });
      },
    };
  })
);

export default useLayoutStore;
