import { ArrowRightIcon } from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { Fragment, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import Spinner from '_components/spinner';
import useClientProfile from '_hooks/useClientProfile';
import useProjectTypes from '_hooks/useProjectTypes';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import { ROLE_LABEL } from '_types/profile';
import getDisplayNameFromProfile from '_utils/getDisplayNameFromProfile';

export const OrganizationUserDetail = () => {
  const params = useParams<{ userId: string }>();

  const { data: clientProfile, isLoading } = useClientProfile(params.userId);
  const { data: projectTypes } = useProjectTypes();

  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  // update breadcrumbs
  useEffect(() => {
    setItems([
      { label: 'People' },
      {
        label: 'Clients',
        link: '/people/clients',
      },
    ]);
    addItem({
      label: getDisplayNameFromProfile(clientProfile),
      highlight: true,
    });
  }, [addItem, clientProfile, params, setItems]);

  const displayName = useMemo(
    () => getDisplayNameFromProfile(clientProfile),
    [clientProfile]
  );

  const statusNode = useMemo(() => {
    if (!clientProfile) return null;
    if (!clientProfile.confirmed_at) {
      return (
        <div className="flex">
          <div className="h-6 px-2.5 border rounded border-red-500 text-red-500 text-xs font-medium flex items-center">
            Pending
          </div>
        </div>
      );
    }
    return (
      <div className="h-6 px-2.5 rounded bg-green-500 text-white text-xs font-medium flex items-center">
        Active
      </div>
    );
  }, [clientProfile]);

  return (
    <div className="relative">
      {isLoading ? (
        <div className="flex justify-center items-center border-b pb-8 mt-12 h-[400px] w-full">
          <Spinner className="w-10 h-10" />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center border-b pb-8 mt-12">
            <div className="flex items-center gap-5">
              <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-200">
                {clientProfile?.profile_image_url ? (
                  <img
                    src={clientProfile?.profile_image_url ?? ''}
                    className="w-full h-full object-cover "
                    alt="avatar"
                  />
                ) : null}
              </div>
              <div>
                <div className="font-semibold text-2xl">{displayName}</div>
                <div className="text-mid-gray-1">{clientProfile?.email}</div>
              </div>
            </div>
            <div className="flex gap-2.5 items-center"></div>
          </div>
          <div className="flex flex-start mt-5">
            <div className="w-32">
              <div className="border-l-2 pl-4 font-semibold text-dark-gray-2 h-9 flex items-center cursor-pointer border-primary text-primary">
                <div className="flex flex-start items-center">
                  <span>Details</span>
                </div>
              </div>
            </div>
            <div className="mb-10 ml-8 flex-1">
              <div className="border border-light-gray-3 rounded-xl overflow-hidden">
                <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
                  <div className="font-semibold">Account Info</div>
                  <div></div>
                </div>
                <div className="p-5 flex flex-col gap-4 border-t">
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Role
                    </div>
                    <div className="text-dark-gray-2 capitalize">
                      {clientProfile?.role
                        ? ROLE_LABEL[clientProfile?.role]
                        : null}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Mobile No.
                    </div>
                    <div className="text-dark-gray-2">
                      {clientProfile?.mobile_number ?? '(no data)'}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Email
                    </div>
                    <div className="text-dark-gray-2">
                      {clientProfile?.email}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Status
                    </div>
                    <div>{statusNode}</div>
                  </div>
                </div>
              </div>

              <div className="border border-light-gray-3 rounded-xl overflow-hidden mt-8">
                <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
                  <div className="font-semibold">Profile Info</div>
                </div>
                <div className="p-5 flex flex-col gap-4 border-t">
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      First Name
                    </div>
                    <div className="text-dark-gray-2 capitalize">
                      {clientProfile?.first_name ?? '(no data)'}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Last Name
                    </div>
                    <div className="text-dark-gray-2">
                      {clientProfile?.last_name ?? '(no data)'}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-[120px] text-mid-gray-1 font-semibold">
                      Gender
                    </div>
                    <div className="text-dark-gray-2">
                      {clientProfile?.gender ?? '(no data)'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-light-gray-3 rounded-xl overflow-hidden mt-8">
                <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
                  <div className="font-semibold">Projects</div>
                </div>
                <div className="flex flex-col border-t">
                  {clientProfile?.projects.map((project, index) => (
                    <Fragment key={project.id}>
                      {index !== 0 ? <div className="border-t" /> : null}
                      <div
                        className={classNames(
                          'grid grid-cols-12 items-center p-5'
                        )}>
                        <div className="col-span-6">
                          <div className="flex items-center">
                            {project.cover_image_url ? (
                              <img
                                alt="cover"
                                src={project.cover_image_url}
                                className="w-[80px] h-[60px] object-center object-cover rounded-lg"
                              />
                            ) : (
                              <img width="80" height="60" alt="" />
                            )}
                            <div className="ml-4">
                              {project.name}
                              <div className="text-mid-gray-1 text-xs leading-6">
                                <span>{project?.address?.street}, </span>
                                <span>
                                  {project?.address?.building_name}
                                  {project?.address?.building_name && ','}{' '}
                                </span>
                                <span>
                                  {project?.address?.unit}
                                  {project?.address?.unit && ','}{' '}
                                </span>
                                <span>{project?.address?.block_no}, </span>
                                <span>{project?.address.country}</span>
                                <span>{project?.address?.postal_code}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-3">
                          {projectTypes?.find(e => e.id === project.type)?.name}
                        </div>
                        <div className="col-span-2">
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                'w-2 h-2 rounded-full',
                                project.status === 'ACTIVE'
                                  ? 'bg-green-500'
                                  : 'bg-gray-400'
                              )}
                            />
                            <span className="ml-2 capitalize">
                              {project.status === 'ACTIVE'
                                ? 'Active'
                                : 'Archived'}
                            </span>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <Link to={`/projects/${project.id}`}>
                            <ArrowRightIcon className="w-6 h-6 text-light-gray-1" />
                          </Link>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrganizationUserDetail;
