import { Checklist } from './checklist';
import { MilestoneHideStatus } from './milestone-status';
import { MilestoneImageOrFile } from './milestone-upload';

export type Milestone = {
  id?: number;
  name: string;
  notes: string;
  project_stage_id: number;
  project_checklist_item: Checklist[];
  project_milestone_image?: MilestoneImageOrFile[];
  project_milestone_file?: MilestoneImageOrFile[];
  status: string;
  hide_status: MilestoneHideStatus;
  created_at?: Date;
  updated_at?: Date;
  dates: Date[];
  minDate: Date | null;
};

export const getMilestoneOrDefault = (milestone?: Milestone) =>
  milestone ?? {
    notes: '',

    project_checklist_item: [],

    project_milestone_image: [],

    project_milestone_file: [],

    status: '',

    name: '',

    created_at: new Date(),

    updated_at: new Date(),
  };
