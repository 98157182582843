import { Dialog, Transition } from '@headlessui/react';

import { Fragment, ReactNode, useEffect, useState } from 'react';

import Button from './button';
import Spinner from './spinner';

interface Props {
  isVisible: boolean;
  title?: string;
  content?: ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmWaitTimeAsSeconds?: number;
  isBusy?: boolean;
  confirmButtonClassName?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog = ({
  isVisible,
  title,
  content,
  cancelLabel,
  confirmLabel,
  confirmWaitTimeAsSeconds = 0,
  isBusy = false,
  confirmButtonClassName,
  onClose,
  onConfirm,
}: Props) => {
  const [remainingTime, setRemaningTime] = useState(confirmWaitTimeAsSeconds);

  useEffect(() => {
    if (!remainingTime) {
      return;
    }
    const intervalId = setInterval(() => {
      setRemaningTime(remainingTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingTime]);

  return (
    <Transition.Root show={isVisible} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={onClose} unmount>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg relative">
                {isBusy ? (
                  <div className="absolute inset-0 bg-white opacity-50 flex justify-center items-center">
                    <Spinner className="h-5 w-5" />
                  </div>
                ) : null}
                <div className="font-semibold text-dark-gray-1 text-lg p-5">
                  {title ?? 'Confirmation'}
                </div>

                <div className="py-7 px-5 bg-light-gray-5 border-t border-light-gray-3">
                  {content ?? 'Are you sure?'}
                </div>

                <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                  <Button color="secondary" onClick={onClose}>
                    {cancelLabel ?? 'Cancel'}
                  </Button>
                  <Button
                    type="button"
                    disabled={remainingTime > 0}
                    onClick={onConfirm}
                    color="red">
                    {confirmLabel ?? 'Confirm'}
                    {remainingTime > 0 ? ` (${remainingTime}s)` : null}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationDialog;
