import { Profile } from '_types/profile';

const getDisplayNameFromProfile = (profile?: Profile) => {
  if (!profile) {
    return '';
  }
  if (!profile.first_name && !profile.last_name) {
    return `${profile.email}`;
  }

  return `${profile.first_name ?? ''} ${profile.last_name ?? ''}`.trim();
};

export default getDisplayNameFromProfile;
