import { XCircleIcon } from '@heroicons/react/20/solid';

import { useCallback, useState } from 'react';

import ConfirmationDialog from '_components/ConfirmationDialog';
import { ProjectTemplateStageMilestone } from '_types/projectType';

import useTemplateDetailStore from '../store';

interface Props {
  stageId: string;
  milestone: ProjectTemplateStageMilestone;
  showDeleteButton: boolean;
}

const MilestoneEditor = ({ stageId, milestone, showDeleteButton }: Props) => {
  const setMilestoneName = useTemplateDetailStore($ => $.setMilestoneName);
  const removeMilestone = useTemplateDetailStore($ => $.removeMilestone);

  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] =
    useState(false);

  const handleConfirmDelete = useCallback(() => {
    removeMilestone(stageId, milestone.id);
  }, [milestone, removeMilestone, stageId]);

  return (
    <div className="flex items-center">
      <input
        className="px-3 py-1 w-96 block rounded-md border-gray-300 outline-none border border-light-gray-2"
        value={milestone.name}
        onChange={e => {
          setMilestoneName(stageId, milestone.id, e.target.value);
        }}
        placeholder="e.g. Contract signature with client"
      />
      {showDeleteButton ? (
        <XCircleIcon
          className="ml-2.5 w-6 h-6 text-mid-gray-5 cursor-pointer"
          onClick={() => setIsConfirmDeleteModalVisible(true)}
        />
      ) : null}

      <ConfirmationDialog
        isVisible={isConfirmDeleteModalVisible}
        title="Confirm Delete"
        content={
          <div>
            <div>Are you sure you want to delete this milestone?</div>
          </div>
        }
        onClose={() => setIsConfirmDeleteModalVisible(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default MilestoneEditor;
