import { Popover, Transition } from '@headlessui/react';
import { FolderPlusIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/solid';
import { Avatar, AvatarGroup } from '@mui/material';

import { orderBy } from 'lodash';
import moment from 'moment';
import React, { Fragment, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Spinner from '_components/spinner';
import { ProjectInfo } from '_hooks/useProjects';
import useProjectTypes from '_hooks/useProjectTypes';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';

import theme from '../../theme/theme';

const PAGE_SIZE = 7;

export const ProjectList = (props: {
  projects: ProjectInfo[];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const currentDate = moment(new Date());
  const [currentPage, setCurrentPage] = useState(0);

  const canPerformAction = useAuthStore($ => $.canPerformAction);

  const { projects, isLoading } = props;

  const { data: projectTypes } = useProjectTypes();

  const snackBar = useSnackBarStore();

  const [isBusy, setIsBusy] = useState(false);

  const navigateEditProject = (projectId?: number) => {
    if (!projectId) return;
    navigate({
      pathname: `/projects/${projectId}/edit`,
    });
  };

  const handleChangeStatus = async (status: string, projectId?: number) => {
    if (!projectId) return;

    try {
      setIsBusy(true);
      const { error } = await supabase.functions.invoke('update-project', {
        body: {
          projectArgs: { id: projectId, status },
        },
      });

      if (error) {
        throw error;
      }

      snackBar.show('Project status updated successfully', 'success');
      queryClient.invalidateQueries(['projects']);
    } finally {
      setIsBusy(false);
    }
  };

  const currentPageData = useMemo(() => {
    if (!projects) return [];

    return orderBy(projects, ['created_at'], ['desc']).slice(
      currentPage * PAGE_SIZE,
      (currentPage + 1) * PAGE_SIZE
    );
  }, [currentPage, projects]);

  const pageCount = useMemo(() => {
    if (!projects) {
      return 0;
    }
    return Math.ceil(projects.length / PAGE_SIZE);
  }, [projects]);

  return (
    <div className="border border-light-gray-3 rounded-lg overflow-hidden relative">
      {isLoading || isBusy ? (
        <div className="absolute inset-0 bg-white opacity-75 flex justify-center items-center z-20">
          <Spinner className="w-8 h-8" />
        </div>
      ) : null}
      <table className="w-full">
        <thead className="bg-light-gray-5">
          <tr>
            <th className="text-mid-gray-2 font-normal py-3 px-4 w-[100px]">
              Cover
            </th>
            <th className="text-mid-gray-2 font-normal py-3 px-4 text-left">
              Project Name
            </th>
            <th className="w-48 text-mid-gray-2 font-normal py-3 px-4 text-left">
              People
            </th>
            <th className="w-48 text-mid-gray-2 font-normal py-3 px-4 text-left">
              Project Type
            </th>
            <th className="w-40 text-mid-gray-2 font-normal py-3 px-4 text-left">
              Days in Active
            </th>
            <th className="w-20"></th>
          </tr>
        </thead>

        {currentPageData.length ? (
          <tbody>
            {currentPageData.map(project => {
              return (
                <tr key={project.id} className="border-t border-light-gray-3">
                  <td className="h-20 flex items-center justify-center">
                    {project.cover_image_url ? (
                      <img
                        alt="cover"
                        src={project.cover_image_url}
                        className="w-[80px] h-[60px] object-center object-cover rounded-lg"
                      />
                    ) : (
                      <img width="80" height="60" alt="" />
                    )}
                  </td>
                  <td className="px-4">
                    <div className="text-nowrap">
                      <Link
                        className="text-blue-500"
                        to={`/projects/${project.id}?selectedTab=details`}
                        id={`projectname-${project.name}-btn`}>
                        {project.status === 'INACTIVE'
                          ? `(Archived) ${project.name}`
                          : project.name}
                      </Link>
                    </div>
                  </td>
                  <td className="px-4">
                    <AvatarGroup sx={{ display: 'inline-flex' }} max={4}>
                      {(project.project_user ?? []).map((user, index) => (
                        <Avatar
                          sx={{
                            bgcolor: `${theme.palette.primary.light} !important`,
                            color: 'white !important',
                          }}
                          key={index}
                          alt={user.profile?.first_name ?? ''}
                          src={
                            user.profile?.profile_image_url
                              ? user.profile?.profile_image_url
                              : '/broken-image.jpg'
                          }
                        />
                      ))}
                    </AvatarGroup>
                  </td>
                  <td className="px-4">
                    {projectTypes?.find(e => e.id === project.type)?.name}
                  </td>
                  <td className="px-4">
                    {currentDate.diff(moment(project.created_at), 'days')} days
                  </td>
                  <td>
                    {canPerformAction() ? (
                      <div className="flex justify-center">
                        <Popover className="relative flex items-center">
                          {({ open, close }) => (
                            <>
                              <Popover.Button>
                                <Cog8ToothIcon className="text-light-gray-1 h-6 w-6 focus:outline-none" />
                              </Popover.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1">
                                <Popover.Panel className="w-56 bg-white rounded-lg border border-light-gray-5 shadow-lg absolute top-3 right-3 z-10">
                                  <div className="py-2 px-3">
                                    <div
                                      className="block rounded-md transition duration-150 ease-in-out hover:bg-light-gray-5 py-2 px-3 cursor-pointer"
                                      onClick={() => {
                                        navigateEditProject(project.id);
                                        close();
                                      }}>
                                      <div className="flex justify-start gap-2 items-center">
                                        <PencilSquareIcon className="h-5 w-5 text-mid-gray-5" />
                                        <span className="text-dark-gray-2">
                                          Edit Project
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-full border-t border-light-gray-3" />
                                  <div className="py-2 px-3">
                                    <div
                                      className="block rounded-md transition duration-150 ease-in-out hover:bg-light-gray-5 py-2 px-3 cursor-pointer"
                                      onClick={() => {
                                        handleChangeStatus(
                                          project.status === 'ACTIVE'
                                            ? 'INACTIVE'
                                            : 'ACTIVE',
                                          project.id
                                        );
                                        close();
                                      }}>
                                      <div className="flex justify-start gap-2 items-center">
                                        <FolderPlusIcon className="h-5 w-5 text-mid-gray-5" />
                                        <span className="text-dark-gray-2">
                                          {project.status === 'ACTIVE' ? (
                                            <span>Archive Project</span>
                                          ) : (
                                            <span>Set Project as Active</span>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </div>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr className="border-t border-light-gray-3">
              <td colSpan={5} className="table-cell">
                <div className="w-full py-10 flex justify-center text-gray-400">
                  No Projects available
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <ChevronRightIcon
            className="h-6 w-6 text-dark-gray-2 opacity-50"
            aria-hidden="true"
          />
        }
        previousLabel={
          <ChevronLeftIcon
            className="h-6 w-6 text-dark-gray-2 opacity-50"
            aria-hidden="true"
          />
        }
        className="flex items-center gap-1 p-4 justify-end border-t"
        pageClassName="py-1 px-2 leading-6"
        activeClassName="bg-light-gray-3 rounded"
        onPageChange={({ selected }) => {
          setCurrentPage(selected);
        }}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        disabledClassName="opacity-20 cursor-not-allowed"
      />
    </div>
  );
};
