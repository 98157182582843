import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const ProjectValidator = Yup.object()
  .shape({
    cover_image_url: Yup.string().label('Cover Image').required(),
    status: Yup.string().label('Status').required(),
    type: Yup.number()
      .transform(value => (value === -1 ? null : value))
      .label('Project type')
      .nullable()
      .required(),
    name: Yup.string().trim().max(50).label('Project name').required(),
    cost: Yup.number().nullable().label('Project cost'),
    address: Yup.object().shape({
      country: Yup.string().label('Country').required(),
      block_no: Yup.string().label('Block/House No.').required(),
      street: Yup.string().label('Street').required(),
      postal_code: Yup.string()
        .label('Postal code')
        .required()
        .min(3, 'Postal code must be at least 3 characters')
        .max(10, 'Please enter a valid postal code')
        .matches(/^[0-9]+$/, 'Must be only digits'),
    }),
    start_date: Yup.date().nullable().label('Start Date'),
    handover_date: Yup.date()
      .label('Handover Date')
      .nullable()
      .min(
        Yup.ref('start_date'),
        'Handover date must be later than start date'
      ),
  })
  .required();
