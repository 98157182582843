import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from '@heroicons/react/24/solid';

import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Spinner from '_components/spinner';
import { ReactComponent as GripDots } from '_images/icons/dots.svg';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Json } from '_types/database.types';
import getErrorMessage from '_utils/getErrorMessage';

import useTemplateDetailStore from '../store';
import StageEditor from './StageEditor';

const TemplateEditor = () => {
  const snackBar = useSnackBarStore();

  const originalProjectType = useTemplateDetailStore(
    $ => $.originalProjectType
  );
  const projectType = useTemplateDetailStore($ => $.projectType);
  const setProjectType = useTemplateDetailStore($ => $.setProjectType);
  const openingStageId = useTemplateDetailStore($ => $.openingStageId);
  const setOpeningStageId = useTemplateDetailStore($ => $.setOpeningStageId);
  const reorderStages = useTemplateDetailStore($ => $.reorderStages);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const [mouseOverId, setMouseOverId] = useState<string | null>(null);
  const [draggingId, setDraggingId] = useState<string | null>(null);

  const stages = useMemo(() => {
    return projectType?.template ?? [];
  }, [projectType]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setDraggingId(null);
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      reorderStages(result.source.index, result.destination.index);
    },
    [reorderStages]
  );

  const onDragStart = useCallback((start: DragStart) => {
    setDraggingId(start.draggableId);
  }, []);

  const handleSave = useCallback(async () => {
    const isMissingData = !!projectType?.template.find(
      stage =>
        !stage.name.trim() ||
        stage.milestones.find(milestone => !milestone.name.trim())
    );
    if (isMissingData) {
      setErrorMessage(
        'Please input a valid title and/or milestone to save the template'
      );
      return;
    }

    setErrorMessage(null);

    if (!projectType?.template) {
      return;
    }

    // const templateData = projectType.template.map(stage => {
    //   return {
    //     name: stage.name,
    //     milestones: stage.milestones.map(milestone => {
    //       return {
    //         name: milestone.name,
    //       };
    //     }),
    //   };
    // });

    // @TODO: consider about 'sequence' & 'id'
    try {
      setIsBusy(true);
      const { error } = await supabase
        .from('project_type')
        .update({
          template: projectType.template as unknown as Json,
        })
        .eq('id', projectType?.id);
      if (error) {
        throw error;
      }
      await queryClient.invalidateQueries(['projectType']);
      snackBar.show('Template saved successfully', 'success');
    } catch (e) {
      const errorMessage = await getErrorMessage(e);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  }, [projectType, snackBar]);

  const canSave = useMemo(
    () => !isEqual(projectType, originalProjectType),
    [originalProjectType, projectType]
  );

  const handleCancel = useCallback(() => {
    setProjectType(originalProjectType);
  }, [originalProjectType, setProjectType]);

  if (!stages) {
    return null;
  }

  if (stages.length === 0) {
    return (
      <div className="text-mid-gray-1 p-5 text-center">
        No stages yet, click "Add Stage" Button to start adding stage
      </div>
    );
  }

  return (
    <div className="mb-20 relative">
      {isBusy ? (
        <div className="absolute inset-0 bg-white opacity-75 flex justify-center items-center">
          <Spinner className="w-8 h-8" />
        </div>
      ) : null}
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="stage-dropable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {stages?.map((stage, index) => (
                <Draggable key={stage.id} draggableId={stage.id} index={index}>
                  {provided => (
                    <div
                      className="mt-5"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{ ...provided.draggableProps.style }}>
                      <div {...provided.dragHandleProps} className="relative">
                        <div
                          className="flex bg-light-gray-5 p-2 rounded-md flex justify-between relative"
                          onMouseOver={() => setMouseOverId(stage.id)}
                          onMouseLeave={() => {
                            setMouseOverId(null);
                          }}>
                          {stage.id === mouseOverId ||
                          stage.id === draggingId ? (
                            <GripDots className="w-4 h-4 absolute top-2.5 -left-4" />
                          ) : null}
                          <div className="flex justify-start">
                            <button
                              onClick={() =>
                                setOpeningStageId(
                                  openingStageId === stage.id
                                    ? undefined
                                    : stage.id
                                )
                              }>
                              {openingStageId === stage.id ? (
                                <ArrowDownCircleIcon className="w-5 h-5 text-mid-gray-5" />
                              ) : (
                                <ArrowUpCircleIcon className="w-5 h-5 text-mid-gray-5" />
                              )}
                            </button>
                            <div className="text-mid-gray-1 font-semibold ml-2">
                              {stage.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <StageEditor
                        stage={stage}
                        isOpen={openingStageId === stage.id}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-between gap-2 border-t border-light-gray-3 mt-10 p-5 items-center">
        <div className="text-red-600">{errorMessage}</div>
        <div className="flex items-center gap-2">
          <Button disabled={!canSave} color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={!canSave} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemplateEditor;
