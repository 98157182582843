import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const CoverImageWrapper = styled(Avatar)(
  ({ theme }) => `
          position: absolute;
          margin-bottom: ${theme.spacing(7)};
          z-index:1;
          borderRadius:10;
          background: none;
          &:hover {
            background: ${theme.palette.error.light};
          }
        `
);

export default CoverImageWrapper;
