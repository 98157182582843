import { Milestone } from '_models/milestone/milestone';

export type Stage = {
  id: number;
  name: string;
  project_id: number;
  project_milestone: Milestone[];
  created_at?: string;
  status: StageStatus;
};

export enum StageStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
