import * as Yup from 'yup';

export const forgotPasswordValidator = Yup.object()
  .shape({
    email: Yup.string()
      .trim()
      .email('Please enter a valid email id')
      .label('Email')
      .required(),
  })
  .required();
