import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';
import { ProjectUpdate } from '_types/project';

import {
  ProjectUpdatePayload,
  ProjectUpdatePreloaded,
} from './useProjectUpdates';

export interface NotificationsState {
  notifications: ProjectUpdate[];

  fetchNotifications: () => Promise<void>;
  markNotificationsAsRead: () => Promise<void>;
}

export const fetchNotifications = async () => {
  const organization = useAuthStore.getState().selectedOrganization;
  if (!organization) {
    return [];
  }
  const currentOrganizationUser = organization.organization_user[0];

  const query = supabase
    .from('project_update')
    .select(
      '*, profile(*), project!inner(*, organization_id, project_user!inner(user_id))'
    )
    .eq('project.project_user.user_id', currentOrganizationUser.user_id)
    .neq('action_taker_id', currentOrganizationUser.user_id)
    .order('created_at', {
      ascending: false,
    });

  const {
    data: notificationAcknowledgement,
    error: getNotificationAcknowledgementError,
  } = await supabase
    .from('notification_acknowledgements')
    .select()
    .eq('user_id', currentOrganizationUser.user_id)
    .maybeSingle();
  if (getNotificationAcknowledgementError) {
    throw getNotificationAcknowledgementError;
  }

  if (notificationAcknowledgement) {
    query.gt('updated_at', notificationAcknowledgement.date_read);
  }

  const { data: projectUpdates, error } = await query;
  if (error) {
    throw error;
  }

  // type assert for payload
  let projectUpdatesWithPreloaded = projectUpdates.map(pu => ({
    ...pu,
    payload: pu.payload as ProjectUpdatePayload | null,
  }));

  // Fill targetUser to each item
  const userIds = projectUpdatesWithPreloaded
    .map(pu => pu.payload?.user_id)
    .filter(id => !!id);

  const { data: profiles, error: getProfilesError } = await supabase
    .from('profile')
    .select()
    .in('id', userIds);
  if (getProfilesError) {
    throw getProfilesError;
  }

  projectUpdatesWithPreloaded = projectUpdatesWithPreloaded.map(pu => ({
    ...pu,
    targetUser: profiles.find(
      p => p.id === (pu.payload as ProjectUpdatePayload)?.user_id
    ),
  }));

  return projectUpdatesWithPreloaded as unknown as ProjectUpdatePreloaded[];
};

const useNotifications = () =>
  useQuery(['notifications'], () => fetchNotifications());

export default useNotifications;
