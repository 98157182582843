import { Alert, AlertTitle,Fade } from '@mui/material';

import { useState } from 'react';

import { ErrorResponse } from '_models/error-response';

export const DisplayErrors = (props: { errors: ErrorResponse[] }) => {
  const [alertVisibility, setAlertVisibility] = useState(true);
  return (
    <Fade
      in={alertVisibility} //Write the needed condition here to make it appear
      timeout={{ enter: 500, exit: 500 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
      addEndListener={() => {
        setTimeout(() => {
          setAlertVisibility(false);
        }, 5000);
      }}>
      <Alert
        onClose={() => {
          setAlertVisibility(false);
        }}
        severity="error">
        {props.errors &&
          props.errors.length > 0 &&
          props.errors[0].property && (
            <AlertTitle>{props.errors[0].property}</AlertTitle>
        )}
        {props.errors &&
          props.errors.length > 0 &&
          props.errors[0].message &&
          props.errors[0].message}
      </Alert>
    </Fade>
  );
};
