import { ErrorResponse } from './error-response';
const UnknownError = 'Something went wrong.';
export class ResponseViewModel<T> {
  public errors: ErrorResponse[] = [];

  public data: T | null = null;

  public static getErrors<T>(viewModel: ResponseViewModel<T>): string[] {
    if (viewModel && viewModel.errors) {
      return viewModel.errors.map(error => error.message);
    }
    return [UnknownError];
  }

  public static hasErrors<T>(viewModel: ResponseViewModel<T>): boolean {
    return (
      !viewModel ||
      !viewModel.data ||
      (viewModel.errors && viewModel.errors.length > 0)
    );
  }

  public static getNullable<T>(
    request: ResponseViewModel<T>
  ): ResponseViewModel<T> {
    return request ? request : new ResponseViewModel();
  }

  public static fromError<T>(error: string): ResponseViewModel<T> {
    return {
      data: null,
      errors: [{ message: error, property: '' }],
    };
  }

  public static fromModel<T>(data: T): ResponseViewModel<T> {
    return { data, errors: [] };
  }
}
