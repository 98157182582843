import { ProjectUpdatePreloaded } from '_hooks/useProjectUpdates';

const replaceString = (projectUpdate: ProjectUpdatePreloaded) => {
  if (!projectUpdate || !projectUpdate.message) {
    return '';
  }

  let myString = projectUpdate.message;
  const replaceArray = [
    'user',
    'action-taker',
    'project name',
    'milestone name',
    'new checklist item title',
    'checklist item title',
  ];
  const replaceWith = [
    `${projectUpdate?.targetUser?.first_name} ${projectUpdate?.targetUser?.last_name}`,
    `${projectUpdate?.profile?.first_name} ${projectUpdate?.profile?.last_name}`,
    `${projectUpdate?.project?.name}`,
    `${projectUpdate?.payload?.milestone}`,
    `${projectUpdate?.payload?.checklist_item_title}`,
    `${projectUpdate?.payload?.checklist_item_title}`,
  ];

  for (let i = 0; i < replaceArray.length; i++) {
    myString = myString.replace(
      new RegExp('{' + replaceArray[i] + '}', 'gi'),
      replaceWith[i]
    );
  }
  return myString;
};

export default replaceString;
