import { PencilSquareIcon } from '@heroicons/react/24/solid';

import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '_components/button';
import useCountryList from '_hooks/useCountryList';
import { ProjectDetail } from '_hooks/useProjectBasicInfo';
import useProjectTypes from '_hooks/useProjectTypes';
import useAuthStore from '_stores/useAuthStore';

export const ProjectDetailPage = (props: {
  projectId: number;
  project: ProjectDetail;
}) => {
  const { project } = props;
  const navigate = useNavigate();
  const { id } = useParams();

  const canPerformAction = useAuthStore($ => $.canPerformAction);
  const { data: projectTypes } = useProjectTypes();
  const { data: countryList } = useCountryList();

  const handleEdit = () => {
    navigate(`/projects/${id}/edit`);
  };

  const address = project.address;

  return (
    <div className="border border-light-gray-3 rounded-xl overflow-hidden">
      <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
        <div className="font-semibold">Details</div>
        <Button
          color="secondary"
          disabled={!canPerformAction()}
          onClick={handleEdit}>
          <PencilSquareIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Edit
        </Button>
      </div>
      {project && (
        <div className="p-5">
          <div className="text-xs text-mid-gray-3 uppercase font-semibold">
            Key Info
          </div>
          <div className="mt-4 grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-mid-gray-1 font-semibold leading-6">
              Project Type
            </div>
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-dark-gray-2 leading-6">
              {projectTypes?.find(e => e.id === project.type)?.name}
            </div>
          </div>

          <div className="mt-2 grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-mid-gray-1 font-semibold leading-6">
              Project Name
            </div>
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-dark-gray-2 leading-6">
              {project.name}
            </div>
          </div>

          <div className="mt-2 grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-mid-gray-1 font-semibold leading-6">
              Project Cost
            </div>
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-dark-gray-2 leading-6">
              {project.cost ? project.cost?.toLocaleString() : '-'}
            </div>
          </div>

          <div className="mt-2 grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-mid-gray-1 font-semibold leading-6">
              Start Date
            </div>
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-dark-gray-2 leading-6">
              {project.start_date
                ? moment(project.start_date).format('MMMM Do YYYY')
                : '-'}
            </div>
          </div>

          <div className="mt-2 grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-mid-gray-1 font-semibold leading-6">
              Est. Handover date
            </div>
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-dark-gray-2 leading-6">
              {project.handover_date
                ? moment(project.handover_date).format('MMMM Do YYYY')
                : '-'}
            </div>
          </div>

          <div className="mt-4 text-xs text-mid-gray-3 uppercase font-semibold">
            Address
          </div>

          <div className="mt-4 grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-mid-gray-1 font-semibold leading-6">
              Country
            </div>
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-dark-gray-2 leading-6">
              {countryList?.find(e => e.name === address.country)?.name}
            </div>
          </div>

          <div className="mt-2 grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-mid-gray-1 font-semibold leading-6">
              Address
            </div>
            <div className="col-span-12 sm:col-span-4 md:col-span-3 text-dark-gray-2 leading-6">
              <span>{address.street}, </span>
              <span>
                {address.building_name}
                {address.building_name && ','}{' '}
              </span>
              <span>
                {address.unit}
                {address.unit && ','}{' '}
              </span>
              <span>{address.block_no}, </span>
              <span>
                {countryList?.find(e => e.name === address.country)?.name}{' '}
              </span>
              <span>{address.postal_code}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
