// export type Profile = {
//   id: string;
//   first_name: string;
//   last_name: string;
//   profile_image_url?: string;
//   gender: string;
//   date_of_birth?: string;
//   email: string;
//   mobile_number: string;
//   role: string;
//   updated_at?: string;
//   first_time_login: boolean;
// };

import { Profile } from '_types/profile';

export const getProfileOrDefault = (profile?: Profile): Profile =>
  profile ?? {
    id: '',
    first_name: '',
    last_name: '',
    profile_image_url: '',
    date_of_birth: '',
    email: '',
    mobile_number: '',
    created_at: '',
    updated_at: '',
    gender: 'MALE',
    role: 'DESIGNER',
    first_time_login: true,
    phone_change: false,
    raw_app_meta_data: null,
    confirmed_at: null,
    invited_at: null,
  };
