export const ProjectUpdateMessages = {
  PROJECT_CREATED: '{action-taker} created project {project name}',
  PROJECT_UPDATED: '{action-taker} updated project details',
  PROJECT_USER_INVITED: '{action-taker} invited {user} to the project',
  PROJECT_USER_ADDED: '{user} joined the project',
  PROJECT_USER_REMOVED: '{action-taker} removed {user} from the project',
  PROJECT_ARCHIVED: '{action-taker} archived the project',
  PROJECT_ACTIVE: '{action-taker} set the project to active',

  MILESTONE_NOTE_UPDATED: '{action-taker} updated a note in {milestone name}',
  MILESTONE_FILE_UPLOADED:
    '{action-taker} added a new file in {milestone name}',
  MILESTONE_IMAGE_UPLOADED:
    '{action-taker} added a new photo in {milestone name}',
  COMMENT: '{action-taker} left a comment',
  MILESTONE_FILE_RENAMED:
    '{action-taker} renamed {original-file-name} to {new-file-name} in {milestone name}',
  MILESTONE_FILE_DELETED: '{action-taker} deleted a file in {milestone name}',
  MILESTONE_IMAGE_DELETED:
    '{action-taker} deleted an image in {milestone name}',
  CHECKLIST_ITEM_ADDED:
    '{action-taker} added {checklist item title} in {milestone name}',
  CHECKLIST_ITEM_EDITED:
    '{action-taker} updated details for {new checklist item title} in {milestone name}',
  CHECKLIST_ITEM_DELETED:
    '{action-taker} deleted {checklist item title} in {milestone name}',
  CHECKLIST_ITEM_CHECKED:
    '{action-taker} completed {checklist item title} in {milestone name}',
  CHECKLIST_ITEM_UNCHECKED:
    '{action-taker} set incomplete {checklist item title} in {milestone name}',
  MILESTONE_CHECKED: '{action-taker} completed {milestone name}',
  MILESTONE_UNCHECKED: '{action-taker} set incomplete {milestone name}',
  STAGE_CHECKED: '{action-taker} completed {stage name}',
};
