import { Checklist } from '_models/milestone/checklist';
import { ChecklistStatus } from '_models/milestone/checklist-status-enum';
import { Milestone } from '_models/milestone/milestone';
import { MilestoneImageOrFile } from '_models/milestone/milestone-upload';
import { Stage } from '_models/milestone/stage';
import { ResponseViewModel } from '_models/response-viewmodel';

import { profileService } from './profile-service';
import { supabaseService } from './supabase-service';

const STORAGE_BUCKET_KEY_AVATARS = 'project';
export const MilestoneService = {
  async getStagesWithMilestone(
    project_id: number
  ): Promise<ResponseViewModel<Stage[]>> {
    const order = [
      { column: 'status', asc: true },
      { column: 'sequence', asc: true },
      { column: 'sequence', asc: true, foreignTable: 'project_milestone' },
    ];
    return supabaseService.getByMultipleOrderBy<Stage>(
      'project_stage',
      'project_id',
      project_id,
      order,
      '*, project_milestone(*, project_checklist_item(*), project_milestone_file(*), project_milestone_image(*))'
    );
  },

  async getMilestoneById(
    milestone_id: number
  ): Promise<ResponseViewModel<Milestone[]>> {
    const order = [
      { column: 'hide_status', asc: true },
      { column: 'status', asc: true },
      { column: 'sequence', asc: true },
    ];
    return supabaseService.getByMultipleOrderBy<Milestone>(
      'project_milestone',
      'id',
      milestone_id,
      order,
      '*,project_checklist_item!left(*),project_milestone_image!left(*),project_milestone_file!left(*)'
    );
  },

  async getMilestoneByStageId(
    stageId: number
  ): Promise<ResponseViewModel<Stage[]>> {
    const order = [
      { column: 'hide_status', asc: true },
      { column: 'status', asc: true },
      { column: 'sequence', asc: true },
    ];
    return supabaseService.getByMultipleOrderBy(
      'project_milestone',
      'project_stage_id',
      stageId,
      order
    );
  },

  async getChecklistItems(
    project_milestone_id: number
  ): Promise<ResponseViewModel<Checklist[]>> {
    const order = [
      { column: 'status', asc: false },
      { column: 'due_date', asc: false },
      { column: 'sequence', asc: true },
    ];
    return supabaseService.getByMultipleOrderBy(
      'project_checklist_item',
      'project_milestone_id',
      project_milestone_id,
      order,
      '*,profile!left(*)'
    );
  },

  async updateMilestoneNotes(
    id: number,
    notes: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patch('project_milestone', id, { notes: notes });
  },

  async uploadImagesOrFiles(
    filepath: string,
    file: File
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.uploadFile(
      STORAGE_BUCKET_KEY_AVATARS,
      filepath,
      file
    );
  },

  async updateImagesOrFiles(
    table: string,
    fieldsToUpdate: MilestoneImageOrFile
  ): Promise<ResponseViewModel<MilestoneImageOrFile>> {
    return supabaseService.insert(table, fieldsToUpdate);
  },

  async changeMilestoneStatus(
    id: number,
    status: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patch('project_milestone', id, {
      status: status,
    });
  },

  async updateHideStatus(
    table: string,
    id: number,
    status: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patch(table, id, { hide_status: status });
  },

  async updateComments(
    comments: any,
    id: number,
    isImage: boolean
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patchConditional(
      isImage ? 'project_milestone_image' : 'project_milestone_file',
      { column: 'id', value: id },
      { comments }
    );
  },

  async getMilestoneImageOrFileById(
    id: number,
    isImage: boolean
  ): Promise<ResponseViewModel<MilestoneImageOrFile>> {
    const selectedTable = isImage
      ? 'project_milestone_image'
      : 'project_milestone_file';
    const { data: milestoneData, errors } =
      await supabaseService.getSingle<MilestoneImageOrFile>(
        selectedTable,
        'id',
        id
      );
    if (errors && errors.length > 0) {
      return { data: milestoneData, errors };
    }
    const userIds = (milestoneData?.comments || []).map(
      comment => comment.user_id
    );
    if (milestoneData?.uploaded_by) {
      userIds.push(milestoneData?.uploaded_by);
    }
    const { data: users, errors: userErrors } =
      await profileService.getUsersByIds(userIds);
    if (userErrors && userErrors.length > 0) {
      return { data: milestoneData, errors: userErrors };
    }
    const commentsWithUsers = (milestoneData?.comments || []).map(c => ({
      ...c,
      user: users?.find(u => u.id === c.user_id),
    }));
    if (milestoneData) {
      milestoneData.uploaded_by_user = users?.find(
        u => u.id === milestoneData?.uploaded_by
      );
      milestoneData.total_comments = milestoneData.comments?.length;
    }
    return { data: { ...milestoneData!, comments: commentsWithUsers }, errors };
  },

  async getFileById(
    id: number
  ): Promise<ResponseViewModel<MilestoneImageOrFile>> {
    return supabaseService.getSingle<MilestoneImageOrFile>(
      'project_milestone_file',
      'id',
      id
    );
  },

  async renameFile(
    id: number,
    rename: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patch('project_milestone_file', id, {
      name: rename,
    });
  },

  async addChecklistItem(
    table: string,
    fieldsToUpdate: Checklist
  ): Promise<ResponseViewModel<Checklist>> {
    return supabaseService.insert(table, fieldsToUpdate);
  },

  async updateChecklistItem(
    id: number,
    value: string,
    isName: boolean
  ): Promise<ResponseViewModel<boolean>> {
    const fieldsToUpdate = isName ? { name: value } : { due_date: value };
    return supabaseService.patch('project_checklist_item', id, fieldsToUpdate);
  },

  async deleteChecklistItem(id: number) {
    return supabaseService.deleteByMatch('project_checklist_item', { id: id });
  },

  async changeChecklistItemStatus(
    id: number,
    status: string,
    userId: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patch('project_checklist_item', id, {
      status,
      completed_by: status === ChecklistStatus.Complete ? userId : null,
      completed_at:
        status === ChecklistStatus.Complete ? new Date().toISOString() : null,
    });
  },

  async deleteMilestoneImageOrFile(id: number, isImage: boolean) {
    if (isImage) {
      return supabaseService.deleteByMatch('project_milestone_image', {
        id: id,
      });
    } else {
      return supabaseService.deleteByMatch('project_milestone_file', {
        id: id,
      });
    }
  },

  async getChecklistItemById(
    id: number
  ): Promise<ResponseViewModel<Checklist>> {
    return supabaseService.getSingle('project_checklist_item', 'id', id);
  },

  async updateStatus(
    table: string,
    id: number,
    status: string
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patch(table, id, { status: status });
  },
};
