import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import { Profile } from '_types/profile';

import { ProjectDetail } from './useProjectBasicInfo';

type DetailClientProfile = Profile & {
  projects: ProjectDetail[];
};

export const fetchClientProfile = async (userId?: string) => {
  if (!userId) {
    throw new Error('Missing user id');
  }

  const { data, error } = await supabase
    .from('profile')
    .select('*, projects:project(*)')
    .eq('id', userId)
    .returns<DetailClientProfile[]>()
    .single();

  if (error) {
    throw error;
  }

  return data;
};

const useClientProfile = (userId?: string) =>
  useQuery(['clientProfile', userId], () => fetchClientProfile(userId), {
    enabled: !!userId,
  });

export default useClientProfile;
