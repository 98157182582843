import * as Yup from 'yup';

export const RenameFileValidator = Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .max(50)
      .label('Filename')
      .matches(
        /^([a-zA-Z0-9\s_\\.\-():])+(.doc|.docx|.pdf|.jpg|.txt|.jpeg|.png|.csv|.xslx)$/,
        'Supported file types .doc|.docx|.pdf|.jpg|.txt|.jpeg|.png|.csv|.xslx'
      )
      .required(),
  })
  .required();
