import {
  ArchiveBoxIcon,
  PlusSmallIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Spinner from '_components/spinner';
import useProjectType from '_hooks/useProjectType';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

import ConfirmRemoveTemplateModal from './ConfirmRemoveTemplateModal';
import useTemplateDetailStore from './store';
import TemplateBasicInfo from './TemplateBasicInfo';
import TemplateEditor from './TemplateEditor';
import TemplatePreview from './TemplatePreview';

const tabs = [
  {
    key: 'details',
    title: 'Details',
  },
  {
    key: 'editor',
    title: 'Editor',
  },
  {
    key: 'preview',
    title: 'Preview',
  },
];

export const TemplateDetail = () => {
  const params = useParams<{ projectTypeId: string }>();
  const snackBar = useSnackBarStore();
  const [isBusy, setIsBusy] = useState(false);
  const [selectedTabKey, setSelectedTabKey] = useState('details');

  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  const {
    data: projectType,
    isInitialLoading,
    isLoading,
  } = useProjectType(params.projectTypeId);

  const setProjectType = useTemplateDetailStore($ => $.setProjectType);
  const addStage = useTemplateDetailStore($ => $.addStage);
  const setIsConfirmRemoveModalVisible = useTemplateDetailStore(
    $ => $.setIsConfirmRemoveModalVisible
  );

  // update breadcrumbs
  useEffect(() => {
    setItems([
      { label: 'Manage' },
      {
        label: 'Templates',
        link: '/manage/templates',
      },
    ]);
    if (!projectType) {
      return;
    }
    addItem({
      label: projectType.name ?? '',
      highlight: true,
    });
  }, [addItem, params, projectType, setItems]);

  useEffect(() => {
    if (projectType) {
      setProjectType(projectType);
    }
  }, [projectType, setProjectType]);

  const unarchiveProjectType = async () => {
    if (!projectType) return;
    try {
      setIsBusy(true);
      const { error } = await supabase
        .from('project_type')
        .update({
          status: 'ACTIVE',
        })
        .eq('id', projectType.id);
      if (error) {
        throw error;
      }
      await queryClient.invalidateQueries(['projectType']);
      snackBar.show('Project type unarchived successfully', 'success');
    } catch (err) {
      const errorMessage = await getErrorMessage(err);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  };

  const archiveProjectType = async () => {
    if (!projectType) return;
    try {
      setIsBusy(true);
      const { error } = await supabase
        .from('project_type')
        .update({
          status: 'INACTIVE',
        })
        .eq('id', projectType.id);
      if (error) {
        throw error;
      }
      await queryClient.invalidateQueries(['projectType']);
      snackBar.show('Project type archived successfully', 'success');
    } catch (err) {
      const errorMessage = await getErrorMessage(err);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  };

  const handleClickDeleteButton = async () => {
    setIsConfirmRemoveModalVisible(true);
  };

  if (isLoading || isInitialLoading) {
    return (
      <div className="flex justify-center items-center border-b pb-8 mt-12 h-[400px] w-full">
        <Spinner className="w-10 h-10" />
      </div>
    );
  }

  return (
    <div className="relative">
      {isBusy ? (
        <div className="absolute inset-0 bg-white opacity-75 flex justify-center items-center">
          <Spinner className="w-8 h-8" />
        </div>
      ) : null}
      <div className="flex justify-between items-center border-b pb-8 mt-12">
        <div className="font-semibold text-2xl flex items-center">
          <span>{projectType?.name}</span>
        </div>
        <div className="h-[68px]">
          {selectedTabKey === 'details' ? (
            <>
              {projectType?.status === 'INACTIVE' ? (
                <Button
                  onClick={unarchiveProjectType}
                  color="secondary"
                  className="font-semibold border-mid-gray-1 text-mid-gray-1">
                  <ArchiveBoxIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Unarchive
                </Button>
              ) : (
                <Button onClick={archiveProjectType} color="gray">
                  <ArchiveBoxIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Archive
                </Button>
              )}
              <Button
                type="button"
                onClick={handleClickDeleteButton}
                className="ml-3.5 inline-flex items-center rounded-md border border-transparent px-4 py-1.5 font-semibold text-white bg-red-400 hover:bg-red-300 shadow-sm focus:outline-none ">
                <TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Delete
              </Button>
            </>
          ) : null}
          {selectedTabKey === 'editor' ? (
            <Button
              type="button"
              onClick={() => {
                addStage();
              }}
              className="ml-3.5 inline-flex items-center rounded-md border border-transparent px-4 py-1.5 font-semibold text-white shadow-sm bg-primary hover:opacity-80 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:opacity-50">
              <PlusSmallIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Add Stage
            </Button>
          ) : null}
        </div>
      </div>
      <div className="flex flex-start mt-5">
        <div className="w-[150px]">
          {tabs.map(tab => (
            <div
              key={tab.key}
              className={classNames(
                'border-l-2 pl-4 text-dark-gray-2 h-9 flex items-center cursor-pointer',
                selectedTabKey === tab.key
                  ? 'border-primary text-primary'
                  : 'hover:text-primary-50'
              )}
              onClick={() => {
                setSelectedTabKey(tab.key);
              }}>
              <div className="flex flex-start items-center">
                <span>{tab.title}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="ml-8 flex-1">
          {selectedTabKey === 'details' ? <TemplateBasicInfo /> : null}
          {selectedTabKey === 'editor' ? <TemplateEditor /> : null}
          {selectedTabKey === 'preview' ? <TemplatePreview /> : null}
        </div>
      </div>
      <ConfirmRemoveTemplateModal />
    </div>
  );
};

export default TemplateDetail;
