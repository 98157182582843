import './layout.css';

import { ArchiveBoxIcon } from '@heroicons/react/24/solid';
import { Avatar } from '@mui/material';

import 'stream-chat-react/dist/css/v2/index.css';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ChannelFilters, ChannelSort } from 'stream-chat';
import {
  Channel,
  ChannelHeader,
  ChannelList,
  MessageList,
  useChatContext,
  Window,
} from 'stream-chat-react';
import { ChannelSearchFunctionParams } from 'stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch';

import useAuthStore from '_stores/useAuthStore';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';

import { CustomMessageInput } from './chat-input';

export const ChatList = observer(() => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const projectId = query.get('project');
  const [showFrozen, setShowFrozen] = useState(false);

  const user = useAuthStore($ => $.user);
  const profile = useAuthStore($ => $.profile);
  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  const userId = useMemo(() => user?.id ?? '', [user]);

  const filters = { members: { $in: [userId] }, frozen: showFrozen };
  const sort = { last_message_at: -1 } as ChannelSort;
  const options = { limit: 50 };
  const { client: chatClient } = useChatContext();

  // update breadcrumbs
  useEffect(() => {
    setItems([
      { label: 'Manage' },
      {
        label: 'Chats',
      },
    ]);
  }, [addItem, setItems]);

  const customSearchFunction = async (
    props: ChannelSearchFunctionParams,
    event: { target: { value: string } }
  ) => {
    const { setResults, setSearching, setQuery } = props;

    const searchFilters: ChannelFilters =
      event.target.value !== ''
        ? {
            name: { $autocomplete: event.target.value },
            members: { $in: [userId] },
            frozen: showFrozen,
          }
        : {
            members: { $in: [userId] },
            frozen: showFrozen,
          };

    setSearching(true);
    setQuery(event.target.value);
    const channels = await chatClient.queryChannels(searchFilters);
    setResults(channels);
    setSearching(false);
  };

  if (!chatClient) {
    return <>Loading...</>;
  }

  const toggleArchivedFilter = () => {
    setShowFrozen(!showFrozen);
  };

  return (
    <div className="flex h-full overflow-hidden border rounded-2xl border-light-gray-1  h-[700px] mb-20 mt-12">
      <div className="w-[400px]">
        <div className="bg-light-gray-5 w-full flex justify-between items-center p-5 border-b border-light-gray-3">
          {profile ? (
            <div className="flex justify-start gap-x-4 items-center">
              {profile.profile_image_url ? (
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt={`${profile.first_name ?? ''} ${profile.last_name ?? ''}`}
                  src={profile?.profile_image_url}
                  id="profile-menu-lnk"
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              <div>
                <div>
                  {`${profile.first_name ?? ''} ${profile.last_name ?? ''}`}
                </div>
                <div className="text-mid-gray-3">{profile.email}</div>
              </div>
            </div>
          ) : null}
          <ArchiveBoxIcon
            onClick={toggleArchivedFilter}
            className={classNames(
              'w-6 h-6 cursor-pointer',
              showFrozen ? 'text-red-500' : 'text-mid-gray-5'
            )}
          />
        </div>
        <div className="h-full overflow-auto mb-10">
          <ChannelList
            filters={filters}
            sort={sort}
            options={options}
            showChannelSearch={true}
            customActiveChannel={!projectId ? undefined : `${projectId}_000`}
            additionalChannelSearchProps={{
              searchFunction: customSearchFunction,
            }}
          />
        </div>
      </div>
      <div className="p-5 flex-1">
        <Channel>
          <Window>
            <ChannelHeader />
            <MessageList />
            <CustomMessageInput />
          </Window>
        </Channel>
      </div>
    </div>
  );
});
