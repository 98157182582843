import { Field, Formik, FormikHelpers } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import * as yup from 'yup';

import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import CoverImage from '_components/cover-image';
import Label from '_components/label';
import Spinner from '_components/spinner';
import useAuthStore from '_stores/useAuthStore';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

interface FormValues {
  coverImageUrl: string;
  name: string;
  address: string;
  postalCode: string;
}

export const validationSchema = yup
  .object()
  .shape({
    coverImageUrl: yup.string(),
    name: yup.string().trim().max(50).label('Name').required(),
    address: yup.string(),
    postalCode: yup
      .string()
      .label('Postal code')
      .min(3, 'Postal code must be at least 3 characters')
      .max(10, 'Please enter a valid postal code')
      .matches(/^[0-9]+$/, 'Must be only digits'),
  })
  .required();

export const OrganizationInfoEdit = () => {
  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);
  const selectedOrganization = useAuthStore($ => $.selectedOrganization);
  const refetchOrganizations = useAuthStore($ => $.refetchOrganizations);

  const snackBar = useSnackBarStore();

  const [isSaving, setIsSaving] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File>();

  // update breadcrumbs
  useEffect(() => {
    setItems([
      { label: 'Manage', link: '/' },
      { label: 'Organisation Details', highlight: true },
    ]);
  }, [addItem, setItems]);

  const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    if (!selectedOrganization) {
      return;
    }

    try {
      setIsSaving(true);
      let coverImageUrl;
      if (selectedImage) {
        const timestamp = new Date().getTime();
        const filePath = `${timestamp}_${selectedImage.name}`;

        await supabase.storage.from('avatars').upload(filePath, selectedImage, {
          cacheControl: '3600',
          upsert: true,
        });

        const imageUrl = supabase.storage.from('avatars').getPublicUrl(filePath)
          .data.publicUrl;

        coverImageUrl = imageUrl;
      }

      await supabase
        .from('organization')
        .update({
          name: values.name,
          cover_image_url: coverImageUrl ?? values.coverImageUrl,
          address: values.address,
          postal_code: values.postalCode,
        })
        .eq('id', selectedOrganization.id);

      await refetchOrganizations();

      snackBar.show('Organisation details updated', 'success');

      helpers.resetForm();
    } catch (e) {
      const errorMessage = await getErrorMessage(e);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="shadow-outer rounded-lg overflow-hidden mt-12">
      <Formik<FormValues>
        enableReinitialize={true}
        initialValues={{
          coverImageUrl: selectedOrganization?.cover_image_url ?? '',
          name: selectedOrganization?.name ?? '',
          address: selectedOrganization?.address ?? '',
          postalCode: selectedOrganization?.postal_code ?? '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue,
          handleSubmit,
          dirty,
          setFieldTouched,
        }) => {
          return (
            <>
              <div className="p-5 border-b text-lg font-semibold">
                Organisation Details
              </div>
              <form onSubmit={handleSubmit}>
                <div className="bg-light-gray-5 p-5">
                  <div className="relative flex items-center">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      Organisation Details
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>

                  <div className="flex justify-start items-center mt-5">
                    <div className="w-32">
                      <Label>Org Photo</Label>
                    </div>
                    <div className="ml-8">
                      <CoverImage
                        handleRemove={() => {
                          setSelectedImage(undefined);
                          setFieldValue('coverImageUrl', '');
                        }}
                        alt={selectedOrganization?.name ?? ''}
                        src={
                          selectedImage
                            ? window.URL.createObjectURL(selectedImage)
                            : values.coverImageUrl ?? ''
                        }
                        handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
                          if (!e.target.files) {
                            return;
                          }
                          handleUploadImage(e);
                          setFieldValue(
                            'coverImageUrl',
                            e.target.files[0].name
                          );
                        }}
                        name="coverImageUrl"
                        handleBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="flex justify-start items-center mt-5">
                    <Label className="w-32">Name*</Label>
                    <Field
                      name="name"
                      type="text"
                      autoComplete="name"
                      placeholder="eg: Sunnyside Org"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSaving}
                      className="block w-56 ml-8 py-2 px-3 rounded-lg border border-gray-200 focus:outline-none sm:text-sm"
                    />
                    {errors.name && touched.name && (
                      <span className="ml-6 text-red-500">{errors.name}</span>
                    )}
                  </div>
                  <div className="flex justify-start items-center mt-5">
                    <Label className="w-32">Address</Label>
                    <Field
                      name="address"
                      type="text"
                      autoComplete="address"
                      placeholder="eg: Orchard Road"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSaving}
                      className="block w-96 ml-8 py-2 px-3 rounded-lg border border-gray-200 focus:outline-none sm:text-sm"
                    />
                    {errors.address && touched.address && (
                      <span className="ml-6 text-red-500">
                        {errors.address}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-start items-center mt-5">
                    <Label className="w-32">Postal Code</Label>
                    <Field
                      name="postalCode"
                      type="text"
                      autoComplete="postalCode"
                      placeholder="e.g. 412345"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSaving}
                      className="block w-64 ml-8 py-2 px-3 rounded-lg border border-gray-200 focus:outline-none sm:text-sm"
                    />
                    {errors.postalCode && touched.postalCode && (
                      <span className="ml-6 text-red-500">
                        {errors.postalCode}
                      </span>
                    )}
                  </div>
                </div>
                <div className="border-t flex justify-end p-5">
                  <Button type="submit" disabled={isSaving || !dirty}>
                    {isSaving ? (
                      <div className="inline-flex justify-start items-center gap-x-2">
                        <Spinner className="h-4 w-4" />
                        Saving..
                      </div>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default OrganizationInfoEdit;
