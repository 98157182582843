import {
  ArrowLeftCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import { Divider, Grid, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ManageFiles } from '_components/manage-files';
import { MilestoneCarousel } from '_components/milestone-carousel';
import { MilestoneChecklist } from '_components/milestone-checklist';
import { MilestoneComments } from '_components/milestone-comments';
import { ProjectCard } from '_components/project-card';
import Spinner from '_components/spinner';
import useProject from '_hooks/useProjectBasicInfo';
import { Checklist } from '_models/milestone/checklist';
import { ChecklistStatus } from '_models/milestone/checklist-status-enum';
import { getMilestoneOrDefault } from '_models/milestone/milestone';
import {
  getImageFileOrDefault,
  MilestoneImageOrFile,
} from '_models/milestone/milestone-upload';
import { MilestoneStore } from '_store/milestone-store';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';

import { ProjectNavigator } from '../../shared/components/project-navigator';
import { MilestoneFileUpload } from './milestone-file-upload';
import { RenameFile } from './rename-file';

export const ProjectMilestone = observer(() => {
  const snackBar = useSnackBarStore();
  const profile = useAuthStore($ => $.profile);

  const {
    projectMilestone,
    uploadMilestoneImages,
    updateMilestoneNotes,
    saveFile,
    milestoneFileName,
    saveFileName,
    milestoneFile,
    uploadMilestoneFile,
    // getMilestones,
    getMilestoneByStageId,
    milestoneIds,
    deleteChecklist,
    updateChecklist,
    errors: milestoneErrors,
    addChecklistItem,
    changeChecklistItemStatus,
    getCommentsByImageOrFile,
    getMilestoneById,
    saveImageOrFile,
    deleteMilestoneImageOrFile,
    getChecklistItems,
    checklistItems,
    stageMilestones,
    renameFile,
    milestoneFileData,
    getMilestoneFileById,
  } = MilestoneStore;

  const { id, milestone_id: milestoneId } = useParams();
  const [note, setNote] = useState('');
  const [isNoteDisabled, setIsNoteDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [checklistItem, setAddChecklistItem] = useState('');
  const [inputId, setInputId] = useState<number | undefined>(0);
  const [openComments, setOpenComments] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(getImageFileOrDefault());
  const [openEditName, setOpenEditName] = useState(false);
  const [checklistError, setChecklistError] = useState(false);
  const [fileUploadErrors, setFileUploadErrors] = useState({
    name: false,
    milestone: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingComments, setIsLoadingComments] = useState(true);

  const notesField = useRef<HTMLInputElement>();
  const boxRef = useRef<HTMLDivElement | null>(null);

  const projectId = useMemo<number | null>(() => {
    const idAsNumber = Number(id);
    return !isNaN(idAsNumber) ? idAsNumber : null;
  }, [id]);

  const { data: project, isLoading: isLoadingProjectDetail } =
    useProject(projectId);
  const canPerformAction = useAuthStore($ => $.canPerformAction);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      await getMilestoneById(+milestoneId!);
      await getChecklistItems(+milestoneId!);
      await getMilestoneByStageId(+query.get('stage_id')!);
      setIsLoading(false);
    })();
  }, [
    milestoneId,
    id,
    getMilestoneByStageId,
    search,
    query,
    getMilestoneById,
    getChecklistItems,
  ]);

  useEffect(() => {
    if (!isNoteDisabled) {
      notesField.current?.focus();
    }
  }, [isNoteDisabled]);

  useEffect(() => {
    if (inputId && boxRef.current) {
      const toFind = `#chk_${inputId}`;
      (boxRef.current?.querySelector(toFind) as HTMLInputElement)?.focus();
    }
  }, [inputId]);

  useEffect(() => {
    if (!milestoneErrors || milestoneErrors.length === 0) {
      return;
    }
    snackBar.show(milestoneErrors.map(p => p.message).join(', '), 'error');
  }, [milestoneErrors, snackBar]);

  const navigate = useNavigate();

  const handleUploadImage = async (e: any) => {
    if (!id || !milestoneId || !profile) return;
    const file = e.target.files[0];
    await uploadMilestoneImages(+id, +milestoneId, file, profile.id);
  };

  const handleSaveNote = async (milestoneNoteId: number) => {
    await updateMilestoneNotes(milestoneNoteId, note, +id!);
    setIsNoteDisabled(true);
  };

  const onNoteEditClick = () => {
    if (!canPerformAction()) {
      return;
    }
    setIsNoteDisabled(false);
  };

  const onChecklistEditClick = (ChecklistId: number | undefined) => {
    checklistItems.forEach(checklist => {
      if (checklist.id === ChecklistId) {
        setInputId(ChecklistId);
      }
    });
  };

  const handleTextInput = (text: string, checklistId: number | undefined) => {
    checklistItems.forEach(checklist => {
      if (checklist.id === checklistId) {
        checklist.name = text;
      }
    });
  };

  const handleUploadFile = async (e: any) => {
    const file = e.target.files[0];
    saveFile(file);
    setOpen(true);
  };

  const changeFileName = (e: any) => {
    saveFileName(e.target.value);
  };

  const handleSaveFile = async () => {
    if (!projectMilestone?.id || !id || !profile) return;
    milestoneIds?.push(Number(projectMilestone.id));
    if (!milestoneFileName) {
      setFileUploadErrors({ ...fileUploadErrors, name: true });
      return;
    }
    if (!milestoneIds || milestoneIds?.length === 0) {
      setFileUploadErrors({ ...fileUploadErrors, milestone: true });
      return;
    }

    await uploadMilestoneFile(
      +id,
      milestoneIds,
      milestoneFileName,
      milestoneFile,
      profile.id
    );
    setFileUploadErrors({ name: false, milestone: false });
    await setOpen(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleBackArrow = () => {
    navigate({
      pathname: `/projects/${+id!}`,
      search: '?selectedTab=milestone',
    });
  };

  const handleAddChecklist = async () => {
    if (checklistItem.length > 0) {
      await addChecklistItem(+id!, +milestoneId!, checklistItem);
      setAddChecklistItem('');
      snackBar.show('Checklist item added successfully', 'success');
      await getChecklistItems(+milestoneId!);
    }
  };

  const handleSaveChecklist = async (
    checklistId: number,
    column: string,
    item: { name?: string; due_date?: string }
  ) => {
    if (column === 'name' && !item.name) {
      setChecklistError(true);
      return;
    }
    await updateChecklist(+id!, checklistId, column, item);
    setInputId(0);
    setChecklistError(false);
    snackBar.show('Checklist item updated successfully', 'success');
    await getChecklistItems(+milestoneId!);
  };

  const handleEditNameClose = () => {
    saveImageOrFile(true);
    setOpenEditName(false);
    getMilestoneById(+milestoneId!);
  };

  const onEditNameSave = async (milestoneFileRename: MilestoneImageOrFile) => {
    await renameFile(milestoneFileRename.id!, milestoneFileRename.name, +id!);
    await handleEditNameClose();
  };

  const onDeleteChicklist = async (checklistId: any, checklistName: string) => {
    await deleteChecklist(+id!, checklistId, checklistName);
    snackBar.show('Checklist item deleted successfully', 'success');
    await getChecklistItems(+milestoneId!);
  };

  const handleChangeStatus = async (
    checklistId: any,
    status: string,
    checklistName: string
  ) => {
    if (!id || !milestoneId) return;
    if (status === 'INCOMPLETE') {
      await changeChecklistItemStatus(
        Number(id),
        checklistId,
        'COMPLETE',
        checklistName
      );
      await getChecklistItems(Number(milestoneId));
    }

    if (status === 'COMPLETE') {
      await changeChecklistItemStatus(
        Number(id),
        checklistId,
        'INCOMPLETE',
        checklistName
      );
      await getChecklistItems(Number(milestoneId));
    }
  };

  const handleDateChange = async (
    newValue: any,
    checkistId: number,
    checklistName: string
  ) => {
    const formatedDate = newValue.format();
    await handleSaveChecklist(checkistId, 'due_date', {
      due_date: formatedDate,
      name: checklistName,
    });
  };

  const handleClickOpen = async (
    item: MilestoneImageOrFile,
    isImage: boolean,
    isComment = true
  ) => {
    saveImageOrFile(isImage);
    setSelectedValue(item);
    setIsLoadingComments(true);
    if (isComment) setOpenComments(true);
    else {
      await getMilestoneFileById(item.id!);
      setOpenEditName(true);
    }
    await getCommentsByImageOrFile(item.id ?? 0);
    setIsLoadingComments(false);
  };

  const handleClose = () => {
    saveImageOrFile(true);
    setOpenComments(false);
    getMilestoneById(+milestoneId!);
  };

  const orderChecklistItems = (a: Checklist, b: Checklist) => {
    let comparison = 0;
    if (
      a.due_date &&
      b.due_date &&
      a.status === ChecklistStatus.Incomplete &&
      b.status === ChecklistStatus.Incomplete
    ) {
      if (a.due_date > b.due_date) {
        comparison = 1;
      } else if (a.due_date < b.due_date) {
        comparison = -1;
      }
    }
    return comparison;
  };

  return (
    <div className="mt-12">
      <Formik
        enableReinitialize={true}
        initialValues={getMilestoneOrDefault(projectMilestone)}
        onSubmit={() => {
          // TODO
        }}>
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <div className="mb-20">
                <ProjectCard project={project ?? undefined} />
                <div className="w-full border-t border-light-gray-3 mt-5 mb-8" />
                <div className="flex">
                  <ProjectNavigator selectedTab={query.get('selectedTab')} />

                  {!isLoading && !isLoadingProjectDetail && projectMilestone ? (
                    <Grid item sx={{ flexGrow: 1 }}>
                      <div className="flex flex-start items-center">
                        <ArrowLeftCircleIcon
                          onClick={handleBackArrow}
                          className="w-6 h-6 text-mid-gray-5 cursor-pointer"
                        />
                        <span className="text-xl ml-4 font-semibold">
                          {projectMilestone.name}
                        </span>
                      </div>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <div className="font-semibold text-lg">Notes</div>
                      <TextField
                        id="notes"
                        placeholder="Add an optional note here."
                        multiline
                        rows={4}
                        variant="standard"
                        fullWidth
                        name="notes"
                        disabled={isNoteDisabled}
                        value={note || values.notes}
                        onChange={e => {
                          setFieldValue('notes', e.target.value);
                          setNote(e.target.value);
                        }}
                        inputRef={notesField}
                        onBlur={() => handleSaveNote(projectMilestone.id!)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <PencilSquareIcon
                                className="w-5 h-5 cursor-pointer text-mid-gray-5"
                                onClick={onNoteEditClick}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div ref={boxRef} className="mt-4">
                        <MilestoneChecklist
                          onChecklistEditClick={onChecklistEditClick}
                          handleTextInput={handleTextInput}
                          handleAddChecklist={handleAddChecklist}
                          handleSaveChecklist={handleSaveChecklist}
                          onDeleteChicklist={onDeleteChicklist}
                          handleChangeStatus={handleChangeStatus}
                          handleDateChange={handleDateChange}
                          setFieldValue={setFieldValue}
                          inputId={inputId}
                          checklistItem={checklistItem}
                          setAddChecklistItem={setAddChecklistItem}
                          checklistItems={checklistItems.sort(
                            orderChecklistItems
                          )}
                          checklistError={checklistError}
                        />
                      </div>
                      <div className="flex-grow border-t border-light-gray-3 mt-8" />
                      <div className="text-lg font-semibold text-dark-gray-1 mt-5">
                        Images
                      </div>
                      <MilestoneCarousel
                        data={projectMilestone}
                        handleUploadImage={handleUploadImage}
                        handleClickOpen={handleClickOpen}
                      />
                      <div className="flex-grow border-t border-light-gray-3 mt-8" />
                      <div className="flex justify-between items-center mt-5">
                        <div className="text-lg font-semibold text-dark-gray-1">
                          Attached Files
                        </div>
                        {canPerformAction() ? (
                          <label
                            id="milestones-uploadFile"
                            className="ml-3 cursor-pointer inline-flex items-center rounded-md border border-transparent bg-primary px-3 py-2 font-semibold leading-4 text-white shadow-sm hover:bg-dark-blue focus:outline-none disabled:opacity-70 disabled:hover:bg-primary">
                            Upload File
                            <input
                              hidden
                              onChange={handleUploadFile}
                              accept="*"
                              multiple
                              type="file"
                            />
                          </label>
                        ) : null}
                      </div>
                      <div className="mt-5">
                        <ManageFiles
                          projectMilestone={projectMilestone}
                          deleteMilestoneImageOrFile={
                            deleteMilestoneImageOrFile
                          }
                          getMilestoneById={getMilestoneById}
                          handleClickOpen={handleClickOpen}
                          projectId={+id!}
                          milestoneId={+milestoneId!}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <div className="w-full py-10 flex justify-center">
                      <Spinner className="w-8 h-8 z-15" />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <MilestoneFileUpload
        open={open}
        handleDialogClose={handleDialogClose}
        handleSave={handleSaveFile}
        changeFileName={changeFileName}
        fileName={milestoneFileName}
        milestones={stageMilestones}
        projectMilestoneId={projectMilestone?.id}
        fileUploadErrors={fileUploadErrors}
      />
      {openComments && (
        <>
          <MilestoneComments
            open={openComments}
            handleClickOpen={handleClickOpen}
            selectedValue={selectedValue}
            project={project!}
            data={projectMilestone!}
            loading={isLoadingComments}
            imageId={selectedValue.id}
            commentValue={commentValue}
            setCommentValue={setCommentValue}
            handleClose={handleClose}
            milestoneId={+milestoneId!}
            projectId={+id!}
          />
        </>
      )}
      <RenameFile
        handleEditNameClose={handleEditNameClose}
        openEditName={openEditName}
        onEditNameSave={onEditNameSave}
        milestoneFileData={milestoneFileData}
      />
    </div>
  );
});
