import {
  CalendarDaysIcon,
  CheckCircleIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import classNames from 'classnames';
import { filter, union } from 'lodash';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';

import { Checklist } from '_models/milestone/checklist';
import useAuthStore from '_stores/useAuthStore';

import Spinner from './spinner';

export const MilestoneChecklist = (props: {
  onChecklistEditClick: (event: any) => void;
  handleTextInput: (event: any, item: any) => void;
  handleAddChecklist: () => Promise<void>;
  handleSaveChecklist: (
    event: any,
    column: string,
    item: { name?: string; due_date?: string }
  ) => void;
  onDeleteChicklist: (event: any, item: string) => void;
  handleChangeStatus: (event: any, item: any, name: string) => Promise<void>;
  handleDateChange: (event: any, item: any, name: string) => void;
  setFieldValue: any;
  inputId: any;
  checklistItem: any;
  setAddChecklistItem: any;
  checklistItems: Checklist[];
  checklistError: boolean;
}) => {
  const {
    onChecklistEditClick,
    handleTextInput,
    handleAddChecklist,
    handleSaveChecklist,
    onDeleteChicklist,
    handleChangeStatus,
    handleDateChange,
    setFieldValue,
    inputId,
    checklistItem,
    setAddChecklistItem,
    checklistItems,
  } = props;
  const addRef = useRef<HTMLInputElement | null>(null);
  const [busyIds, setBusyIds] = useState<(number | undefined)[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [hoveringId, setHoveringId] = useState<number | undefined>();

  const addCheckList = async () => {
    setIsAdding(true);
    await handleAddChecklist();
    setIsAdding(false);
  };

  const canPerformAction = useAuthStore($ => $.canPerformAction);

  return (
    <div onMouseLeave={() => setHoveringId(undefined)}>
      {checklistItems.map((checklist: Checklist) => {
        return (
          <div
            key={checklist.id}
            onMouseOver={() => setHoveringId(checklist.id)}
            className={classNames(
              'w-full flex justify-between text-dark-gray-1 px-1 py-2 rounded-md',
              hoveringId === checklist.id ? 'bg-light-gray-4' : ''
            )}>
            <div className="grid grid-cols-6 flex-1">
              <div className="flex justify-start items-center col-span-5">
                {busyIds.includes(checklist?.id) ? (
                  <div className="w-6 h-6 flex items-center justify-center rounded-full">
                    <Spinner className="w-5 h-5 fill-white text-primary"></Spinner>
                  </div>
                ) : (
                  <CheckCircleIcon
                    id="mileStones-checklist-checkCircleIcon"
                    className={classNames(
                      'h-6 w-6 cursor-pointer ',
                      checklist.status === 'COMPLETE'
                        ? 'text-green-500'
                        : 'text-light-gray-1'
                    )}
                    onClick={async () => {
                      setBusyIds(val => union(val, [checklist?.id]));
                      await handleChangeStatus(
                        checklist.id,
                        checklist.status,
                        checklist.name
                      );
                      setBusyIds(val =>
                        filter(val, id => id !== checklist?.id)
                      );
                    }}
                  />
                )}
                {inputId === checklist.id ? (
                  <input
                    key={checklist.id}
                    id={`chk_${checklist.id}`}
                    name="name"
                    defaultValue={checklist.name}
                    required
                    placeholder="Add Checklist Item"
                    onChange={e => {
                      handleTextInput(e.target.value, checklist.id);
                    }}
                    onBlur={e =>
                      handleSaveChecklist(checklist.id, 'name', {
                        name: e.target.value,
                      })
                    }
                    className="ml-2 w-96 focus:outline-none font-semibold disabled:bg-white disabled:opacity-30 bg-transparent"
                  />
                ) : (
                  <div className="ml-2">
                    <span
                      className={classNames(
                        'font-semibold',
                        checklist.status === 'COMPLETE' ? 'line-through' : ''
                      )}>
                      {checklist.name}
                    </span>
                    {checklist.status === 'COMPLETE' ? (
                      <span className="text-mid-gray-3 text-xs ml-2">
                        Completed on {moment(checklist.updated_at).format('LL')}{' '}
                        by{' '}
                        <span className="text-dark-gray-1 font-semibold">{`${
                          checklist.profile?.first_name || ''
                        } ${checklist.profile?.last_name || ''}`}</span>
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
              {canPerformAction() ? (
                <div className="col-span-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Custom input"
                      value={checklist.due_date}
                      onChange={e => {
                        handleDateChange(e, checklist.id, checklist.name);
                      }}
                      components={{
                        OpenPickerIcon: () => (
                          <CalendarDaysIcon className="w-4 h-4 text-mid-gray-5" />
                        ),
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div className="flex justify-start items-center gap-x-4">
                          {hoveringId === checklist.id &&
                          inputId !== checklist.id ? (
                            InputProps?.endAdornment
                          ) : (
                            <div className="w-6 h-6" />
                          )}
                          <input {...inputProps} className="hidden" />
                          <span className="font-semibold" ref={inputRef}>
                            {checklist.due_date
                              ? moment(checklist.due_date).format('DD MMM YYYY')
                              : '-'}
                          </span>
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              ) : null}
            </div>
            {canPerformAction() ? (
              <div className="w-12 flex items-center justify-between">
                {hoveringId === checklist.id && inputId !== checklist.id ? (
                  <>
                    <PencilSquareIcon
                      className="w-4 h-4 text-mid-gray-5 cursor-pointer"
                      onClick={() => onChecklistEditClick(checklist.id)}
                    />
                    <TrashIcon
                      className="w-4 h-4 text-mid-gray-5 cursor-pointer"
                      onClick={() => {
                        onDeleteChicklist(checklist.id, checklist.name);
                      }}
                    />
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        );
      })}
      {canPerformAction() ? (
        <div className="px-1 flex justify-start gap-x-2 mt-2">
          {isAdding ? (
            <div className="w-6 h-6 flex items-center justify-center rounded-full">
              <Spinner className="w-5 h-5 fill-white text-light-gray-1"></Spinner>
            </div>
          ) : (
            <PlusCircleIcon
              className="w-6 h-6 cursor-pointer text-light-gray-1"
              id="milestones-addCheckListItem-button"
              onClick={() => addRef.current?.focus()}
            />
          )}
          <input
            id="milestones-addCheckListItem-names-input"
            value={checklistItem}
            placeholder="Add Checklist Item"
            onChange={e => {
              setFieldValue('name', e.target.value);
              setAddChecklistItem(e.target.value);
            }}
            onBlur={addCheckList}
            onKeyDown={e => (e.key === 'Enter' ? addCheckList() : null)}
            ref={addRef}
            disabled={isAdding}
            className="w-full focus:outline-none font-semibold disabled:bg-white disabled:opacity-30"
          />
        </div>
      ) : null}
    </div>
  );
};
