import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuthStore from '_stores/useAuthStore';

export const CLIENT_ALLOWED_ROUTES = [
  '/download-app',
  '/set-password',
  '/reset-password',
];

export const SUPER_ADMIN_ALLOWED_ROUTES = ['/organizations', '/profile'];

export const DESGINER_ALLOWED_ROUTES = [
  '/projects',
  '/profile',
  '/chats',
  '/people/staff',
  '/set-password',
];

export const ARCHIVED_ADMIN_ALLOWED_ROUTES = [
  '/projects',
  '/profile',
  '/chats',
  '/people/staff',
  '/set-password',
];

export const ADMIN_ALLOWED_ROUTES = [
  '/projects',
  '/profile',
  '/chats',
  '/people',
  '/manage/templates',
  '/manage/organisation-info',
  '/set-password',
];

const isRouteAllowed = (pathname: string, routes: string[]) => {
  for (let i = 0; i < routes.length; i++) {
    if (pathname.startsWith(routes[i])) {
      return true;
    }
  }
};

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const location = useLocation();
  const user = useAuthStore($ => $.user);
  const profile = useAuthStore($ => $.profile);
  const selectedOrganization = useAuthStore($ => $.selectedOrganization);

  const role = useMemo(() => {
    if (profile?.role === 'CLIENT' || profile?.role === 'SUPER_ADMIN') {
      return profile?.role;
    } else {
      return selectedOrganization?.organization_user?.[0].role;
    }
  }, [profile, selectedOrganization]);

  const status = useMemo(() => {
    if (profile?.role === 'CLIENT' || profile?.role === 'SUPER_ADMIN') {
      return 'ACTIVE';
    } else {
      return selectedOrganization?.organization_user?.[0].status;
    }
  }, [profile, selectedOrganization]);

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    role === 'CLIENT' &&
    !isRouteAllowed(location.pathname, CLIENT_ALLOWED_ROUTES)
  ) {
    return (
      <Navigate
        to={CLIENT_ALLOWED_ROUTES[0]}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    profile?.first_time_login &&
    location.pathname !== '/profile' &&
    location.pathname !== '/set-password' &&
    role !== 'CLIENT'
  ) {
    return <Navigate to="/profile" state={{ from: location }} replace />;
  }

  if (
    role === 'SUPER_ADMIN' &&
    !isRouteAllowed(location.pathname, SUPER_ADMIN_ALLOWED_ROUTES)
  ) {
    return (
      <Navigate
        to={SUPER_ADMIN_ALLOWED_ROUTES[0]}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    role === 'DESIGNER' &&
    !isRouteAllowed(location.pathname, DESGINER_ALLOWED_ROUTES)
  ) {
    return (
      <Navigate
        to={DESGINER_ALLOWED_ROUTES[0]}
        state={{ from: location }}
        replace
      />
    );
  }

  if (role === 'ADMIN') {
    const allowedRoutes =
      status === 'ACTIVE'
        ? ADMIN_ALLOWED_ROUTES
        : ARCHIVED_ADMIN_ALLOWED_ROUTES;

    if (!isRouteAllowed(location.pathname, allowedRoutes)) {
      return (
        <Navigate to={allowedRoutes[0]} state={{ from: location }} replace />
      );
    }
  }

  return children;
}

export default RequireAuth;
