import { useQuery } from '@tanstack/react-query';

import { v4 as uuidv4 } from 'uuid';

import { supabase } from '_clients/supabaseClient';
import { ProjectType } from '_types/projectType';

export interface TemplateStageMilestone {
  id: string;
  name: string;
  sequence: number;
  checklist?: string[];
}

export interface ProjectTemplateStage {
  id: string;
  name: string;
  sequence: number;
  milestones: TemplateStageMilestone[];
}

export const fetchProjectType = async (id?: string | number) => {
  const { data, error } = await supabase
    .from('project_type')
    .select()
    .eq('id', id)
    .single();

  if (error) {
    throw error;
  }

  const projectType = data as unknown as ProjectType;

  // fill in id for local process
  projectType.template.forEach(stage => {
    if (!stage.id) {
      stage.id = uuidv4();
    }

    stage.milestones.forEach(milestone => {
      if (!milestone.id) {
        milestone.id = uuidv4();
      }
    });
  });

  return projectType;
};

const useProjectType = (id?: string | number) =>
  useQuery(['projectType', id], () => fetchProjectType(id), {
    enabled: !!id,
    staleTime: Infinity,
  });

export default useProjectType;
