import { Database } from './database.types';

export type Profile = Database['public']['Tables']['profile']['Row'];

export const Roles = {
  Client: 'CLIENT',
  Designer: 'DESIGNER',
  Admin: 'ADMIN',
  SuperAdmin: 'SUPER_ADMIN',
  Test: 'TEST',
} as const;

export const ROLE_LABEL = {
  [Roles.Client]: 'Client',
  [Roles.Designer]: 'Designer',
  [Roles.Admin]: 'Admin',
  [Roles.SuperAdmin]: 'Super Admin',
  [Roles.Test]: 'Tester',
};

export type Role = (typeof Roles)[keyof typeof Roles];
