import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@mui/material';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import Button from '_components/button';
import Label from '_components/label';
import { Stage } from '_models/milestone/stage';
import { MilestoneStore } from '_store/milestone-store';

export const MilestoneFileUpload = observer(
  (props: {
    open: boolean;
    handleDialogClose: () => void;
    handleSave: () => void;
    changeFileName: (event: any) => void;
    fileName: string;
    milestones?: Stage[];
    projectMilestoneId?: number;
    fileUploadErrors: { name: boolean; milestone: boolean };
  }) => {
    const {
      open,
      handleDialogClose,
      handleSave,
      changeFileName,
      fileName,
      milestones,
      projectMilestoneId,
      fileUploadErrors,
    } = props;
    const { saveMilestoneIds } = MilestoneStore;
    const [checked, setChecked] = useState<number[]>([]);

    const handleToggle = (value: number) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
      saveMilestoneIds(newChecked);
    };

    return (
      <Dialog open={open} onClose={handleDialogClose}>
        <div className="p-5 border-b text-lg font-semibold">Upload File</div>
        <div className="p-4 bg-light-gray-5">
          <>
            <div className="relative flex items-center">
              <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                Details
              </span>
              <div className="flex-grow border-t border-light-gray-3 ml-3" />
            </div>

            <div className="flex justify-start items-center mt-5">
              <Label className="w-32">Filename</Label>
              <input
                required
                id="Filename"
                name="Filename"
                type="text"
                title="Filename"
                value={fileName}
                onChange={changeFileName}
                className="block w-64 ml-8 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              {fileUploadErrors.name && (
                <span className="ml-6 text-red-500">
                  {fileUploadErrors.name}
                </span>
              )}
            </div>
            <div className="relative flex items-center mt-6">
              <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                Milestone
              </span>
              <div className="flex-grow border-t border-light-gray-3 ml-3" />
            </div>
            <div className="mt-5">
              {fileUploadErrors.milestone &&
                'Atleast 1 milestone should be selected'}
            </div>
            {(milestones || []).map(m => {
              return (
                <div key={m.id}>
                  <div
                    onClick={handleToggle(m.id!)}
                    className="flex items-center cursor-pointer hover:bg-light-gray-4 mt-2 py-2">
                    <CheckCircleIcon
                      className={classNames(
                        'w-6 h-6',
                        m.id === projectMilestoneId ||
                          checked.indexOf(m.id!) !== -1
                          ? 'text-green-600'
                          : 'text-mid-gray-3'
                      )}
                    />
                    <div className="ml-2">{m.name}</div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className="border-t flex justify-end p-5">
          <Button color="secondary" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button type="button" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
);
