import { User } from '@supabase/supabase-js';

import { makeAutoObservable, runInAction } from 'mobx';

import { ProjectDetail } from '_hooks/useProjectBasicInfo';
import { ProjectUpdate } from '_models/project-update';
import { ProjectUpdateMessages } from '_models/project-update/project-update-message';
import { ProjectUpdateTypes } from '_models/project-update/project-update-type';
import { chatService } from '_services/chat-service';
import { profileService } from '_services/profile-service';
import { ProjectUpdateService } from '_services/project-update-service';
import useAuthStore from '_stores/useAuthStore';
import { Profile } from '_types/profile';
import { Project } from '_types/project';

import { ErrorResponse } from '../models/error-response';
import { ProjectUserList } from '../models/project/project-user-list';
import { ProjectService } from './../services/project-service';

const { REACT_APP_SUPABASE_URL } = process.env;
const STORAGE_BUCKET_URL = `${REACT_APP_SUPABASE_URL}/storage/v1/object/public`;
const STORAGE_BUCKET_KEY_AVATARS = 'project';

export type FilterValue = number | string | string[] | number[];

class ProjectStoreImplementation {
  project: ProjectDetail | null = null;

  errors: ErrorResponse[] = [];

  imageUrl = `${STORAGE_BUCKET_URL}/${STORAGE_BUCKET_KEY_AVATARS}`;

  imagePath = '';

  filteredValues: {
    column: string;
    value: number | string | string[] | number[];
    operand: string;
  }[] = [];

  users?: Profile[] = [];

  projectId?: number;

  projectUsers: ProjectUserList[] = [];

  isImageUploaded = false;

  projectUpdate?: ProjectUpdate[] = [];

  filteredUsers: Profile[] = [];

  projectUpdateId: number | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  saveProjectId = (id: number) => {
    runInAction(() => {
      this.projectId = id;
    });
  };

  // saveImage = (image: any) => {
  //   runInAction(() => {
  //     this.projectImage = image;
  //   });
  //   this.isImageUploaded = true;
  // };

  resetUser = () => {
    this.users = [];
    this.errors = [];
  };

  updateInStore = (updatedValues: Record<string, unknown>) => {
    runInAction(() => {
      if (!this.project) return;
      this.project = { ...this.project, ...updatedValues };
    });
  };

  addUserToProject = async (
    userId: string,
    project_id?: number,
    status?: string,
    profile?: Profile
  ) => {
    const { errors } = await ProjectService.addUserToProject({
      user_id: userId,
      project_id: project_id ? project_id : this.projectId ?? 0,
      status: status ? status : 'PENDING',
    });
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    if (profile) {
      await profileService.update({ ...profile, id: userId });
    }

    const currentUserProfile = useAuthStore.getState().profile;

    let updateType;
    if (status === 'ACTIVE') {
      updateType = ProjectUpdateTypes.PROJECT_USER_ADDED;
      await this.insertProjectUpdate({
        type: updateType,
        project_id: project_id ?? 0,
        action_taker_id: currentUserProfile?.id ?? '',
        message: ProjectUpdateMessages[updateType],
        payload: {
          user_id: userId,
        },
      });
    }

    return true;
  };

  // upload = async (id: number) => {
  //   if (!this.projectImage) {
  //     return;
  //   }
  //   const timestamp = +new Date();
  //   const filePath = `${id}/${timestamp}_${this.projectImage.name}`;
  //   this.imagePath = `${STORAGE_BUCKET_URL}/${STORAGE_BUCKET_KEY_AVATARS}/${filePath}`;
  //   const response = await ProjectService.upload(filePath, this.projectImage);
  //   if (ResponseViewModel.hasErrors(response)) {
  //     runInAction(() => {
  //       this.errors = response.errors;
  //     });
  //     return;
  //   }
  //   const { errors } = await ProjectService.updateImagePath(id, this.imagePath);
  //   if (errors && errors.length > 0) {
  //     runInAction(() => {
  //       this.errors = errors;
  //     });
  //     return;
  //   }
  //   this.updateInStore({ cover_image_url: this.imagePath });
  // };

  // removeImage = async () => {
  //   if (!this.project?.id) {
  //     this.projectImage = {};
  //     return;
  //   }
  //   const fragments = this.project?.cover_image_url?.split('/');
  //   if (!fragments || fragments.length === 0) {
  //     return;
  //   }
  //   const value = `${this.project.id}/${fragments.pop()}`;
  //   await ProjectService.removeProjectFile([value]);
  //   const { errors } = await ProjectService.updateImagePath(
  //     this.project.id,
  //     null
  //   );
  //   if (errors && errors.length > 0) {
  //     runInAction(() => {
  //       this.errors = errors;
  //     });
  //     return;
  //   }
  //   this.updateInStore({ cover_image_url: undefined });
  // };

  getProjectUsers = async (id: number) => {
    this.errors = [];
    const { data, errors } = await ProjectService.getProjectUsersByProjectId(
      id
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    runInAction(() => {
      this.projectUsers = data ?? [];
    });
  };

  getProjectUpdate = async (id: number) => {
    this.projectUpdate = [];
    this.errors = [];
    if (id) {
      const { data, errors } = await ProjectService.getProjectUpdateByProjectId(
        id
      );
      if (errors && errors.length > 0) {
        runInAction(() => {
          this.errors = errors;
        });
        return;
      }
      runInAction(() => {
        this.projectUpdate = data ?? undefined;
      });
    }
  };

  getProjectList = async (user: User, profile: Profile) => {
    const filters: {
      column: string;
      value: number | string | [];
      operand: string;
    }[] = [];
    if (profile.role !== 'ADMIN') {
      filters.push({
        column: 'project_user_temp.user_id',
        value: user.id,
        operand: 'eq',
      });
    }
    runInAction(() => {
      this.filteredValues = filters;
    });
    const { errors } = await ProjectService.getProjectList(filters);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
  };

  processProjectUpdates = async (
    project: Project,
    prevValues: Project,
    profile: Profile
  ) => {
    if (project.status !== prevValues.status) {
      const updateType =
        project.status === 'ACTIVE'
          ? ProjectUpdateTypes.PROJECT_ACTIVE
          : ProjectUpdateTypes.PROJECT_ARCHIVED;

      return this.insertProjectUpdate({
        type: updateType,
        project_id: project.id ?? 0,
        action_taker_id: profile.id,
        message: ProjectUpdateMessages[updateType],
      });
    }

    return this.insertProjectUpdate({
      type: ProjectUpdateTypes.PROJECT_UPDATED,
      project_id: project.id ?? 0,
      action_taker_id: profile.id,
      message: ProjectUpdateMessages[ProjectUpdateTypes.PROJECT_UPDATED],
    });
  };

  insertProjectUpdate = async (projectUpdate: ProjectUpdate) => {
    const { data, errors } = await ProjectUpdateService.insert(projectUpdate);
    this.projectUpdateId = data?.id;
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return null;
    }
  };

  searchUserBy = async (
    filters: { column: string; value: number | string; operand: string }[]
  ) => {
    const { data, errors } = await ProjectService.searchUserBy(filters);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return false;
    }

    if (data && data.length > 0) {
      const userExists = await this.projectUsers.find(
        i => i.user_id === data[0].id
      );
      if (userExists) {
        runInAction(() => {
          this.errors = [{ property: 'User', message: 'User already exists' }];
        });
        return null;
      }
      runInAction(() => {
        this.users = data;
      });
    } else {
      return false;
    }
  };

  setUserActive = async (id: string) => {
    const { errors } = await ProjectService.updateStatusOfProjectUser({
      id: id,
      status: 'ACTIVE',
    });
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    return true;
  };

  updateProjectUpdate = async (userId: string) => {
    if (this.projectUpdateId !== undefined) {
      const { errors } = await ProjectUpdateService.update(
        this.projectUpdateId,
        userId
      );
      if (errors && errors.length > 0) {
        runInAction(() => {
          this.errors = errors;
        });
        return;
      }
    }
  };

  removeProjectUser = async (userId: string, project_id: number) => {
    const { errors } = await ProjectService.removeProjectUser(
      userId,
      project_id
    );
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
    }
    // if (isNewUser) {
    //   const { errors: profileErrors } = await profileService.removeProfile(
    //     userId
    //   );
    //   if (profileErrors && profileErrors.length > 0) {
    //     runInAction(() => {
    //       this.errors = errors;
    //     });
    //   }
    //   const { errors: authErrors } = await profileService.removeAuthUser(
    //     userId
    //   );
    //   if (authErrors && authErrors.length > 0) {
    //     runInAction(() => {
    //       this.errors = errors;
    //     });
    //   }
    // }
    const currentUserProfile = useAuthStore.getState().profile;
    const updateType = ProjectUpdateTypes.PROJECT_USER_REMOVED;
    await this.insertProjectUpdate({
      type: updateType,
      project_id: project_id,
      action_taker_id: currentUserProfile?.id ?? '',
      message: ProjectUpdateMessages[updateType],
      payload: {
        user_id: userId,
      },
    });
    await chatService.removeChannelUser(project_id, userId);
  };

  inviteUserByPhone = async (
    phone: string,
    projectId: number,
    status = 'PENDING'
  ): Promise<string | undefined> => {
    const { data, errors } = await ProjectService.inviteUserByPhone(phone);
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.errors = errors;
      });
      return;
    }
    const currentUserProfile = useAuthStore.getState().profile;
    await this.insertProjectUpdate({
      type: ProjectUpdateTypes.PROJECT_USER_INVITED,
      project_id: projectId,
      action_taker_id: currentUserProfile?.id ?? '',
      message: ProjectUpdateMessages[ProjectUpdateTypes.PROJECT_USER_INVITED],
    });
    await this.updateProjectUpdate(data ?? '');
    await this.addUserToProject(
      currentUserProfile?.id ?? '',
      projectId,
      status,
      currentUserProfile ?? undefined
    );
    return data ?? undefined;
  };
}

export const ProjectStore = new ProjectStoreImplementation();
