import { supabase } from '_clients/supabaseClient';
import { ResponseViewModel } from '_models/response-viewmodel';
import { FilterValue } from '_store/project-store';

const addFilter = (
  tableSelect: any,
  filters: {
    column: string;
    value: FilterValue;
    operand: string;
  }[] = []
) => {
  filters.forEach((f: any) => {
    if (f.value !== 0 && f.value !== '' && f.value !== ':*') {
      if (f.operand === 'textSearch') {
        tableSelect.textSearch(f.column, f.value);
      } else if (f.operand === 'in') {
        tableSelect.in(f.column, f.value);
      } else if (f.operand === 'neq') {
        tableSelect.neq(f.column, f.value);
      } else if (f.operand === 'gt') {
        tableSelect.gt(f.column, f.value);
      } else {
        tableSelect.eq(f.column, f.value);
        return;
      }
    }
  });
};

let onRequest: (ending: boolean) => void;
export const supabaseService = {
  init(cb: (ending: boolean) => void, authChangeCb?: (event: any) => void) {
    onRequest = cb;
    supabase.auth.onAuthStateChange(event => {
      if (!authChangeCb) {
        return;
      }
      authChangeCb(event);
    });
  },

  async getLoggedInUser() {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (session) {
      return session?.user;
    }
    return ResponseViewModel.fromError('Session got expired');
  },
  async isAuthenticated() {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    return !!session;
  },

  async getToken() {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    return session?.access_token ?? '';
  },
  async setFCMToken(user_id: string, token: string) {
    const { count } = await supabase
      .from('fcm_token')
      .select('*', { count: 'exact' })
      .eq('user_id', user_id)
      .eq('token', token);
    if ((count || 0) > 0) {
      return;
    }
    return supabase
      .from('fcm_token')
      .upsert({
        user_id,
        token,
      })
      .select();
  },
  async getFCMToken(user_id: string) {
    return supabase.from('fcm_token').select('token').eq('user_id', user_id);
  },

  async updatePhoneNumber(
    phoneNumber: string
  ): Promise<ResponseViewModel<boolean>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { error } = await supabase.auth.updateUser({
        phone: phoneNumber,
      });
      return error
        ? ResponseViewModel.fromError(error.message)
        : ResponseViewModel.fromModel<boolean>(true);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async inviteUserByPhone(phone: string): Promise<ResponseViewModel<string>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const randomPassword = new Date();
      // try signing him up with random password since supabase requires password field
      const {
        data: { user },
        error,
      } = await supabase.auth.signUp({
        phone,
        password: (+randomPassword).toString(),
      });

      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel<string>(user!.id);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async getSingle<T>(
    table: string,
    column: string,
    value: string | number
  ): Promise<ResponseViewModel<T>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { data, error } = await supabase
        .from(table)
        .select()
        .eq(column, value)
        .maybeSingle();
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel<T>(data);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },
  async getAll<T>(
    table: string,
    columns?: string,
    orderBy = 'id'
  ): Promise<ResponseViewModel<T[]>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { data, error } = await supabase
        .from(table)
        .select<any>(columns)
        .order(orderBy, { ascending: true });

      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel<T[]>(data as any);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async getByMultipleOrderBy<T>(
    table: string,
    column: string,
    columnValue: number | string,
    orderBy?: {
      column: string;
      asc: boolean;
      foreignTable?: string;
    }[],
    selectedColumn = '*'
  ): Promise<ResponseViewModel<T[]>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const tableSelect = supabase
        .from(table)
        .select(selectedColumn)
        .eq(column, columnValue);

      if (orderBy) {
        orderBy.forEach(o => {
          if (o.foreignTable) {
            tableSelect.order(o.column, {
              foreignTable: o.foreignTable,
              ascending: o.asc,
            });
          } else {
            tableSelect.order(o.column, { ascending: o.asc });
          }
        });
      }

      const { data, error } = await tableSelect;

      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel<T[]>(data as any);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async getPaginatedList<T>(
    table: string,
    columns: string
  ): Promise<ResponseViewModel<T[]>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { data, error } = await supabase
        .from(table)
        .select(columns, { count: 'exact' })
        .order('created_at', { ascending: false });
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel<T[]>(data as any);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async update<T>(
    table: string,
    data: T | T[],
    onConflict?: string
  ): Promise<ResponseViewModel<boolean>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { error } = await supabase.from(table).upsert(data as Partial<any>);
      // .select();
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel(true);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async patchConditional(
    table: string,
    where: { column: string; value: unknown },
    fieldsToUpdate: Record<string, unknown>
  ): Promise<ResponseViewModel<boolean>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { error } = await supabase
        .from(table)
        .update(fieldsToUpdate)
        .eq(where.column, where.value);
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel(true);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async patch(
    table: string,
    id: string | number,
    fieldsToUpdate: Record<string, unknown>
  ): Promise<ResponseViewModel<boolean>> {
    return supabaseService.patchConditional(
      table,
      { column: 'id', value: id },
      fieldsToUpdate
    );
  },

  async insert<T>(table: string, values: T): Promise<ResponseViewModel<T>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { data, error } = await supabase
        .from(table)
        .insert(values as Partial<any>)
        .select();
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }

      return ResponseViewModel.fromModel(data?.[0] as unknown as T);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async uploadFile(
    storage: string,
    filepath: string,
    file: string | File
  ): Promise<ResponseViewModel<boolean>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { error } = await supabase.storage
        .from(storage)
        .upload(filepath, file, {
          cacheControl: '3600',
          upsert: true,
        });
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel(true);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async removeFile(
    storage: string,
    imagePath: string[]
  ): Promise<ResponseViewModel<boolean>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { error } = await supabase.storage.from(storage).remove(imagePath);
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel(true);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async deleteUser(id: string): Promise<ResponseViewModel<boolean>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { error } = await supabase.auth.admin.deleteUser(id);
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel(true);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async getFilteredList<T>(
    table: string,
    filters: {
      column: string;
      value: FilterValue;
      operand: string;
    }[] = [],
    columns = '*',
    limit?: number,
    extra?: { count: number; skip?: number }
  ): Promise<ResponseViewModel<T[]>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const tableSelect = supabase
        .from(table)
        .select(columns, { count: extra ? 'exact' : undefined })
        .order('created_at', { ascending: false });
      addFilter(tableSelect, filters);
      if (extra && extra.skip) {
        tableSelect.range(extra.skip, extra.skip + (limit! - 1));
      } else if (limit) {
        tableSelect.limit(limit);
      }

      const { data, error, count } = await tableSelect;
      if (extra) {
        extra.count = count || 0;
      }
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel<T[]>(data as any);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async getRecordsByInnerJoin<T>(
    table: string,
    column: string,
    filterColumn: string,
    filterValue: string | number | [],
    orderColumn = 'id',
    orderByAscending?: {
      ascending?: boolean;
      nullsFirst?: boolean;
      foreignTable?: undefined;
    }
  ): Promise<ResponseViewModel<T[]>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { data, error } = await supabase
        .from(table)
        .select(column)
        .eq(filterColumn, filterValue)
        .order(orderColumn, orderByAscending);
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel<T[]>(data as any);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },

  async deleteByMatch(
    table: string,
    match: Record<string, unknown>
  ): Promise<ResponseViewModel<boolean>> {
    if (onRequest) {
      onRequest(false);
    }
    try {
      const { data, error } = await supabase.from(table).delete().match(match);
      if (error) {
        return ResponseViewModel.fromError(error.message);
      }
      return ResponseViewModel.fromModel(data?.[0] ?? false);
    } finally {
      if (onRequest) {
        onRequest(true);
      }
    }
  },
};
