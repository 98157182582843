import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import classNames from 'classnames';
import { Fragment, useState } from 'react';

interface Props<T> {
  options: {
    key: T;
    label: string;
  }[];
  label?: string;
  onChange: (value: T) => void;
}

export default function Select<T>({ onChange, options, label }: Props<T>) {
  const [value, setValue] = useState<T>(options[0].key);

  const handleChange = (v: T) => {
    setValue(v);
    onChange(v);
  };

  return (
    <Listbox value={value} onChange={handleChange}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary">
            <span className="inline-flex w-full truncate">
              <span className="text-mid-gray-4">{label}</span>
              <span className="truncate ml-2">
                {options.find(o => o.key === value)?.label}
              </span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((op, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-primary' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={op.key}>
                  {({ selected, active }) => (
                    <>
                      <div className="flex">
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'truncate'
                          )}>
                          {op.label}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-primary',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
