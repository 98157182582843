import { Dialog, Transition } from '@headlessui/react';

import { useFormik } from 'formik';
import { Fragment, useState } from 'react';
import * as yup from 'yup';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';
import useAuthStore from '_stores/useAuthStore';

import useOrganizationStore from './store';

interface FormValues {
  title: string;
  order?: number;
}

const OrganizationSchema = yup.object().shape({
  title: yup.string().label('Title').required(),
});

const CreateTemplateModal = () => {
  const selectedOrganization = useAuthStore($ => $.selectedOrganization);

  const isCreateModalVisible = useOrganizationStore(
    $ => $.isCreateModalVisible
  );
  const setIsCreateModalVisible = useOrganizationStore(
    $ => $.setIsCreateModalVisible
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      title: '',
      order: undefined,
    },
    validationSchema: OrganizationSchema,
    onSubmit: async values => {
      console.log(values);
      try {
        setIsSubmitting(true);
        const { error } = await supabase.from('project_type').insert({
          name: values.title,
          sequence: values.order,
          organization_id: selectedOrganization?.id,
          template: [],
        });
        if (error) {
          throw error;
        }
        queryClient.invalidateQueries(['projectTypes']);
        formik.resetForm();
        setIsCreateModalVisible(false);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    setIsCreateModalVisible(false);
    formik.resetForm();
  };

  return (
    <Transition.Root show={isCreateModalVisible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setIsCreateModalVisible}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-[638px] relative">
                {isSubmitting ? (
                  <div className="absolute inset-0 bg-white opacity-70 z-20 flex justify-center items-center">
                    <Spinner className="w-8 h-8" />
                  </div>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  <div className="font-semibold text-dark-gray-1 text-lg p-5">
                    Create Template
                  </div>
                  <div className="px-5 py-7 bg-light-gray-5 border-t border-light-gray-3">
                    <div className="relative flex items-center">
                      <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                        Project Template
                      </span>
                      <div className="flex-grow border-t border-light-gray-3 ml-3" />
                    </div>
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1.5">Title</Label>
                      <div className="ml-4">
                        <input
                          name="title"
                          placeholder="e.g. Condo"
                          onChange={formik.handleChange}
                          value={formik.values.title}
                          className="w-64 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {formik.errors.title && formik.touched.title ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1.5">Order</Label>
                      <div className="ml-4">
                        <input
                          name="order"
                          placeholder="e.g. 1-255"
                          onChange={formik.handleChange}
                          value={formik.values.order ?? ''}
                          type="number"
                          className="w-64 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {formik.errors.order && formik.touched.order ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.order}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-2.5 text-dark-gray-2">
                      * Templates are sorted in order from lowest to highest,
                      then alphabetically.
                    </div>
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                    <Button color="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateTemplateModal;
