import { AlertColor } from '@mui/material';

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface SnackBarState {
  isVisible: boolean;
  message: string;
  severity: AlertColor;
  title: string;

  show: (message: string, severity: AlertColor, title?: string) => void;
  hide: () => void;
}

const useSnackBarStore = create<SnackBarState>()(
  immer(set => ({
    isVisible: false,
    message: '',
    title: '',
    severity: 'success',

    show: (message: string, severity: AlertColor, title?: string) => {
      set({ isVisible: true, message, severity, title });
    },

    hide: () => {
      set({ isVisible: false });
    },
  }))
);

export default useSnackBarStore;
