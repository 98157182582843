import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  className?: string;
  color?: 'primary' | 'secondary' | 'gray' | 'red';
}

const primaryClasses = [
  'font-semibold',
  'text-white',
  'bg-primary',
  'hover:bg-dark-blue',
  'border-transparent',
];

const secondaryClasses = ['bg-white hover:bg-gray-50'];

const grayClasses = [
  'font-semibold',
  'text-white',
  'bg-mid-gray-1',
  'hover:bg-mid-gray-2',
  'border-transparent',
];

const redClasses = [
  'font-semibold',
  'text-white',
  'bg-red-400',
  'hover:bg-red-300',
  'border-transparent',
];

const Button = ({
  type = 'button',
  disabled,
  onClick,
  icon,
  children,
  className,
  color = 'primary',
}: Props) => {
  const classes = useMemo(() => {
    if (color === 'primary') {
      return primaryClasses.join(' ');
    }
    if (color === 'secondary') {
      return secondaryClasses.join(' ');
    }
    if (color === 'gray') {
      return grayClasses.join(' ');
    }
    if (color === 'red') {
      return redClasses.join(' ');
    }
    return '';
  }, [color]);

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'inline-flex items-center rounded-md border px-4 py-1.5 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none',
        classes,
        className
      )}>
      {icon}
      {children}
    </button>
  );
};

export default Button;
