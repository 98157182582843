import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';

export const fetchCountries = async () => {
  const { data: countries, error } = await supabase.from('country').select('*');

  if (error) {
    throw error;
  }

  return countries;
};

const useCountryList = () => useQuery(['countries'], () => fetchCountries());

export default useCountryList;
