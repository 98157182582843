import { Profile } from "_types/profile";

export type Comment = {
  user_id: string;
  comment: string;
  timestamp: Date;
  user?: Profile;
};

export type MilestoneImageOrFile = {
  id?: number;
  project_milestone_id: number;
  name: string;
  file_url: string;
  file_type?: string;
  comments?: Comment[];
  uploaded_by?: string;
  uploaded_by_user?: Profile;
  total_comments?: number;
  created_at?: Date;
  updated_at?: Date;
};

export const getImageFileOrDefault = (imageOrFile?: MilestoneImageOrFile): MilestoneImageOrFile =>
  imageOrFile ?? {
    project_milestone_id: 0,

    name: '',

    file_url: '',

    file_type: '',

    comments: [],

    updated_at: new Date(),
  };
