import { Popover, Transition } from '@headlessui/react';
import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  ClipboardDocumentCheckIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useAuthStore from '_stores/useAuthStore';

const Topbar = () => {
  const loc = useLocation();
  const profile = useAuthStore($ => $.profile);
  const selectedOrganization = useAuthStore($ => $.selectedOrganization);

  const role = useMemo(() => {
    if (profile?.role === 'CLIENT' || profile?.role === 'SUPER_ADMIN') {
      return profile?.role;
    } else {
      return selectedOrganization?.organization_user?.[0].role;
    }
  }, [profile, selectedOrganization]);

  const status = useMemo(() => {
    if (profile?.role === 'CLIENT' || profile?.role === 'SUPER_ADMIN') {
      return 'ACTIVE';
    } else {
      return selectedOrganization?.organization_user?.[0].status;
    }
  }, [profile, selectedOrganization]);

  const renderManageDropdown = useMemo(() => {
    return (
      <Popover className="relative flex items-center">
        {({ close }) => (
          <>
            <Popover.Button
              disabled={
                (role !== 'ADMIN' && role !== 'DESIGNER') ||
                status === 'INACTIVE'
              }
              className={classNames(
                'py-1.5 px-4 rounded-lg leading-6 flex focus:outline-none items-center disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-white',
                loc.pathname.startsWith('/manage')
                  ? 'bg-primary text-white font-semibold'
                  : 'hover:bg-light-gray-5',
                'ml-5'
              )}>
              <div>Manage</div>
              <ChevronDownIcon className="ml-2 w-4 h-4" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel
                className="bg-white rounded-lg border border-light-gray-3 shadow-lg absolute top-8 left-6 z-10 text-dark-gray-1"
                static>
                <div className="p-5 flex">
                  <Link
                    className="flex-1 flex w-[160px]"
                    to="/manage/templates"
                    onClick={close}>
                    <div>
                      <ClipboardDocumentCheckIcon className="text-green-500 w-5 h-5" />
                    </div>
                    <div className="ml-2 font-normal">
                      <div className="leading-6">Templates</div>
                      <div className="text-xs text-mid-gray-3">
                        Manage milestone templates.
                      </div>
                    </div>
                  </Link>
                  <div className="border-l mx-4" />
                  <Link
                    className={classNames(
                      'flex-1 flex w-[180px] user-select-none',
                      status === 'INACTIVE'
                        ? 'pointer-events-none opacity-50'
                        : ''
                    )}
                    to="/manage/organisation-info"
                    onClick={close}>
                    <div>
                      <BuildingOfficeIcon className="text-blue-500 w-5 h-5" />
                    </div>
                    <div className="ml-2 font-normal">
                      <div className="leading-6">Organisation Details</div>
                      <div className="text-xs text-mid-gray-3">
                        Manage details for your organisation.
                      </div>
                    </div>
                  </Link>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }, [loc, role, status]);

  const renderPeopleDropdown = useMemo(() => {
    return (
      <Popover className="relative flex items-center">
        {({ open, close }) => (
          <>
            <Popover.Button
              disabled={role !== 'ADMIN' && role !== 'DESIGNER'}
              className={classNames(
                'py-1.5 px-4 rounded-lg leading-6 flex focus:outline-none items-center disabled:cursor-not-allowed disabled:opacity-20 disabled:hover:bg-white',
                loc.pathname.startsWith('/people')
                  ? 'bg-primary text-white font-semibold'
                  : 'hover:bg-light-gray-5',
                'ml-5'
              )}>
              <div>People</div>
              <ChevronDownIcon className="ml-2 w-4 h-4" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel
                className="bg-white rounded-lg border border-light-gray-3 shadow-lg absolute top-8 left-6 z-10 text-dark-gray-1"
                static>
                <div className="p-5 flex">
                  <Link
                    className="flex-1 flex w-[160px]"
                    to="/people/staff"
                    onClick={close}>
                    <div>
                      <UserCircleIcon className="text-blue-500 w-5 h-5" />
                    </div>
                    <div className="ml-2 font-normal">
                      <div className="leading-6">Staff</div>
                      <div className="text-xs text-mid-gray-3">
                        {role === 'ADMIN'
                          ? 'Manage your designers and team members.'
                          : 'View staff list and other team members'}
                      </div>
                    </div>
                  </Link>
                  <div className="border-l mx-4" />
                  <Link
                    className={classNames(
                      'flex-1 flex w-[160px] user-select-none',
                      role !== 'ADMIN' || status === 'INACTIVE'
                        ? 'opacity-50 pointer-events-none'
                        : ''
                    )}
                    to="/people/clients"
                    onClick={close}>
                    <div>
                      <UsersIcon className="text-green-500 w-5 h-5" />
                    </div>
                    <div className="ml-2 font-normal">
                      <div className="leading-6">Clients</div>
                      <div className="text-xs text-mid-gray-3">
                        {role === 'ADMIN'
                          ? 'View clients and associated projects.'
                          : 'Client profiles only accessible to admins'}
                      </div>
                    </div>
                  </Link>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }, [loc, role, status]);

  const pages = [
    {
      name: 'Projects',
      link: '/projects',
      disabled: role === 'SUPER_ADMIN',
    },
    {
      name: 'People',
      link: '/people',
      actionNode: renderPeopleDropdown,
    },
    {
      name: 'Manage',
      link: '/manage',
      actionNode: renderManageDropdown,
      hidden: role !== 'ADMIN',
    },
    {
      name: 'Organisations',
      link: '/organizations',
      hidden: role !== 'SUPER_ADMIN',
    },
  ];

  return (
    <div>
      <div className="flex flex-start gap-2">
        {pages.map((page, i) =>
          page.hidden ? null : page.name === 'Manage' ? (
            <div key={page.name}>{renderManageDropdown}</div>
          ) : page.name === 'People' ? (
            <div key={page.name}>{renderPeopleDropdown}</div>
          ) : page.link && !page.disabled ? (
            <Link
              key={page.name}
              to={page.link}
              className={classNames(
                'py-1.5 px-4 rounded-lg leading-6 flex',
                loc.pathname.startsWith(page.link)
                  ? 'bg-primary text-white font-semibold'
                  : 'hover:bg-light-gray-5',
                i !== 0 ? 'ml-5' : ''
              )}>
              {page.name}
              {page.actionNode}
            </Link>
          ) : (
            <span
              key={page.name}
              className={classNames(
                'py-1.5 px-4 rounded-lg leading-6 select-none flex',
                i !== 0 ? 'ml-5' : '',
                page.disabled ? 'cursor-not-allowed opacity-20' : null
              )}>
              {page.name}
              {page.actionNode}
            </span>
          )
        )}
      </div>
    </div>
  );
};
export default Topbar;
