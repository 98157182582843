import { Dialog, DialogActions, DialogContent, Tooltip } from '@mui/material';

import classNames from 'classnames';
import { Field, FieldProps, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { supabase } from '_clients/supabaseClient';
import Autocomplete from '_components/autocomplete';
import Button from '_components/button';
import CoverImage from '_components/cover-image';
import Label from '_components/label';
import Spinner from '_components/spinner';
import useCountryList from '_hooks/useCountryList';
import useProject, { ProjectDetail } from '_hooks/useProjectBasicInfo';
import useProjectTypes from '_hooks/useProjectTypes';
import useAuthStore from '_stores/useAuthStore';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Status, Statuses } from '_types/common';
import { ProjectInsertArgs } from '_types/project';
import getErrorMessage from '_utils/getErrorMessage';
import { ProjectValidator } from '_validators/project-validator';

interface FormValues {
  address: {
    country: string;
    building_name?: string;
    unit?: string;
    block_no?: string;
    street: string;
    postal_code: string;
  };
  cost?: number;
  cover_image_url: string;
  handover_date: string;
  name: string;
  start_date: string;
  status: Status;
  type: number;
}

const projectToFormValues = (project: ProjectDetail): FormValues => {
  return {
    address: {
      country: project.address.country,
      building_name: project.address.building_name,
      unit: project.address.unit,
      block_no: project.address.block_no,
      street: project.address.street,
      postal_code: project.address.postal_code,
    },
    cost: project.cost ?? undefined,
    cover_image_url: project.cover_image_url ?? '',
    handover_date: project.handover_date ?? '',
    name: project.name,
    start_date: project.start_date ?? '',
    status: project.status,
    type: project.type,
  };
};

interface DateInput {
  showPicker: () => void;
}

export const CreateProject = observer(() => {
  const navigate = useNavigate();
  const snackBar = useSnackBarStore();

  const profile = useAuthStore($ => $.profile);
  const { data: projectTypes } = useProjectTypes();
  const { data: countryList } = useCountryList();

  const { id } = useParams<{ id: string }>();

  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  const [selectedImage, setSelectedImage] = useState<File>();
  const [isSaving, setIsSaving] = useState(false);
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const projectId = useMemo<number | null>(() => {
    const idAsNumber = Number(id);
    return !isNaN(idAsNumber) ? idAsNumber : null;
  }, [id]);

  const { data: project, isLoading } = useProject(projectId);

  const selectedOrganization = useAuthStore($ => $.selectedOrganization);
  const canPerformAction = useAuthStore($ => $.canPerformAction);

  // update breadcrumbs
  useEffect(() => {
    setItems([{ label: 'Projects', link: '/projects' }]);

    if (!projectId) {
      addItem({
        label: 'create',
        highlight: true,
      });
    } else if (project) {
      addItem({
        label: project.name,
        highlight: true,
      });
    }
  }, [addItem, project, projectId, setItems]);

  const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const insertProject = useCallback(
    async (project: ProjectInsertArgs, image?: File) => {
      if (!profile || !selectedImage) {
        return;
      }

      const timestamp = new Date().getTime();
      const filePath = `${timestamp}_${selectedImage.name}`;

      await supabase.storage.from('avatars').upload(filePath, selectedImage, {
        cacheControl: '3600',
        upsert: true,
      });

      const imageUrl = supabase.storage.from('avatars').getPublicUrl(filePath)
        .data.publicUrl;

      const { data, error } = await supabase.functions.invoke(
        'create-project',
        {
          body: {
            projectArgs: { ...project, cover_image_url: imageUrl },
          },
        }
      );

      if (error) {
        throw error;
      }

      return data;
    },
    [profile, selectedImage]
  );

  const updateProject = useCallback(
    async (projectToUpdate: ProjectInsertArgs) => {
      if (!profile || !projectId) {
        return;
      }

      const payloadBody = {
        projectArgs: {
          id: projectId,
          ...projectToUpdate,
        },
      };

      if (selectedImage) {
        const timestamp = new Date().getTime();
        const filePath = `${timestamp}_${selectedImage.name}`;

        await supabase.storage.from('avatars').upload(filePath, selectedImage, {
          cacheControl: '3600',
          upsert: true,
        });

        const imageUrl = supabase.storage.from('avatars').getPublicUrl(filePath)
          .data.publicUrl;

        payloadBody.projectArgs.cover_image_url = imageUrl;
      }

      const { error } = await supabase.functions.invoke('update-project', {
        body: payloadBody,
      });

      if (error) {
        throw error;
      }
    },
    [profile, projectId, selectedImage]
  );

  const onProjectSubmit = async (values: FormValues) => {
    if (!selectedOrganization || !values.type) {
      return;
    }
    try {
      setIsSaving(true);
      const payload = {
        ...values,
        organization_id: selectedOrganization.id,
      };

      if (!id) {
        await insertProject(payload);
      } else {
        await updateProject(payload);
      }
      snackBar.show('Project updated successfully', 'success');
      navigate('/projects');
    } catch (err) {
      snackBar.show(
        (await getErrorMessage(err)) ?? 'Project failed to update',
        'error'
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteProject = async () => {
    try {
      setIsDeleting(true);

      const { error } = await supabase.functions.invoke('delete-project', {
        body: {
          projectId: projectId,
        },
      });
      if (error) {
        const e = await error.context.json();
        if (e.error) {
          snackBar.show(e.error, 'error');
        } else {
          snackBar.show('Project failed to delete', 'error');
        }
        return;
      }
      snackBar.show('Project deleted successfully', 'success');
      navigate('/projects');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDialogClose = () => {
    setOpenDeleteProjectDialog(false);
  };

  const handleDialog = () => {
    setOpenDeleteProjectDialog(true);
  };

  const initialValues = useMemo(() => {
    if (project) {
      return projectToFormValues(project);
    }
    return {
      cover_image_url: '',
      status: Statuses.Active,
      type: -1,
      name: '',
      address: {
        country: 'Singapore',
        street: '',
        postal_code: '',
      },
      organization_id: 0,
      start_date: new Date().toISOString(),
      handover_date: new Date().toISOString(),
    };
  }, [project]);

  return (
    <div className="shadow-outer rounded-lg overflow-hidden mb-10 mt-12">
      <div className="p-5 border-b text-lg font-semibold">
        {id ? 'Edit Project' : 'Create a Project'}
      </div>
      {id && isLoading ? (
        <div className="bg-light-gray-5 py-10 flex justify-center">
          <Spinner className="w-8 h-8 z-15" />
        </div>
      ) : (
        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onProjectSubmit}
          validationSchema={ProjectValidator}
          validateOnBlur={false}>
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <div className="bg-light-gray-5 p-5">
                  <div className="relative flex items-center">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      Key Info
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>
                  <div className="mt-5 flex justify-start items-center">
                    <div className="w-32">
                      <Label>Image*</Label>
                    </div>
                    <div className="ml-8">
                      <CoverImage
                        handleRemove={() => {
                          setSelectedImage(undefined);
                          setFieldValue('cover_image_url', undefined);
                        }}
                        alt={project?.name}
                        src={
                          selectedImage
                            ? window.URL.createObjectURL(selectedImage)
                            : values.cover_image_url
                            ? values.cover_image_url
                            : ''
                        }
                        handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
                          if (!e.target.files) {
                            return;
                          }
                          handleUploadImage(e);
                          setFieldValue(
                            'cover_image_url',
                            e.target.files[0].name
                          );
                        }}
                        name="cover_image_url"
                        handleBlur={handleBlur}
                      />
                    </div>
                    {errors.cover_image_url && (
                      <span className="ml-6 text-red-500">
                        {errors.cover_image_url}
                      </span>
                    )}
                  </div>
                  <div className="mt-5 flex justify-start items-center">
                    <div className="w-32">
                      <Label>Status*</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        as="select"
                        id="CreateProject-status-select"
                        name="status"
                        autoComplete="status"
                        placeholder="Choose"
                        value={values.status}
                        onChange={handleChange}
                        className="w-32 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option
                          value="ACTIVE"
                          id="createProject-activeStatus-select">
                          Active
                        </option>
                        <option
                          value="INACTIVE"
                          id="createProject-inactiveStatus-select">
                          Archived
                        </option>
                      </Field>
                      {errors.status && (
                        <span className="ml-6 text-red-500">
                          {errors.status}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 flex">
                    <div className="w-32 pt-1.5">
                      <Label>Organisation*</Label>
                    </div>
                    <div className="ml-8">
                      <input
                        disabled
                        className="py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={selectedOrganization?.name ?? ''}
                      />
                    </div>
                  </div>
                  <div className="mt-5 flex">
                    <div className="w-32 pt-1.5">
                      <Label>Project Type*</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        as="select"
                        id="createProject-projectType-select"
                        name="type"
                        placeholder="Choose"
                        value={values.type}
                        onChange={handleChange}
                        className="block w-52 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option value={-1}>Select</option>
                        {projectTypes?.map((type, index) => {
                          return (
                            <option
                              value={type.id}
                              key={index}
                              id={`projecttype-${type.name}-filter`}>
                              {type.name}
                            </option>
                          );
                        })}
                      </Field>
                      {errors.type && touched.type && (
                        <div className="mt-2 text-red-500">{errors.type}</div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 flex">
                    <div className="w-32 pt-1.5">
                      <Label>Project Name*</Label>
                    </div>
                    <div className="ml-8 w-96">
                      <Field
                        name="name"
                        placeholder="eg: Orchard Road"
                        type="text"
                        autoComplete="name"
                        className="py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.name && touched.name && (
                        <div className="mt-2 text-red-500">{errors.name}</div>
                      )}
                    </div>
                  </div>

                  <div className="mt-5 flex">
                    <div className="w-32 pt-1.5">
                      <Label>Project Cost</Label>
                    </div>
                    <div className="ml-8 flex flex-start">
                      <Field name="cost">
                        {({ field }: FieldProps) => (
                          <input
                            type="number"
                            placeholder="eg: $118,000"
                            autoComplete="cost"
                            {...field}
                            value={field.value ?? ''}
                            className="block w-32 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        )}
                      </Field>
                      {errors.cost && touched.cost && (
                        <span className="ml-6 text-red-500">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="relative flex items-center mt-5">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      Address Details
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>

                  <div className="mt-5 flex justify-start items-center">
                    <div className="w-32">
                      <Label>Country*</Label>
                    </div>
                    <div className="ml-8">
                      <Autocomplete
                        value={values.address?.country ?? ''}
                        onChange={value => {
                          setFieldValue('address.country', value);
                        }}
                        options={
                          countryList?.map(country => ({
                            key: country.name ?? '',
                            name: country.name ?? '',
                          })) ?? []
                        }
                      />
                    </div>
                  </div>

                  <div className="mt-5 flex justify-start items-center">
                    <div className="w-32">
                      <Label>Building Name</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        name="address.building_name"
                        placeholder="eg: Orchard Condo"
                        id="createProject-buildingName-input"
                        type="text"
                        autoComplete="building_name"
                        className="w-64 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.address?.building_name &&
                        touched.address?.building_name && (
                          <span className="ml-6 text-red-500">
                            {errors.address.building_name}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="mt-5 flex justify-start items-center">
                    <div className="w-32">
                      <Label>Floor / Unit</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        id="createProject-unit-input"
                        name="address.unit"
                        placeholder="eg: #14-02"
                        type="text"
                        autoComplete="unit"
                        className="w-44	py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.address?.unit && touched.address?.unit && (
                        <span className="ml-6 text-red-500">
                          {errors.address.unit}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 flex">
                    <div className="w-32 pt-1.5">
                      <Label>Block/House No.*</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        id="createProject-houseNo-input"
                        name="address.block_no"
                        placeholder="eg: 21B"
                        type="text"
                        autoComplete="block_no"
                        className="w-44 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.address?.block_no &&
                        touched.address?.block_no && (
                          <div className="mt-1.5 text-red-500">
                            {errors.address.block_no}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="mt-5 flex">
                    <div className="w-32 pt-1.5">
                      <Label>Street*</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        id="createProject-street-input"
                        name="address.street"
                        placeholder="eg: Orchard Road"
                        type="text"
                        autoComplete="street"
                        className="w-96 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.address?.street && touched.address?.street && (
                        <div className="mt-1.5 text-red-500">
                          {errors.address.street}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-5 flex">
                    <div className="w-32 pt-1.5">
                      <Label>Postal Code*</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        id="createProject-postalCode-input"
                        name="address.postal_code"
                        placeholder="eg: 546080"
                        type="text"
                        autoComplete="postal_code"
                        className="w-32 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.address?.postal_code &&
                        touched.address?.postal_code && (
                          <div className="mt-1.5 text-red-500">
                            {errors.address.postal_code}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="relative flex items-center mt-5">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      Project Settings
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>

                  <div className="mt-5 flex justify-start items-center">
                    <div className="w-32">
                      <Label>Start Date*</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        id="start_date"
                        name="start_date"
                        type="date"
                        onClick={(e: MouseEvent<HTMLInputElement>) =>
                          (e.target as unknown as DateInput).showPicker()
                        }
                        value={
                          values.start_date &&
                          moment(values?.start_date).format('YYYY-MM-DD')
                        }
                        autoComplete="start_date"
                        className="w-64 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.start_date && touched.start_date && (
                        <span className="ml-6 text-red-500">
                          {errors.start_date}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="mt-5 flex justify-start items-center">
                    <div className="w-32">
                      <Label>Handover Date*</Label>
                    </div>
                    <div className="ml-8">
                      <Field
                        id="handover_date"
                        name="handover_date"
                        type="date"
                        onClick={(e: MouseEvent<HTMLInputElement>) =>
                          (e.target as unknown as DateInput).showPicker()
                        }
                        value={
                          values.handover_date &&
                          moment(values?.handover_date).format('YYYY-MM-DD')
                        }
                        autoComplete="handover_date"
                        className="w-64 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {errors.handover_date && touched.handover_date && (
                        <span className="ml-6 text-red-500">
                          {errors.handover_date}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    'border-t flex justify-end gap-x-3 p-5',
                    id ? 'justify-between' : 'justify-end'
                  )}>
                  {id && (
                    <Tooltip title="Delete project">
                      <Button color="red" onClick={handleDialog}>
                        Delete project
                      </Button>
                    </Tooltip>
                  )}
                  <div className="flex items-center gap-3">
                    <Button
                      color="secondary"
                      onClick={() => navigate('/projects')}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSaving || !canPerformAction()}
                      icon={
                        isSaving ? (
                          <Spinner className="-ml-1 mr-2 h-4 w-4" />
                        ) : null
                      }>
                      {isSaving ? 'Saving..' : 'Save'}
                    </Button>
                  </div>
                </div>
              </Form>
              <Dialog
                open={openDeleteProjectDialog}
                onClose={handleDialogClose}>
                <DialogContent>Confirm delete project?</DialogContent>
                <DialogActions>
                  <Button
                    color="secondary"
                    disabled={isDeleting}
                    onClick={handleDialogClose}>
                    No
                  </Button>
                  <Button onClick={handleDeleteProject} disabled={isDeleting}>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Formik>
      )}
    </div>
  );
});
