import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';
import { Project } from '_types/project';

export interface Address {
  country: string;
  building_name?: string;
  unit?: string;
  block_no?: string;
  street: string;
  postal_code: string;
}

export interface ProjectDetail extends Project {
  address: {
    country: string;
    building_name?: string;
    unit?: string;
    block_no?: string;
    street: string;
    postal_code: string;
  };
}

export const fetchProject = async (id: number): Promise<ProjectDetail> => {
  const selectedOrganization = useAuthStore.getState().selectedOrganization;
  if (!selectedOrganization) {
    throw new Error('Missing organization');
  }

  const { data: project, error } = await supabase
    .from('project')
    .select('*')
    .eq('id', id)
    .eq('organization_id', selectedOrganization.id)
    .single();

  if (error) {
    throw error;
  }

  const projectDetail: ProjectDetail = {
    ...project,
    address: project.address as unknown as Address,
  };

  return projectDetail;
};

const useProject = (id: number | null) =>
  useQuery(
    ['projectBasicInfo', id],
    () => {
      if (!id) {
        return null;
      }
      return fetchProject(id);
    },
    { enabled: !!id }
  );

export default useProject;
