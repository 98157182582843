import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon, PlusSmallIcon } from '@heroicons/react/24/solid';

import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import Button from '_components/button';
import FilterSelect from '_components/select';
import Spinner from '_components/spinner';
import useOrganisations, {
  OrganizationWithUsers,
} from '_hooks/useOrganizations';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import { Status, Statuses } from '_types/common';

import CreateOrganizationModal from './CreateOrganizationModal';
import useOrganizationStore from './useOrganizationStore';

const PAGE_SIZE = 5;

export const OrganizationHome = () => {
  const filters = useOrganizationStore($ => $.filters);
  const setFilters = useOrganizationStore($ => $.setFilters);
  const setIsCreateModalVisible = useOrganizationStore(
    $ => $.setIsCreateModalVisible
  );

  const setItems = useBreadCrumbsStore($ => $.setItems);
  const { data: organizations, isInitialLoading } = useOrganisations(filters);

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setItems([
      { label: 'Manage' },
      { label: 'Organisations', highlight: true },
    ]);
  }, [setItems]);

  const currentPageData = useMemo(() => {
    if (!organizations) return [];
    return organizations.slice(
      currentPage * PAGE_SIZE,
      (currentPage + 1) * PAGE_SIZE
    );
  }, [currentPage, organizations]);

  const pageCount = useMemo(() => {
    if (!organizations) {
      return 0;
    }
    return Math.ceil(organizations.length / PAGE_SIZE);
  }, [organizations]);

  const getDescription = useCallback((org: OrganizationWithUsers) => {
    let adminsCount = 0;
    let designersCount = 0;
    org.organization_user.forEach(user => {
      if (user.role === 'ADMIN') {
        adminsCount++;
      }
      if (user.role === 'DESIGNER') {
        designersCount++;
      }
    });
    return `${adminsCount} Admin${
      adminsCount === 1 ? '' : 's'
    }  ${designersCount} Designer${designersCount === 1 ? '' : 's'}`;
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center border-b pb-8 mt-12">
        <div className="font-semibold text-2xl">Organisations</div>
        <Button
          onClick={() => {
            setIsCreateModalVisible(true);
          }}>
          <PlusSmallIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Create Organization
        </Button>
      </div>
      <div className="mt-5 flex justify-between items-center">
        <div className="relative mt-1 rounded-md border w-72">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            name="search"
            id="search-input"
            onChange={e => {
              e.persist();
              setFilters({
                ...filters,
                keyword: e.target.value,
              });
              // reset page
              setCurrentPage(0);
            }}
            className="p-2 block w-full rounded-md border-gray-300 pl-10 outline-primary"
            placeholder="Search Name"
          />
        </div>
        <FilterSelect<Status | null>
          onChange={status => {
            setFilters({
              ...filters,
              status,
            });
          }}
          options={[
            {
              key: null,
              label: 'All',
            },
            {
              key: Statuses.Active,
              label: 'Active',
            },
            {
              key: Statuses.Inactive,
              label: 'InActive',
            },
          ]}
          label="Status"
        />
      </div>
      <div className="border border-light-gray-3 rounded-lg overflow-hidden mt-7">
        <table className="w-full">
          <thead className="bg-light-gray-5">
            <tr>
              <th className="text-mid-gray-2 font-normal py-3 px-4 w-[100px] text-left">
                Name
              </th>
              <th className="text-mid-gray-2 font-normal py-3 px-4 text-left">
                Created At
              </th>
              <th className="w-48 text-mid-gray-2 font-normal py-3 px-4 text-left">
                Status
              </th>
              <th className="w-20"></th>
            </tr>
          </thead>
          {isInitialLoading ? (
            <tbody>
              <tr className="border-t border-light-gray-3">
                <td colSpan={4} className="table-cell">
                  <div className="w-full py-10 flex justify-center">
                    <Spinner className="w-8 h-8 z-15" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {currentPageData.map(org => (
                <tr key={org.id} className="border-t border-light-gray-3">
                  <td className="p-4 w-1/2">
                    <div className="flex gap-2 items-center">
                      {org.cover_image_url ? (
                        <img
                          alt="cover"
                          src={org.cover_image_url}
                          className="w-[34px] h-[34px] object-center object-contain rounded-lg"
                        />
                      ) : (
                        <div className="w-[34px] h-[34px] bg-gray-200 rounded-full" />
                      )}
                      <div>
                        <Link
                          to={`/organizations/${org.id}`}
                          className="block font-medium">
                          {org.name}
                        </Link>
                        <div className="text-xs text-mid-gray-1 leading-6">
                          {getDescription(org)}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4">
                    {moment(org.created_at).format('DD MMM YYYY')}
                  </td>
                  <td className="px-4">
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          'w-2 h-2 rounded-full',
                          org.status === 'ACTIVE'
                            ? 'bg-green-500'
                            : 'bg-gray-400'
                        )}
                      />
                      <span className="ml-2 capitalize">
                        {org.status.toLowerCase()}
                      </span>
                    </div>
                  </td>
                  <td className="px-4">
                    <Link to={`/organizations/${org.id}`}>
                      <ArrowRightIcon className="w-6 h-6 text-light-gray-1" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ChevronRightIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          previousLabel={
            <ChevronLeftIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          className="flex items-center gap-1 p-4 justify-end border-t"
          pageClassName="py-1 px-2 leading-6"
          activeClassName="bg-light-gray-3 rounded"
          forcePage={currentPage}
          onPageChange={({ selected }) => {
            setCurrentPage(selected);
          }}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          disabledClassName="opacity-20 cursor-not-allowed"
        />
      </div>
      <CreateOrganizationModal />
    </div>
  );
};

export default OrganizationHome;
