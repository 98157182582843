import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Event } from 'stream-chat';
import { Chat } from 'stream-chat-react';

import chatClient from '_clients/chatClient';
import { supabase } from '_clients/supabaseClient';
import Spinner from '_components/spinner';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';

import { PrimarySearchAppBar } from './appbar';
import BreadCrumbs from './BreadCrumbs';
import useLayoutStore from './store';
import Topbar from './topbar';

const Layout = () => {
  const profile = useAuthStore($ => $.profile);
  const setUnreadCount = useAuthStore($ => $.setUnreadCount);

  const snackBar = useSnackBarStore();

  const isConnecting = useRef(false);
  const isConnected = useRef(false);

  const isBusy = useLayoutStore($ => $.isBusy);
  const busyMessage = useLayoutStore($ => $.busyMessage);

  const location = useLocation();

  const [continueAnyway, setContinueAnyway] = useState(false);

  const chatEventHandler = useCallback(
    (event: Event) => {
      if (event.me) {
        setUnreadCount(event.me.total_unread_count);
      }
      if (event.total_unread_count !== undefined) {
        setUnreadCount(event.total_unread_count);
      }
      if (event.message && event.message.text) {
        snackBar.show(`New message: ${event.message.text}`, 'info');
      }
    },
    [setUnreadCount, snackBar]
  );

  useEffect(() => {
    (async function () {
      if (!profile) {
        return;
      }

      chatClient.on(chatEventHandler);

      if (!isConnecting.current && !isConnected.current) {
        isConnecting.current = true;
        await chatClient.connectUser(
          {
            id: profile.id,
            name: `${profile.first_name} ${profile.last_name}`,
            image: profile.profile_image_url,
          },
          async () => {
            const { data, error } = await supabase.functions.invoke(
              'request-chat-token'
            );
            if (error) {
              throw error;
            }

            if (!data?.token) {
              throw new Error('Failed to get chat token');
            }

            return data.token;
          }
        );
        await chatClient.openConnection();
        isConnecting.current = false;
        isConnected.current = true;
      }
    })();

    return () => chatClient.off(chatEventHandler);
  }, [chatEventHandler, profile]);

  if (
    profile?.role !== 'CLIENT' &&
    window.innerWidth < 640 &&
    !continueAnyway &&
    location.pathname !== '/set-password' &&
    location.pathname !== '/reset-password'
  ) {
    return (
      <div>
        <div className="px-5 w-full sm:w-[27rem] mx-auto mt-32">
          <div className="text-2xl font-semibold text-center tracking-tight">
            Mobile Browser Detected
          </div>
          <div className="flex justify-center mt-1">
            <ExclamationTriangleIcon className="w-6 h-6" />
          </div>
          <p className="text-dark-gray-2 leading-6 bg-light-gray-5 p-4 mt-8 rounded-2xl shadow-[0_0_3px_rgba(176,186,191,0.5)]">
            Just a note - the Sunnyside Admin site is best optimised for a
            Desktop browsing experience!
            <br />
            <br />
            Need to check or manage your ID projects on the go?
            <br />
            Download the mobile app instead!
          </p>
          <div className="flex justify-center mt-8">
            <div
              className="text-blue-500 underline cursor-pointer"
              onClick={() => setContinueAnyway(true)}>
              Continue to login?
            </div>
          </div>
          <div className="mt-8">
            <h3 className="font-semibold text-center text-lg sm:text-2xl tracking-tight">
              Don't have the app yet? Get it here!
            </h3>
            <div className="flex justify-center gap-3 mt-6">
              <a
                href="https://apps.apple.com/vn/app/sunnyside-homes/id1666287931"
                target="_blank"
                rel="noreferrer">
                <img alt="App Store" src="/app-store-link.png" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.sunnysideapp"
                target="_blank"
                rel="noreferrer">
                <img alt="Play Store" src="/google-play-link.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isBusy) {
    return (
      <div className="h-screen bg-white opacity-75 flex justify-center items-center z-40">
        <div className="flex flex-col items-center">
          <Spinner className="w-12 h-12" />
          <div className="text-mid-gray-1 mt-2 font-medium">{busyMessage}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <Chat client={chatClient} theme="str-chat__theme-light">
        <PrimarySearchAppBar />
        <div className="max-w-[1440px] mx-auto w-full px-20 pt-28 relative">
          <Topbar />
          <div className="w-full border-t border-light-gray-3 mt-4 mb-4" />
          <BreadCrumbs />
          <Outlet />
        </div>
      </Chat>
    </div>
  );
};

export default Layout;
