import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { OrganizationUserWithDetails } from '_hooks/useOrganizationUser';

export interface OrganizationState {
  organizationUserWithDetails?: OrganizationUserWithDetails;
  isEditAccountModalVisible: boolean;
  isEditProfileModalVisible: boolean;
  isConfirmRemoveModalVisible: boolean;
  isResendInvitationModalVisible: boolean;
  isArchiveOrganizationUserModalVisible: boolean;
  invitationResent: boolean;

  setOrganizationUserWithDetails: (
    organizationUserWithDetails?: OrganizationUserWithDetails
  ) => void;

  setIsEditAccountModalVisible: (isEditAccountModalVisible: boolean) => void;
  setIsEditProfileModalVisible: (isEditAccountModalVisible: boolean) => void;
  setIsConfirmRemoveModalVisible: (
    isConfirmRemoveModalVisible: boolean
  ) => void;
  setIsResendInvitationModalVisible: (
    isResendInvitationModalVisible: boolean
  ) => void;
  setIsArchiveOrganizationUserModalVisible: (
    isArchiveOrganizationUserModalVisible: boolean
  ) => void;
  setInvitationResent: (invitationResent: boolean) => void;

  clearState: () => void;
}

const useOrganizationUserDetailStore = create<OrganizationState>()(
  immer((set, _get) => {
    return {
      isEditAccountModalVisible: false,
      isEditProfileModalVisible: false,
      isConfirmRemoveModalVisible: false,
      isResendInvitationModalVisible: false,
      isArchiveOrganizationUserModalVisible: false,
      invitationResent: false,

      setOrganizationUserWithDetails: (
        organizationUserWithDetails?: OrganizationUserWithDetails
      ) => {
        set({ organizationUserWithDetails });
      },

      setIsEditAccountModalVisible: (isEditAccountModalVisible: boolean) => {
        set({ isEditAccountModalVisible });
      },
      setIsEditProfileModalVisible: (isEditProfileModalVisible: boolean) => {
        set({ isEditProfileModalVisible });
      },
      setIsConfirmRemoveModalVisible: (
        isConfirmRemoveModalVisible: boolean
      ) => {
        set({ isConfirmRemoveModalVisible });
      },

      setIsResendInvitationModalVisible: (
        isResendInvitationModalVisible: boolean
      ) => {
        set({ isResendInvitationModalVisible });
      },

      setIsArchiveOrganizationUserModalVisible: (
        isArchiveOrganizationUserModalVisible: boolean
      ) => {
        set({ isArchiveOrganizationUserModalVisible });
      },

      setInvitationResent: (invitationResent: boolean) => {
        set({ invitationResent });
      },

      clearState: () => {
        set({
          organizationUserWithDetails: undefined,
          isEditAccountModalVisible: false,
          isEditProfileModalVisible: false,
          isConfirmRemoveModalVisible: false,
          isResendInvitationModalVisible: false,
          isArchiveOrganizationUserModalVisible: false,
          invitationResent: false,
        });
      },
    };
  })
);

export default useOrganizationUserDetailStore;
