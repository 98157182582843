import { Dialog, Transition } from '@headlessui/react';

import { Fragment, useCallback, useState } from 'react';

import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

import useOrganizationUserDetailStore from './store';

const ResendInvitationModal = () => {
  const snackBar = useSnackBarStore();

  const organizationUserWithProfile = useOrganizationUserDetailStore(
    $ => $.organizationUserWithDetails
  );
  const isResendInvitationModalVisible = useOrganizationUserDetailStore(
    $ => $.isResendInvitationModalVisible
  );
  const setIsResendInvitationModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsResendInvitationModalVisible
  );
  const setInvitationResent = useOrganizationUserDetailStore(
    $ => $.setInvitationResent
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleResendInvitation = useCallback(async () => {
    if (!organizationUserWithProfile) return;
    try {
      setIsSubmitting(true);
      const { error } = await supabase.functions.invoke(
        'resend-invitation-email',
        {
          body: {
            email: organizationUserWithProfile.profile.email,
          },
        }
      );
      if (error) {
        const errorMessage = await getErrorMessage(error);
        throw new Error(errorMessage);
      }
      snackBar.show('Invitation resent successfully', 'success');
      setIsResendInvitationModalVisible(false);
      setInvitationResent(true);
    } catch (err) {
      snackBar.show((err as Error).message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  }, [
    organizationUserWithProfile,
    setInvitationResent,
    setIsResendInvitationModalVisible,
    snackBar,
  ]);

  return (
    <Transition.Root appear show={isResendInvitationModalVisible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsResendInvitationModalVisible}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg relative">
                {isSubmitting ? (
                  <div className="absolute inset-0 bg-white opacity-70 z-20 flex justify-center items-center">
                    <Spinner className="w-8 h-8" />
                  </div>
                ) : null}
                <div>
                  <div className="font-semibold text-dark-gray-1 text-lg p-5">
                    Invite User
                  </div>
                  <div className="px-5 py-7 bg-light-gray-5 border-t border-light-gray-3">
                    <div className="relative flex items-center">
                      <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                        Role
                      </span>
                      <div className="flex-grow border-t border-light-gray-3 ml-3" />
                    </div>
                    <div className="flex items-center mt-4">
                      <Label className="w-24">User Role</Label>
                      <div>
                        <select
                          name="role"
                          placeholder="Role"
                          disabled
                          value={organizationUserWithProfile?.role}
                          className="block w-32 ml-4 py-1.5 px-2.5 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-light-gray-3">
                          <option value="CLIENT">Client</option>
                          <option value="DESIGNER">Designer</option>
                          <option value="ADMIN">Admin</option>
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-center mt-5">
                      <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                        User Details
                      </span>
                      <div className="flex-grow border-t border-light-gray-3 ml-3" />
                    </div>

                    <div className="flex flex-start mt-5">
                      <Label className="w-24 mt-1">First Name*</Label>
                      <div className="ml-4">
                        <input
                          name="firstName"
                          type="text"
                          title="First Name"
                          placeholder="First Name"
                          disabled
                          value={
                            organizationUserWithProfile?.profile.first_name ??
                            ''
                          }
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-light-gray-3"
                        />
                      </div>
                    </div>
                    <div className="flex flex-start mt-5">
                      <Label className="w-24 mt-1">Last Name*</Label>
                      <div className="ml-4">
                        <input
                          name="lastName"
                          type="text"
                          title="Last Name"
                          placeholder="Last Name"
                          disabled
                          value={
                            organizationUserWithProfile?.profile.last_name ?? ''
                          }
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-light-gray-3"
                        />
                      </div>
                    </div>

                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Email*</Label>
                      <div className="ml-4">
                        <input
                          name="email"
                          title="Email"
                          placeholder="e.g. john.tan@company.com"
                          autoComplete="email"
                          disabled
                          value={
                            organizationUserWithProfile?.profile.email ?? ''
                          }
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-light-gray-3"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                    <Button
                      color="secondary"
                      onClick={() => setIsResendInvitationModalVisible(false)}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      onClick={handleResendInvitation}>
                      Resend Invite
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ResendInvitationModal;
