import { Dialog, Transition } from '@headlessui/react';

import { useFormik } from 'formik';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';
import { Profile, Role } from '_types/profile';

import useOrganizationDetailStore from './store';

interface FormValues {
  email: string;
  role: Role;
  firstName: string;
  lastName: string;
}

const InviteUserModal = ({ organizationId }: { organizationId: number }) => {
  const isInviteUserModalVisible = useOrganizationDetailStore(
    $ => $.isInviteUserModalVisible
  );
  const setIsInviteUserModalVisible = useOrganizationDetailStore(
    $ => $.setIsInviteUserModalVisible
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [existingUser, setExistingUser] = useState<Profile | null>(null);
  const [formState, setFormState] = useState<'new' | 'searched'>('new');
  const [errorMessage, setErrorMessage] = useState<string>();

  const isInvitingNewUser = useMemo(() => {
    return formState === 'searched' && !existingUser;
  }, [formState, existingUser]);

  const OrganizationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .trim()
          .email('please enter a valid email')
          .label('Email')
          .required('Email is required'),
        firstName: isInvitingNewUser
          ? yup.string().trim().required('First name is required')
          : yup.mixed(),
        lastName: isInvitingNewUser
          ? yup.string().trim().required('Fast name is required')
          : yup.mixed(),
      }),
    [isInvitingNewUser]
  );

  const handleSearchUser = useCallback(async (email: string) => {
    setIsSearching(true);
    try {
      const { data: profile, error } = await supabase
        .from('profile')
        .select()
        .eq('email', email)
        .maybeSingle();
      if (error) {
        throw error;
      }

      if (!profile) {
        setFormState('searched');
        return;
      }

      if (profile?.role === 'CLIENT') {
        setErrorMessage(
          `* Error: User already exists in Sunnyside as a Client - Client email addresses cannot be added to Sunnyside as an Admin / Designer.`
        );
        // need to search again
        setFormState('new');
        return;
      }

      setExistingUser(profile);
      setFormState('searched');
    } catch (err) {
      console.log(err);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      role: 'ADMIN',
      email: existingUser?.email ?? '',
      firstName: existingUser?.first_name ?? '',
      lastName: existingUser?.last_name ?? '',
    },
    validationSchema: OrganizationSchema,
    onSubmit: async values => {
      try {
        setIsSubmitting(true);
        if (formState === 'new') {
          await handleSearchUser(values.email);
          return;
        }

        const { error: inviteError } = await supabase.functions.invoke(
          'invite-user-by-email',
          {
            body: {
              email: values.email,
              role: values.role,
              firstName: values.firstName,
              lastName: values.lastName,
              organizationId: organizationId,
            },
          }
        );
        if (inviteError) {
          const e = await inviteError.context.json();
          if (e.error?.includes('organization_id_user_id_unique')) {
            setErrorMessage(
              `${values.email} already belong to this organization`
            );
          }
          throw inviteError;
        }
        reset();
        queryClient.invalidateQueries({ queryKey: ['organization'] });
        setIsInviteUserModalVisible(false);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const reset = useCallback(() => {
    formik.resetForm();
    setFormState('new');
    setExistingUser(null);
    setErrorMessage(undefined);
  }, [formik]);

  const handleCancel = () => {
    setIsInviteUserModalVisible(false);
    reset();
  };

  useEffect(() => {
    setFormState('new');
    setExistingUser(null);
    setErrorMessage(undefined);
  }, [formik.values.email]);

  return (
    <Transition.Root
      appear={isInviteUserModalVisible}
      show={isInviteUserModalVisible}
      as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setIsInviteUserModalVisible}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg relative">
                {isSubmitting ? (
                  <div className="absolute inset-0 bg-white opacity-70 z-20 flex justify-center items-center">
                    <Spinner className="w-8 h-8" />
                  </div>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  <div className="font-semibold text-dark-gray-1 text-lg p-5">
                    Invite User
                  </div>
                  <div className="px-5 py-7 bg-light-gray-5 border-t border-light-gray-3">
                    <div className="relative flex items-center">
                      <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                        Role
                      </span>
                      <div className="flex-grow border-t border-light-gray-3 ml-3" />
                    </div>
                    <div className="flex items-center mt-4">
                      <Label className="w-24">User Role</Label>
                      <div>
                        <select
                          name="role"
                          placeholder="Role"
                          disabled={isSearching || isSubmitting}
                          onChange={formik.handleChange}
                          value={formik.values.role}
                          onBlur={formik.handleBlur}
                          className="block w-32 ml-4 py-1.5 px-2.5 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                          <option value="DESIGNER">Designer</option>
                          <option value="ADMIN">Admin</option>
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-center mt-5">
                      <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                        User Details
                      </span>
                      <div className="flex-grow border-t border-light-gray-3 ml-3" />
                    </div>
                    {formState === 'searched' ? (
                      <>
                        <div className="flex flex-start mt-5">
                          <Label className="w-24 mt-1">First Name*</Label>
                          <div className="ml-4">
                            <input
                              name="firstName"
                              type="text"
                              title="First Name"
                              placeholder="First Name"
                              disabled={!!existingUser}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-light-gray-3"
                            />
                            {formik.errors.firstName &&
                            formik.touched.firstName ? (
                              <div className="mt-1 text-red-400">
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex flex-start mt-5">
                          <Label className="w-24 mt-1">Last Name*</Label>
                          <div className="ml-4">
                            <input
                              name="lastName"
                              type="text"
                              title="Last Name"
                              placeholder="Last Name"
                              disabled={!!existingUser}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastName}
                              className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-light-gray-3"
                            />
                            {formik.errors.lastName &&
                            formik.touched.lastName ? (
                              <div className="mt-1 text-red-400">
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Email*</Label>
                      <div className="ml-4">
                        <input
                          name="email"
                          title="Email"
                          placeholder="e.g. john.tan@company.com"
                          autoComplete="email"
                          disabled={
                            isSearching || isSubmitting || !!existingUser
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className="w-64 py-1.5 px-2.5 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-light-gray-3"
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <div className="mt-1 text-red-400">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {errorMessage ? (
                      <div className="mt-1 text-red-400">{errorMessage}</div>
                    ) : null}
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                    <Button color="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isSearching || isSubmitting}
                      type="submit">
                      {formState === 'searched' ? 'Invite' : 'Search'}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InviteUserModal;
