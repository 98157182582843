import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';

export const fetchProjectTypes = async () => {
  const organization = useAuthStore.getState().selectedOrganization;
  if (!organization) {
    return [];
  }

  const { data: projectTypes, error } = await supabase
    .from('project_type')
    .select('*')
    .order('sequence', {
      ascending: true,
    })
    .order('name', {
      ascending: true,
    })
    .eq('organization_id', organization.id);

  if (error) {
    throw error;
  }

  if (!projectTypes) {
    throw new Error('No project type found');
  }

  return projectTypes;
};

const useProjectTypes = () =>
  useQuery(['projectTypes'], () => fetchProjectTypes());

export default useProjectTypes;
