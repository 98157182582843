import { useState } from 'react';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import ConfirmationDialog from '_components/ConfirmationDialog';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

import useOrganizationDetailStore from './store';

export const ConfirmArchiveOrganizationModal = () => {
  const snackBar = useSnackBarStore();

  const organizationWithUsers = useOrganizationDetailStore(
    $ => $.organizationWithUsers
  );

  const isArchiveOrganizationModalVisible = useOrganizationDetailStore(
    $ => $.isArchiveOrganizationModalVisible
  );
  const setIsArchiveOrganizationModalVisible = useOrganizationDetailStore(
    $ => $.setIsArchiveOrganizationModalVisible
  );

  const [isBusy, setIsBusy] = useState(false);

  const handleConfirm = async () => {
    if (!organizationWithUsers) return;
    try {
      setIsBusy(true);
      const { error } = await supabase.functions.invoke(
        'update-organization-status',
        {
          body: {
            organizationId: organizationWithUsers.id,
            status: 'INACTIVE',
          },
        }
      );
      if (error) {
        throw error;
      }
      setIsArchiveOrganizationModalVisible(false);
      queryClient.invalidateQueries(['organization']);
      snackBar.show('Organization archived successfully', 'success');
    } catch (err) {
      const errorMessage = await getErrorMessage(err);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <ConfirmationDialog
      isVisible={isArchiveOrganizationModalVisible}
      title="Confirm Archive"
      isBusy={isBusy}
      content={
        <div>
          <div>Are you sure you want to archive this organisation?</div>
          <div className="mt-2">
            No new projects will be able to be created and existing projects
            will not be modifiable. The organisation will also no longer be able
            to manage their users until the organisation is unarchived.
          </div>
          <div className="mt-2">
            This will disrupt usage for the affected organisation
          </div>
        </div>
      }
      onClose={() => setIsArchiveOrganizationModalVisible(false)}
      onConfirm={handleConfirm}
      cancelLabel="No"
      confirmLabel="Yes"
    />
  );
};

export default ConfirmArchiveOrganizationModal;
