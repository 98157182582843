import { Dialog, Transition } from '@headlessui/react';

import { Field, Form, Formik } from 'formik';
import { Fragment } from 'react';

import Button from '_components/button';
import Label from '_components/label';
import {
  getImageFileOrDefault,
  MilestoneImageOrFile,
} from '_models/milestone/milestone-upload';
import { RenameFileValidator } from '_validators/rename-file-validator';

export const RenameFile = (props: {
  handleEditNameClose: () => void;
  openEditName: boolean;
  onEditNameSave: (renameForm: MilestoneImageOrFile) => void;
  milestoneFileData?: MilestoneImageOrFile;
}) => {
  const {
    handleEditNameClose,
    openEditName,
    onEditNameSave,
    milestoneFileData,
  } = props;
  return (
    <Transition.Root show={openEditName} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleEditNameClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <div className="p-5 border-b text-lg font-semibold">
                  Edit File
                </div>
                <div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={getImageFileOrDefault(milestoneFileData)}
                    onSubmit={onEditNameSave}
                    validationSchema={RenameFileValidator}>
                    {({ errors, touched }) => {
                      return (
                        <Form>
                          <div className="flex justify-start items-center p-4 bg-light-gray-5">
                            <Label className="w-32">File name</Label>
                            <Field
                              id="projectMilestone-rename-input"
                              name="name"
                              type="text"
                              title="Filename"
                              autoComplete="name"
                              placeholder="File Name"
                              className="block w-96 ml-8 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            {errors.name && touched.name && (
                              <span className="ml-6 text-red-500">
                                {errors.name}
                              </span>
                            )}
                          </div>
                          <div className="border-t flex justify-end p-5">
                            <Button
                              color="secondary"
                              onClick={handleEditNameClose}>
                              Cancel
                            </Button>
                            <Button type="submit">Save</Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
