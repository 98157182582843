import { UserPlusIcon } from '@heroicons/react/24/solid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import validator from 'validator';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import { DisplayErrors } from '_components/display-errors';
import { ProjectStore } from '_store/project-store';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Role } from '_types/profile';

import { AddUser } from './add-user';
import { InviteUser } from './invite-user';
import { ProjectUsersList } from './project-users-list';

export const ProjectTeam = observer(() => {
  const snackBar = useSnackBarStore();

  const [open, setOpen] = useState(false);
  const [inviteUserOpen, setInviteUserOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [role, setRole] = useState<Role>('DESIGNER');
  const [query, setQuery] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMobile, setIsMobile] = useState(true);
  const [phone, setPhone] = useState('');
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openAddUserMenu = Boolean(anchorEl);

  const { resetUser, users, searchUserBy, errors } = ProjectStore;

  const canPerformAction = useAuthStore($ => $.canPerformAction);

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUser = (isMobileField: boolean) => {
    setIsMobile(isMobileField);
    setButtonDisabled(true);
    setOpen(true);
    handleClose();
    resetUser();
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserBy = () => {
    setIsMobile(!isMobile);
  };

  const handleUserClose = () => {
    setOpen(false);
  };

  const handleInviteUser = () => {
    setOpenDialog(false);
    setInviteUserOpen(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRole(e.target.value as Role);
  };

  const handleQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const val = isMobile
      ? e.target.value.replace('+', ' ').replace(/ /g, '')
      : e.target.value;
    setQuery(val);
    const value = isMobile
      ? validator.isMobilePhone(val, 'en-SG')
      : validator.isEmail(val);
    if (value) {
      setButtonDisabled(false);
      setErrorMessage('');
    } else {
      setButtonDisabled(true);
      setErrorMessage('Please enter a valid email/phone');
    }
  };

  const handleSave = async () => {
    if (!users || !id) return;
    try {
      setButtonDisabled(true);
      const { error: inviteError } = await supabase.functions.invoke(
        'invite-user-to-project',
        {
          body: {
            email: users[0].email,
            role: users[0].role,
            projectId: id,
          },
        }
      );
      if (inviteError) {
        throw inviteError;
      }

      if (users[0].role !== 'CLIENT') {
        snackBar.show('User is added successfully', 'success');
      } else {
        snackBar.show(
          'This client will be added to the project after they accept the project invitation in their app.',
          'success',
          'Invite Sent'
        );
      }

      setOpen(false);
      queryClient.invalidateQueries(['projectUsers']);
      queryClient.invalidateQueries(['projectUserInvitations']);
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleSearch = async () => {
    const colName = isMobile ? 'mobile_number' : 'email';
    const result = await searchUserBy([
      {
        column: colName,
        value: isMobile ? `65${query}` : query.toLowerCase(),
        operand: 'eq',
      },
    ]);
    if (result === null) {
      setOpen(false);
      return;
    }
    if (result) {
      setOpen(false);
      return;
    }
    if (result === false && users?.length === 0) {
      setOpen(false);
      setOpenDialog(true);
    }
  };

  return (
    <>
      {errors && errors.length > 0 && <DisplayErrors errors={errors} />}
      <div className="border border-light-gray-3 rounded-xl overflow-hidden">
        <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
          <div className="font-semibold">Team</div>
          <div>
            <Button
              disabled={!canPerformAction()}
              color="secondary"
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                handleUserMenu(event)
              }>
              <UserPlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Add User
            </Button>
            <Menu
              id="add-user-menu"
              anchorEl={anchorEl}
              open={openAddUserMenu}
              onClose={handleClose}>
              <MenuItem onClick={() => handleUser(true)}>
                Add via Mobile
              </MenuItem>
              <MenuItem onClick={() => handleUser(false)}>
                Add via Email
              </MenuItem>
            </Menu>
          </div>
        </div>
        <Divider />
        <ProjectUsersList />
        <AddUser
          open={open}
          role={role}
          handleUserClose={handleUserClose}
          handleRoleChange={handleRoleChange}
          handleSave={handleSave}
          handleSearch={handleSearch}
          handleQuery={handleQuery}
          buttonDisabled={buttonDisabled}
          errorMessage={errorMessage}
          isMobile={isMobile}
          handleUserBy={handleUserBy}
          phone={phone}
          handlePhoneChange={handlePhoneChange}
        />
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogContent>
            User does not exist. Do you want to invite?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              No
            </Button>
            <Button onClick={handleInviteUser}>Yes</Button>
          </DialogActions>
        </Dialog>
        <InviteUser
          open={inviteUserOpen}
          role={role}
          query={query}
          projectId={id ? Number(id) : undefined}
          isMobile={isMobile}
          phone={phone}
          onClose={() => setInviteUserOpen(false)}
        />
      </div>
    </>
  );
});
