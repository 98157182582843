import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { supabase } from '_clients/supabaseClient';
import ConfirmationDialog from '_components/ConfirmationDialog';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

import useOrganizationUserDetailStore from './store';

export const ConfirmRemoveUserModal = () => {
  const params = useParams<{ organizationId: string; userId: string }>();

  const snackBar = useSnackBarStore();
  const navigate = useNavigate();

  const organizationUserWithProfile = useOrganizationUserDetailStore(
    $ => $.organizationUserWithDetails
  );

  const isConfirmRemoveModalVisible = useOrganizationUserDetailStore(
    $ => $.isConfirmRemoveModalVisible
  );
  const setIsConfirmRemoveModalVisible = useOrganizationUserDetailStore(
    $ => $.setIsConfirmRemoveModalVisible
  );

  const [isBusy, setIsBusy] = useState(false);

  const handleConfirmRemove = async () => {
    if (!organizationUserWithProfile) return;
    try {
      setIsBusy(true);
      const { error } = await supabase.functions.invoke(
        'remove-user-from-organization',
        {
          body: {
            userId: organizationUserWithProfile.user_id,
            organizationId: organizationUserWithProfile.organization_id,
          },
        }
      );
      if (error) {
        const errorMessage = await getErrorMessage(error);
        snackBar.show(errorMessage, 'error');
        throw error;
      }
      snackBar.show('User removed successfully', 'success');
      if (params.organizationId) {
        navigate(
          `/organizations/${organizationUserWithProfile.organization_id}`
        );
      } else {
        navigate('/people/staff');
      }
      setIsConfirmRemoveModalVisible(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsBusy(false);
    }
  };

  if (!isConfirmRemoveModalVisible) {
    return null;
  }

  return (
    <ConfirmationDialog
      isVisible={isConfirmRemoveModalVisible}
      title="Confirm Removal"
      isBusy={isBusy}
      content={
        <div>
          <div>
            Are you sure you want to remove this user? They will be removed and
            lose access to any projects and chats they were previously
            associated with.
          </div>
          <div className="mt-2">
            Even if the user is reinvited to the organisation, they will not
            regain access to these unless they are re-added to the projects and
            chats.
          </div>
        </div>
      }
      onClose={() => setIsConfirmRemoveModalVisible(false)}
      onConfirm={handleConfirmRemove}
      confirmWaitTimeAsSeconds={10}
    />
  );
};

export default ConfirmRemoveUserModal;
