export enum ProjectUpdateTypes {
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_UPDATED = 'PROJECT_UPDATED',
  PROJECT_USER_INVITED = 'PROJECT_USER_INVITED',
  PROJECT_USER_ADDED = 'PROJECT_USER_ADDED',
  PROJECT_USER_REMOVED = 'PROJECT_USER_REMOVED',
  PROJECT_ARCHIVED = 'PROJECT_ARCHIVED',
  PROJECT_ACTIVE = 'PROJECT_ACTIVE',

  MILESTONE_NOTE_UPDATED = 'MILESTONE_NOTE_UPDATED',
  MILESTONE_FILE_UPLOADED = 'MILESTONE_FILE_UPLOADED',
  MILESTONE_IMAGE_UPLOADED = 'MILESTONE_IMAGE_UPLOADED',
  COMMENT = 'COMMENT',
  MILESTONE_FILE_RENAMED = 'MILESTONE_FILE_RENAMED',
  MILESTONE_FILE_DELETED = 'MILESTONE_FILE_DELETED',
  MILESTONE_IMAGE_DELETED = 'MILESTONE_IMAGE_DELETED',
  CHECKLIST_ITEM_ADDED = 'CHECKLIST_ITEM_ADDED',
  CHECKLIST_ITEM_EDITED = 'CHECKLIST_ITEM_EDITED',
  CHECKLIST_ITEM_DELETED = 'CHECKLIST_ITEM_DELETED',
  CHECKLIST_ITEM_CHECKED = 'CHECKLIST_ITEM_CHECKED',
  CHECKLIST_ITEM_UNCHECKED = 'CHECKLIST_ITEM_UNCHECKED',
  MILESTONE_CHECKED = 'MILESTONE_CHECKED',
  MILESTONE_UNCHECKED = 'MILESTONE_UNCHECKED',
  STAGE_CHECKED = 'STAGE_CHECKED',
}
