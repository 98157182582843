import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  ArrowUpTrayIcon,
} from '@heroicons/react/20/solid';

import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import Carousel from 'react-elastic-carousel';

import { Milestone } from '_models/milestone/milestone';
import { MilestoneImageOrFile } from '_models/milestone/milestone-upload';
import useAuthStore from '_stores/useAuthStore';

const { REACT_APP_SUPABASE_URL } = process.env;
const STORAGE_BUCKET_URL = `${REACT_APP_SUPABASE_URL}/storage/v1/object/public`;
const STORAGE_BUCKET_KEY_AVATARS = 'project';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 7 },
  { width: 768, itemsToShow: 9 },
  { width: 1200, itemsToShow: 9 },
];

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const MilestoneCarousel = observer(
  (props: {
    data: Milestone;
    handleUploadImage: (e: any) => void;
    handleClickOpen: (item: MilestoneImageOrFile, isImage: boolean) => void;
  }) => {
    const { data, handleUploadImage, handleClickOpen } = props;

    const canPerformAction = useAuthStore($ => $.canPerformAction);

    return (
      <div>
        {data && (
          <div className="mt-5">
            {/* @ts-ignore */}
            <Carousel
              key={data.id}
              breakPoints={breakPoints}
              disableArrowsOnEnd={true}
              renderArrow={({ type, onClick, isEdge }) => {
                if (isEdge) {
                  return <div className="hidden" />;
                }
                return (
                  <div className="h-full flex items-center disabled:opacity-30">
                    {type === 'PREV' ? (
                      <ArrowLeftCircleIcon
                        className="w-10 h-10 text-light-gray-1 cursor-pointer"
                        onClick={onClick}
                      />
                    ) : (
                      <ArrowRightCircleIcon
                        className="w-10 h-10 text-light-gray-1 cursor-pointer"
                        onClick={onClick}
                      />
                    )}
                  </div>
                );
              }}>
              {(data.project_milestone_image || [])
                .slice()
                .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
                .map((item: MilestoneImageOrFile, index: number) => {
                  const imageUrl = `${STORAGE_BUCKET_URL}/${STORAGE_BUCKET_KEY_AVATARS}/${item.file_url}`;
                  return (
                    <img
                      key={index}
                      className="w-[100px] h-[70px] rounded-md object-cover object-center cursor-pointer"
                      src={imageUrl}
                      alt={item.name}
                      onClick={() => handleClickOpen(item, true)}
                    />
                  );
                })}
              {canPerformAction() ? (
                <label className="w-[100px] h-[70px] border border-dashed rounded flex items-center justify-center bg-light-gray-5 cursor-pointer">
                  <input
                    id="outlined-button-file1"
                    type="file"
                    accept="image/*"
                    onChange={handleUploadImage}
                    name="image_url"
                    className="hidden"
                  />
                  <ArrowUpTrayIcon className="w-5 h-5 text-mid-gray-5" />
                </label>
              ) : null}
            </Carousel>
          </div>
        )}
      </div>
    );
  }
);
