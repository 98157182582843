import { Database } from './database.types';

export type Status = Database['public']['Enums']['status'];

export const Statuses = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
} as const;

export type MilestoneStatus = Database['public']['Enums']['milestonestatus'];

export const MilestoneStatuses = {
  Incomplete: 'INCOMPLETE',
  Complete: 'COMPLETE',
  Archived: 'ARCHIVED',
} as const;

export type ChecklistStatus = Database['public']['Enums']['checkliststatus'];

export const ChecklistStatuses = {
  Incomplete: 'INCOMPLETE',
  Complete: 'COMPLETE',
} as const;

export type Comment = {
  comment: string;
  user_id: string;
  timestamp: string;
}