import { useCallback } from 'react';
import { Channel, SendFileAPIResponse } from 'stream-chat';
import {
  ImageUpload,
  MessageInput,
  useChannelStateContext,
} from 'stream-chat-react';

import { supabase } from '_clients/supabaseClient';

export const CustomMessageInput = () => {
  const { channel } = useChannelStateContext();

  const handleUploadRequest = useCallback(
    async (
      file: ImageUpload['file'],
      channel: Channel
    ): Promise<SendFileAPIResponse> => {
      const { data: projectChat, error: projectChatError } = await supabase
        .from('project_chat')
        .select('*')
        .eq('chat_id', channel.id)
        .single();
      if (projectChatError) {
        throw projectChatError;
      }

      const isImage = file.type?.startsWith('image/');

      const filePath = `${projectChat.project_id}/chat/${
        isImage ? 'images' : 'files'
      }/${file.name}`;

      const { error: uploadError } = await supabase.storage
        .from('project')
        .upload(filePath, file as File, {
          upsert: true,
        });

      if (uploadError) {
        throw uploadError;
      }

      const { data } = supabase.storage.from('project').getPublicUrl(filePath);

      return {
        duration: '',
        file: data.publicUrl ?? '',
      };
    },
    []
  );

  const input = channel.data?.frozen ? (
    <div className="chatchannel_archived_label"> THIS PROJECT IS ARCHIVED</div>
  ) : (
    <MessageInput
      doFileUploadRequest={handleUploadRequest}
      doImageUploadRequest={handleUploadRequest}
    />
  );

  return <>{input}</>;
};
