import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import { Profile } from '_types/profile';
import { ProjectUser } from '_types/projectUser';

export type ProjectUserWithProfile = ProjectUser & {
  profile: Profile;
};

export const fetchProjectUsers = async (projectId?: string) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }

  const { data, error } = await supabase
    .from('project_user')
    .select('*, profile(*)')
    .eq('project_id', projectId);
  if (error) {
    throw error;
  }

  return data as ProjectUserWithProfile[];
};

const useProjectUsers = (projectId?: string) =>
  useQuery(['projectUsers', projectId], () => fetchProjectUsers(projectId), {
    enabled: !!projectId,
  });

export default useProjectUsers;
