import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import {
  ArchiveBoxIcon,
  MagnifyingGlassIcon,
  PlusSmallIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import ConfirmationDialog from '_components/ConfirmationDialog';
import Select from '_components/select';
import Spinner from '_components/spinner';
import useOrganization from '_hooks/useOrganization';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Status, Statuses } from '_types/common';
import getErrorMessage from '_utils/getErrorMessage';

import ConfirmArchiveOrganizationModal from './ConfirmArchiveOrganizationModal';
import InviteUserModal from './InviteUserModal';
import useOrganizationDetailStore from './store';

const PAGE_SIZE = 6;

export const OrganizationDetail = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [filterStatus, setFilterStatus] = useState<Status | null>(null);

  const { data: organization, isInitialLoading } = useOrganization(Number(id));
  const navigate = useNavigate();
  const snackBar = useSnackBarStore();
  const setItems = useBreadCrumbsStore($ => $.setItems);
  const addItem = useBreadCrumbsStore($ => $.addItem);

  const setIsInviteUserModalVisible = useOrganizationDetailStore(
    $ => $.setIsInviteUserModalVisible
  );

  const setIsArchiveOrganizationModalVisible = useOrganizationDetailStore(
    $ => $.setIsArchiveOrganizationModalVisible
  );

  const setOrganizationWithUsers = useOrganizationDetailStore(
    $ => $.setOrganizationWithUsers
  );

  // update breadcrumbs
  useEffect(() => {
    setItems([
      { label: 'Manage' },
      { label: 'Organisations', link: '/organizations' },
    ]);

    if (!organization) {
      return;
    }
    addItem({
      label: organization?.name ?? '',
      highlight: true,
    });
  }, [addItem, id, organization, setItems]);

  useEffect(() => {
    setOrganizationWithUsers(organization);
  }, [organization, setOrganizationWithUsers]);

  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] =
    useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const handleClickDeleteButton = async () => {
    setIsConfirmDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsConfirmDeleteModalVisible(false);
      setIsBusy(true);
      const { error } = await supabase
        .from('organization')
        .delete()
        .eq('id', id);
      if (error) {
        throw error;
      }

      snackBar.show('Organization deleted successfully', 'success');
      navigate('/organizations');
    } catch (error) {
      const errorMessage = await getErrorMessage(error);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  };

  const filteredOrganizationUsers = useMemo(() => {
    if (!organization) return [];
    return organization.organization_user.filter(user => {
      if (filterStatus && user.status !== filterStatus) {
        return false;
      }

      if (searchString) {
        return (
          user.profile.first_name
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          user.profile.last_name
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          user.profile.email?.toLowerCase().includes(searchString.toLowerCase())
        );
      }

      return true;
    });
  }, [filterStatus, organization, searchString]);

  const currentPageData = useMemo(
    () =>
      orderBy(filteredOrganizationUsers, ['created_at'], ['desc']).slice(
        currentPage * PAGE_SIZE,
        (currentPage + 1) * PAGE_SIZE
      ),
    [currentPage, filteredOrganizationUsers]
  );

  const pageCount = useMemo(
    () => Math.ceil(filteredOrganizationUsers.length / PAGE_SIZE),
    [filteredOrganizationUsers]
  );

  const unarchiveOrganization = async () => {
    if (!organization) return;
    try {
      setIsBusy(true);
      const { error } = await supabase.functions.invoke(
        'update-organization-status',
        {
          body: {
            organizationId: organization.id,
            status: 'ACTIVE',
          },
        }
      );
      if (error) {
        throw error;
      }
      setIsArchiveOrganizationModalVisible(false);
      queryClient.invalidateQueries(['organization']);
    } catch (err) {
      const errorMessage = await getErrorMessage(err);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <div className="relative">
      {isBusy ? (
        <div className="absolute inset-0 bg-white opacity-75 flex justify-center items-center">
          <Spinner className="w-8 h-8" />
        </div>
      ) : null}
      <div className="flex justify-between items-center border-b pb-8 mt-12">
        <div className="font-semibold text-2xl flex items-center">
          <span>{organization?.name}</span>
          {organization?.status === 'INACTIVE' ? (
            <span className="bg-mid-gray-1 text-xs py-1 px-2.5 text-white ml-3 rounded">
              Archived
            </span>
          ) : null}
        </div>
        <div className="flex items-center gap-3">
          {organization?.status === 'INACTIVE' ? (
            <Button
              onClick={unarchiveOrganization}
              color="secondary"
              className="font-semibold border-mid-gray-1 text-mid-gray-1">
              <ArchiveBoxIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Unarchive
            </Button>
          ) : (
            <Button
              onClick={() => {
                setIsArchiveOrganizationModalVisible(true);
              }}
              color="gray">
              <ArchiveBoxIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Archive
            </Button>
          )}
          <Button onClick={handleClickDeleteButton} color="red">
            <TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Delete
          </Button>
          <Button
            disabled={organization?.status === 'INACTIVE'}
            onClick={() => {
              setIsInviteUserModalVisible(true);
            }}>
            <PlusSmallIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Invite User
          </Button>
        </div>
      </div>
      <div className="mt-5 flex justify-between items-center">
        <div className="relative mt-1 rounded-md border w-72">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            name="search"
            onChange={e => {
              setCurrentPage(0);
              setSearchString(e.target.value);
            }}
            className="p-2 block w-full rounded-md border-gray-300 pl-10 outline-primary"
            placeholder="Search Name/Email"
          />
        </div>
        <Select<Status | null>
          onChange={status => {
            setFilterStatus(status);
          }}
          options={[
            {
              key: null,
              label: 'All',
            },
            {
              key: Statuses.Active,
              label: 'Active',
            },
            {
              key: Statuses.Inactive,
              label: 'Archived',
            },
          ]}
          label="Status"
        />
      </div>
      <div className="border border-light-gray-3 rounded-lg overflow-hidden mt-7">
        <table className="w-full">
          <thead className="bg-light-gray-5">
            <tr>
              <th className="text-mid-gray-2 font-normal py-3 px-4 w-1/2 text-left">
                Name
              </th>
              <th className="text-mid-gray-2 font-normal py-3 px-4 text-left">
                Role
              </th>
              <th className="w-48 text-mid-gray-2 font-normal py-3 px-4 text-left">
                Status
              </th>
              <th className="w-20"></th>
            </tr>
          </thead>
          {isInitialLoading ? (
            <tbody>
              <tr className="border-t border-light-gray-3">
                <td colSpan={4} className="table-cell">
                  <div className="w-full py-10 flex justify-center">
                    <Spinner className="w-8 h-8 z-15" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : currentPageData.length ? (
            <tbody>
              {currentPageData.map(user => (
                <tr key={user.id} className="border-t border-light-gray-3">
                  <td className="p-4 w-1/2">
                    <div className="flex gap-2 items-center">
                      {user.profile.profile_image_url ? (
                        <div className="w-[34px] h-[34px] rounded-full overflow-hidden">
                          <img
                            alt="cover"
                            src={user.profile.profile_image_url}
                            className="w-full h-full object-cover object-center"
                          />
                        </div>
                      ) : (
                        <div className="w-[34px] h-[34px] bg-gray-200 rounded-full" />
                      )}
                      <div>
                        <Link
                          to={`/organizations/${id}/users/${user.profile.id}`}
                          className="block font-medium">
                          {user.profile.first_name} {user.profile.last_name}
                        </Link>
                        <div className="text-xs text-mid-gray-1">
                          {user.profile.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 capitalize">{user.role.toLowerCase()}</td>
                  <td className="px-4">
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          'w-2 h-2 rounded-full',
                          user.profile.confirmed_at
                            ? user.status === 'ACTIVE'
                              ? 'bg-green-500'
                              : 'bg-gray-400'
                            : 'bg-yellow-700'
                        )}
                      />
                      <span className="ml-2 capitalize">
                        {user.profile.confirmed_at
                          ? user.status === 'ACTIVE'
                            ? 'Active'
                            : 'Archived'
                          : 'Pending'}
                      </span>
                    </div>
                  </td>
                  <td className="px-4">
                    <Link to={`/organizations/${id}/users/${user.profile.id}`}>
                      <ArrowRightIcon className="w-6 h-6 text-light-gray-1" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className="border-t border-light-gray-3">
                <td colSpan={4} className="table-cell">
                  <div className="w-full py-10 flex justify-center text-gray-400">
                    No Data Found
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ChevronRightIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          previousLabel={
            <ChevronLeftIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          className="flex items-center gap-1 p-4 justify-end border-t"
          pageClassName="py-1 px-2 leading-6"
          activeClassName="bg-light-gray-3 rounded"
          onPageChange={({ selected }) => {
            setCurrentPage(selected);
          }}
          forcePage={currentPage}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          disabledClassName="opacity-20 cursor-not-allowed"
        />
      </div>
      <InviteUserModal organizationId={Number(id)} />
      <ConfirmationDialog
        isVisible={isConfirmDeleteModalVisible}
        title="Confirm Delete"
        content={
          <div>
            <div>
              Are you sure you want to delete this organisation?{' '}
              <b>
                All projects, project templates and chats will be permanently
                deleted.
              </b>
            </div>
            <div className="uppercase mt-4 font-semibold">
              This process is irreversible
            </div>
          </div>
        }
        onClose={() => setIsConfirmDeleteModalVisible(false)}
        onConfirm={handleConfirmDelete}
        confirmWaitTimeAsSeconds={10}
      />
      <ConfirmArchiveOrganizationModal />
    </div>
  );
};

export default OrganizationDetail;
