import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { supabase } from '_clients/supabaseClient';
import ConfirmationDialog from '_components/ConfirmationDialog';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

import useTemplateDetailStore from './store';

export const ConfirmRemoveTemplateModal = () => {
  const snackBar = useSnackBarStore();
  const navigate = useNavigate();

  const projectType = useTemplateDetailStore($ => $.projectType);

  const isConfirmRemoveModalVisible = useTemplateDetailStore(
    $ => $.isConfirmRemoveModalVisible
  );
  const setIsConfirmRemoveModalVisible = useTemplateDetailStore(
    $ => $.setIsConfirmRemoveModalVisible
  );

  const [isBusy, setIsBusy] = useState(false);

  const handleConfirmRemove = async () => {
    if (!projectType) return;
    try {
      setIsBusy(true);
      const { error } = await supabase
        .from('project_type')
        .delete()
        .eq('id', projectType.id);
      if (error) {
        throw error;
      }
      snackBar.show('User removed successfully', 'success');
      navigate(`/manage/templates`);
      setIsConfirmRemoveModalVisible(false);
    } catch (e) {
      const errorMessage = await getErrorMessage(e);
      if (errorMessage.includes('project_type_fkey')) {
        snackBar.show(
          `Cannot delete this template. It is being used by a project.`,
          'error'
        );
      } else {
        snackBar.show(errorMessage, 'error');
      }
    } finally {
      setIsBusy(false);
    }
  };

  if (!isConfirmRemoveModalVisible) {
    return null;
  }

  return (
    <ConfirmationDialog
      isVisible={isConfirmRemoveModalVisible}
      title="Confirm Removal"
      isBusy={isBusy}
      content={
        <div>
          <div>Are you sure you want to remove this template?</div>
        </div>
      }
      onClose={() => setIsConfirmRemoveModalVisible(false)}
      onConfirm={handleConfirmRemove}
    />
  );
};

export default ConfirmRemoveTemplateModal;
