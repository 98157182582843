import Label from '_components/label';
import { Profile } from '_types/profile';

export const ExistingUser = (props: { users: Profile[] }) => {
  return (
    <>
      <div className="relative flex items-center">
        <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
          ROLE
        </span>
        <div className="flex-grow border-t border-light-gray-3 ml-3" />
      </div>
      <div className="flex flex-start mt-5">
        <Label className="w-24 mt-1">User Role</Label>
        <input
          id="role"
          name="role"
          type="text"
          title="Role"
          placeholder="Role"
          disabled
          value={props.users && props.users[0].role}
          className="ml-8 w-56 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-light-gray-3"
        />
      </div>
      <div className="relative flex items-center mt-6">
        <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
          User Details
        </span>
        <div className="flex-grow border-t border-light-gray-3 ml-3" />
      </div>
      <div className="flex flex-start mt-5">
        <Label className="w-24 mt-1">First Name</Label>
        <input
          id="first_name"
          name="first_name"
          type="text"
          title="First Name"
          placeholder="First Name"
          disabled
          value={(props.users && props.users[0].first_name) ?? ''}
          className="ml-8 w-56 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-light-gray-3"
        />
      </div>
      <div className="flex flex-start mt-5">
        <Label className="w-24 mt-1">Last Name</Label>
        <input
          id="last_name"
          name="last_name"
          type="text"
          title="Last Name"
          placeholder="Last Name"
          disabled
          value={(props.users && props.users[0].last_name) ?? ''}
          className="ml-8 w-48 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-light-gray-3"
        />
      </div>
      <div className="flex flex-start mt-5">
        <Label className="w-24 mt-1">Contact No.</Label>
        <input
          id="mobile_number"
          name="mobile_number"
          type="text"
          title="Mobile Number"
          placeholder="Mobile Number"
          disabled
          value={(props.users && props.users[0].mobile_number) ?? ''}
          className="ml-8 w-48 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-light-gray-3"
        />
      </div>
      <div className="flex flex-start mt-5">
        <Label className="w-24 mt-1">Email</Label>
        <input
          id="email"
          name="email"
          type="email"
          title="Email"
          placeholder="Email"
          disabled
          value={(props.users && props.users[0].email) ?? ''}
          className="ml-8 w-64 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-light-gray-3"
        />
      </div>
    </>
  );
};
