import { SignInWithPasswordCredentials, User } from '@supabase/supabase-js';

import { Profile } from 'shared/types/profile';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import chatClient from '_clients/chatClient';
import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import { Organization, OrganizationUser } from '_types/organization';

export interface AuthState {
  isLoading: boolean;
  user: User | null;
  profile: Profile | null;
  unreadCount: number;

  organizations: Organization[] | null;
  selectedOrganization:
    | (Organization & { organization_user: OrganizationUser[] })
    | null;

  setIsLoading: (isLoading: boolean) => void;
  setUser: (user: User) => void;
  setProfile: (profile: Profile) => void;
  setUnreadCount: (unreadCount: number) => void;

  restore: () => Promise<void>;
  signInWithPassword: (
    credentials: SignInWithPasswordCredentials
  ) => Promise<User>;
  signOut: () => Promise<void>;

  canPerformAction: () => boolean;
  changeOrganization: (organizationId: number) => Promise<void>;
  refetchOrganizations: () => Promise<void>;
  refetchProfile: () => Promise<void>;
}

const initialState = {
  user: null,
  profile: null,
  unreadCount: 0,
  organizations: [],
  selectedOrganization: null,
};

const useAuthStore = create<AuthState>()(
  immer((set, get) => ({
    isLoading: true,
    ...initialState,

    setIsLoading: (isLoading: boolean) => {
      set({ isLoading });
    },
    setUser: (user: User) => {
      set({ user });
    },

    setProfile: (profile: Profile) => {
      set({ profile });
    },

    setUnreadCount: (unreadCount: number) => {
      set({ unreadCount });
    },

    restore: async () => {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (!user) {
          return;
        }

        const { data: profile, error: profileError } = await supabase
          .from('profile')
          .select('*')
          .eq('id', user.id)
          .single();
        if (profileError) {
          throw profileError;
        }

        const { data: organizations, error: getOrganizationsError } =
          await supabase
            .from('organization')
            .select('*, organization_user!inner(*)')
            .eq('organization_user.user_id', user.id);
        if (getOrganizationsError) {
          throw getOrganizationsError;
        }

        let selectedOrganization = organizations?.[0];

        // find in local storage
        const selectedOrganizationId = localStorage.getItem(
          'selectedOrganizationId'
        );
        if (selectedOrganizationId) {
          const selectedOrganizationFromStorage = organizations?.find(
            organization => organization.id === Number(selectedOrganizationId)
          );
          if (selectedOrganizationFromStorage) {
            selectedOrganization = selectedOrganizationFromStorage;
          }
        }

        set({
          user,
          profile,
          organizations,
          selectedOrganization,
        });
      } finally {
        set({ isLoading: false });
      }
    },

    signInWithPassword: async credientials => {
      try {
        const {
          data: { session },
          error,
        } = await supabase.auth.signInWithPassword(credientials);

        if (error) {
          throw error;
        }

        if (!session) {
          throw new Error('No session');
        }

        const user = session.user;

        const { data: profile, error: profileError } = await supabase
          .from('profile')
          .select('*')
          .eq('id', user.id)
          .single();
        if (profileError) {
          throw profileError;
        }

        const { data: organizations, error: getOrganizationsError } =
          await supabase
            .from('organization')
            .select('*, organization_user!inner(*)')
            .eq('organization_user.user_id', user.id);

        if (getOrganizationsError) {
          throw getOrganizationsError;
        }

        let selectedOrganization = organizations?.[0];

        // find in local storage
        const selectedOrganizationId = localStorage.getItem(
          'selectedOrganizationId'
        );
        if (selectedOrganizationId) {
          const selectedOrganizationFromStorage = organizations?.find(
            organization => organization.id === Number(selectedOrganizationId)
          );
          if (selectedOrganizationFromStorage) {
            selectedOrganization = selectedOrganizationFromStorage;
          }
        }

        set({
          user,
          profile,
          organizations,
          selectedOrganization,
        });

        return user;
      } finally {
        set({ isLoading: false });
      }
    },

    signOut: async () => {
      localStorage.removeItem('selectedOrganizationId');
      set(initialState);
      await supabase.auth.signOut();
      await chatClient.disconnectUser();
      queryClient.resetQueries();
    },

    canPerformAction: () => {
      const selectedOrganization = get().selectedOrganization;
      return (
        selectedOrganization?.status === 'ACTIVE' &&
        selectedOrganization.organization_user?.[0].status === 'ACTIVE'
      );
    },

    changeOrganization: async (organizationId: number) => {
      const user = get().user;
      if (!user) return;

      const { data: organizations, error: getOrganizationsError } =
        await supabase
          .from('organization')
          .select('*, organization_user!inner(*)')
          .eq('organization_user.user_id', user.id);
      if (getOrganizationsError) {
        throw getOrganizationsError;
      }

      const selectedOrganization = organizations.find(
        o => o.id === organizationId
      );
      if (!selectedOrganization) {
        return;
      }

      localStorage.setItem(
        'selectedOrganizationId',
        selectedOrganization.id.toString()
      );

      set({
        organizations,
        selectedOrganization,
      });
    },

    refetchOrganizations: async () => {
      const user = get().user;
      if (!user) {
        return;
      }

      const { data: organizations, error: getOrganizationsError } =
        await supabase
          .from('organization')
          .select('*, organization_user!inner(*)')
          .eq('organization_user.user_id', user.id);
      if (getOrganizationsError) {
        throw getOrganizationsError;
      }

      const selectedOrganization = get().selectedOrganization;
      if (selectedOrganization) {
        const updatedSelectedOrganization = organizations.find(
          o => o.id === selectedOrganization.id
        );
        set({
          selectedOrganization: updatedSelectedOrganization,
          organizations,
        });
        return;
      }

      set({
        organizations,
      });
    },

    refetchProfile: async () => {
      const userId = get().user?.id;
      if (!userId) {
        return;
      }

      const {
        data: { user },
      } = await supabase.auth.getUser();

      const { data: profile, error: profileError } = await supabase
        .from('profile')
        .select('*')
        .eq('id', userId)
        .single();
      if (profileError) {
        throw profileError;
      }

      set({
        user,
        profile,
      });
    },
  }))
);

export default useAuthStore;
