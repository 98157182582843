import {
  CheckCircleIcon,
  ClipboardDocumentListIcon,
  DocumentIcon,
  PhotoIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from '@heroicons/react/24/solid';
import { Collapse } from '@mui/material';

import { useState } from 'react';

import useTemplateDetailStore from './store';

const TemplatePreview = () => {
  const [openIndex, setOpenIndex] = useState<number | null>();

  const projectType = useTemplateDetailStore($ => $.projectType);

  return (
    <div>
      {projectType?.template?.map((stage, index) => (
        <div key={index} className="mt-5">
          <div
            key={index}
            className="flex bg-light-gray-5 p-2 rounded-md flex justify-between">
            <div className="flex justify-start">
              <button
                onClick={() =>
                  setOpenIndex(openIndex === index ? null : index)
                }>
                {openIndex === index ? (
                  <ArrowDownCircleIcon className="w-5 h-5 text-mid-gray-5" />
                ) : (
                  <ArrowUpCircleIcon className="w-5 h-5 text-mid-gray-5" />
                )}
              </button>
              <div className="text-mid-gray-1 font-semibold ml-2">
                {stage.name}
              </div>
            </div>
          </div>
          {stage.milestones.map((m, i) => (
            <Collapse
              key={i}
              in={openIndex === index}
              timeout="auto"
              unmountOnExit>
              <div className="p-3 rounded-md flex justify-between border border-light-gray-3 mt-2.5">
                <div>
                  <CheckCircleIcon
                    role="button"
                    className="h-6 w-6 cursor-pointer text-light-gray-1"
                  />
                </div>
                <div className="ml-2 grid grid-cols-12 flex-1 items-center opacity-100">
                  <div className="col-span-9">
                    <span className="font-semibold text-dark-gray-1">
                      {m.name}
                    </span>
                  </div>

                  <div className="col-span-3 grid grid-cols-3 text-mid-gray-5">
                    <div className="flex justify-start items-center gap-1">
                      <ClipboardDocumentListIcon className="w-4 h-4" />
                      <span className="text-xs">0 /0</span>
                    </div>
                    <div className="flex justify-start items-center gap-1">
                      <DocumentIcon className="w-4 h-4" />
                      <span className="text-xs">0</span>
                    </div>
                    <div className="flex justify-start items-center gap-1">
                      <PhotoIcon className="w-4 h-4" />
                      <span className="text-xs">0</span>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TemplatePreview;
