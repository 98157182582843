import { useQuery } from '@tanstack/react-query';

import { supabase } from '_clients/supabaseClient';
import useAuthStore from '_stores/useAuthStore';
import { Status } from '_types/common';
import { OrganizationUser } from '_types/organization';
import { Profile } from '_types/profile';
import { Project } from '_types/project';

type StaffInfo = OrganizationUser & {
  profile: Profile & { projects: Project[] };
};

export interface Filter {
  status?: Status | null;
  userIds?: string[];
  projectTypeId?: number | null;
  keyword?: string;
}

export const fetchSelectedOrganizationStaffs = async (filter?: Filter) => {
  const organization = useAuthStore.getState().selectedOrganization;
  if (!organization) {
    return [];
  }

  let query = supabase
    .from('organization_user')
    .select('*, profile(*, projects:project(*))')
    .eq('organization_id', organization.id)
    .eq('profile.projects.organization_id', organization.id);

  if (filter) {
    if (filter.status) {
      query = query.eq('status', filter.status);
    }
    if (filter.userIds && filter.userIds.length > 0) {
      query = query.eq('project_user.user_id', filter.userIds[0]);
    }
    if (filter.projectTypeId != null) {
      query = query.eq('type', filter.projectTypeId);
    }
    if (filter.keyword) {
      query = query.textSearch('fts', `${filter.keyword.replace(/ /g, '+')}:*`);
    }
  }

  const { data, error } = await query
    .order('created_at', {
      ascending: false,
    })
    .returns<StaffInfo[]>();

  if (error) {
    throw error;
  }

  return data;
};

const useSelectedOrganizationStaffs = (filters?: Filter) =>
  useQuery(
    ['selectedOrganizationStaffs', filters],
    () => fetchSelectedOrganizationStaffs(filters),
    {}
  );

export default useSelectedOrganizationStaffs;
