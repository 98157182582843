import { Dialog, DialogContent } from '@mui/material';

import { ChangeEvent } from 'react';

import Button from '_components/button';
import Label from '_components/label';
import { ProjectStore } from '_store/project-store';

import { ExistingUser } from './existing-user';

export const AddUser = (props: {
  open: boolean;
  role: string;
  buttonDisabled: boolean;
  errorMessage: string;
  handleUserClose: () => void;
  handleRoleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  handleSave: () => void;
  handleSearch: () => void;
  handleQuery: (event: ChangeEvent<HTMLInputElement>) => void;
  isMobile: boolean;
  handleUserBy: () => void;
  phone: string;
  handlePhoneChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const {
    open,
    role,
    handleUserClose,
    handleRoleChange,
    handleSave,
    handleSearch,
    handleQuery,
    buttonDisabled,
    errorMessage,
    isMobile,
    handleUserBy,
    phone,
    handlePhoneChange,
  } = props;
  const { users } = ProjectStore;

  return (
    <Dialog className="w-full" open={open} onClose={handleUserClose}>
      <div className="flex items-center justify-between p-5">
        <div className="font-semibold text-dark-gray-1 text-lg">
          Add user to project
        </div>
      </div>
      <DialogContent className="bg-light-gray-5 w-[600px]">
        <div>
          {users && users.length > 0 ? (
            <ExistingUser users={users} />
          ) : (
            <>
              <div className="relative flex items-center">
                <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                  Role
                </span>
                <div className="flex-grow border-t border-light-gray-3 ml-3" />
              </div>
              <div className="flex flex-start mt-6">
                <Label className="w-24 mt-1">User Role</Label>
                <div>
                  <select
                    value={role}
                    onChange={handleRoleChange}
                    id="manageUser-roleSelect-dropdown"
                    className="w-48 ml-4 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <option
                      value="CLIENT"
                      key="1"
                      id="manageUser-client-select">
                      Client
                    </option>
                    <option
                      value="DESIGNER"
                      key="2"
                      id="manageUser-designer-select">
                      Designer
                    </option>
                  </select>
                </div>
              </div>
              <div className="relative flex items-center mt-6">
                <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                  Search By
                </span>
                <div className="flex-grow border-t border-light-gray-3 ml-3" />
              </div>
              <div className="mt-6">
                {isMobile ? (
                  <div className="flex flex-start">
                    <Label className="w-24 mt-1">Mobile No.</Label>
                    <div className="ml-4">
                      <div className="ml-4 relative mt-2 rounded-lg border border-gray-200">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">+65</span>
                        </div>
                        <input
                          id="mobile_number"
                          name="mobile_number"
                          placeholder="eg: 91234567"
                          value={phone}
                          onChange={e => {
                            handlePhoneChange(e);
                            handleQuery(e);
                          }}
                          className="block w-56 rounded-lg border-0 py-1.5 pl-12 pr-12 focus:outline-none leading-6"
                        />
                      </div>
                      <div className="mt-1 text-red-400">{errorMessage}</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-start">
                    <Label className="w-24 mt-1">Email</Label>
                    <div className="ml-4">
                      <input
                        required
                        id="manageUser-email-input"
                        name="email"
                        type="email"
                        title="Email"
                        placeholder="Email"
                        onChange={handleQuery}
                        className="w-80 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <div className="mt-1 text-red-400">{errorMessage}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-start mt-1">
                <div className="w-24 " />
                <div className="ml-4">
                  <div className="mt-2">
                    Search by{' '}
                    <span
                      onClick={handleUserBy}
                      className="text-primary cursor-pointer">
                      {isMobile ? 'email address' : 'mobile number'}
                    </span>{' '}
                    instead
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
        <Button color="secondary" onClick={handleUserClose}>
          Cancel
        </Button>
        <Button
          disabled={buttonDisabled}
          onClick={users && users.length > 0 ? handleSave : handleSearch}>
          {users && users.length > 0 ? 'Save' : 'Search'}
        </Button>
      </div>
    </Dialog>
  );
};
