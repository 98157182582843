import { XCircleIcon } from '@heroicons/react/20/solid';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';

import { Field } from 'formik';
import { ChangeEvent, MouseEvent, useState } from 'react';

const CoverImage = (props: {
  handleRemove: () => void;
  src?: string;
  alt?: string;
  name: string;
  handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { handleRemove, src, alt, handleUpload, name, handleBlur } = props;

  const [showCloseButton, setShowCloseButton] = useState(false);

  return (
    <Field
      name={name}
      title="Select a file"
      style={{ display: 'flex' }}
      onBlur={handleBlur}>
      {() => (
        <>
          {src ? (
            <div
              className="relative"
              onMouseOver={() => setShowCloseButton(true)}
              onMouseLeave={() => setShowCloseButton(false)}>
              <img
                alt={alt}
                src={src}
                className="w-28 h-20 object-cover rounded"
              />
              {showCloseButton && (
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/[.5] rounded flex justify-center items-center">
                  <XCircleIcon
                    className="w-5 h-5 text-red-500 bg-white rounded-xl cursor-pointer"
                    onClick={handleRemove}
                  />
                </div>
              )}
            </div>
          ) : (
            <label
              htmlFor="upload"
              className="block border border-dashed border-2 bg-light-gray-5 border-light-gray-1 rounded w-28 h-20 flex justify-center items-center cursor-pointer">
              <ArrowUpTrayIcon className="text-mid-gray-5 h-5 w-5" />
            </label>
          )}
          <>
            <input
              id="upload"
              type="file"
              accept="image/*"
              onChange={e => {
                handleUpload(e);
              }}
              onClick={(event: MouseEvent<HTMLInputElement>) => {
                (event.target as HTMLInputElement).value = '';
              }}
              name={name}
              className="hidden"
            />
          </>
        </>
      )}
    </Field>
  );
};
export default CoverImage;
