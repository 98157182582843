import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon, PlusSmallIcon } from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import Button from '_components/button';
import FilterSelect from '_components/select';
import Spinner from '_components/spinner';
import useProjectTypes from '_hooks/useProjectTypes';
import useBreadCrumbsStore from '_stores/useBreadCrumbsStore';
import { Status, Statuses } from '_types/common';

import CreateTemplateModal from './CreateTemplateModal';
import useOrganizationStore from './store';

const PAGE_SIZE = 5;

export const Templates = () => {
  const setIsCreateModalVisible = useOrganizationStore(
    $ => $.setIsCreateModalVisible
  );

  const setItems = useBreadCrumbsStore($ => $.setItems);
  const { data: projectTypes, isInitialLoading } = useProjectTypes();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [filterStatus, setFilterStatus] = useState<Status | null>(null);

  useEffect(() => {
    setItems([{ label: 'Manage' }, { label: 'Templates', highlight: true }]);
  }, [setItems]);

  const filteredProjectTypes = useMemo(() => {
    if (!projectTypes) return [];
    return projectTypes.filter(projectType => {
      if (filterStatus && projectType.status !== filterStatus) {
        return false;
      }

      if (searchString) {
        return projectType.name
          ?.toLowerCase()
          .includes(searchString.toLowerCase());
      }

      return true;
    });
  }, [filterStatus, projectTypes, searchString]);

  const currentPageData = useMemo(() => {
    if (!filteredProjectTypes) return [];
    return filteredProjectTypes.slice(
      currentPage * PAGE_SIZE,
      (currentPage + 1) * PAGE_SIZE
    );
  }, [currentPage, filteredProjectTypes]);

  const pageCount = useMemo(() => {
    if (!filteredProjectTypes) {
      return 0;
    }
    return Math.ceil(filteredProjectTypes.length / PAGE_SIZE);
  }, [filteredProjectTypes]);

  return (
    <div>
      <div className="flex justify-between items-center border-b pb-8 mt-12">
        <div className="font-semibold text-2xl">Templates</div>
        <Button
          onClick={() => {
            setIsCreateModalVisible(true);
          }}>
          <PlusSmallIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Create
        </Button>
      </div>
      <div className="mt-5 flex justify-between items-center">
        <div className="relative mt-1 rounded-md border w-72">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            name="search"
            id="search-input"
            onChange={e => {
              setCurrentPage(0);
              setSearchString(e.target.value);
            }}
            className="p-2 block w-full rounded-md border-gray-300 pl-10 outline-primary"
            placeholder="Search Name"
          />
        </div>
        <FilterSelect<Status | null>
          onChange={status => {
            setFilterStatus(status);
          }}
          options={[
            {
              key: null,
              label: 'All',
            },
            {
              key: Statuses.Active,
              label: 'Active',
            },
            {
              key: Statuses.Inactive,
              label: 'InActive',
            },
          ]}
          label="Status"
        />
      </div>
      <div className="border border-light-gray-3 rounded-lg overflow-hidden mt-7">
        <table className="w-full">
          <thead className="bg-light-gray-5">
            <tr>
              <th className="text-mid-gray-2 font-normal py-3 px-4 w-2/3 text-left">
                Name
              </th>
              <th className="text-mid-gray-2 font-normal py-3 px-4 text-left">
                Order
              </th>
              <th className="w-48 text-mid-gray-2 font-normal py-3 px-4 text-left">
                Status
              </th>
              <th className="w-20"></th>
            </tr>
          </thead>
          {isInitialLoading ? (
            <tbody>
              <tr className="border-t border-light-gray-3">
                <td colSpan={4} className="table-cell">
                  <div className="w-full py-10 flex justify-center">
                    <Spinner className="w-8 h-8 z-15" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {currentPageData.map(projectType => (
                <tr
                  key={projectType.id}
                  className="border-t border-light-gray-3">
                  <td className="p-4">
                    <div className="flex gap-2 items-center">
                      <Link
                        to={`/manage/templates/${projectType.id}`}
                        className="block">
                        {projectType.name}
                      </Link>
                    </div>
                  </td>
                  <td className="px-4">{projectType.sequence}</td>
                  <td className="px-4">
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          'w-2 h-2 rounded-full',
                          projectType.status === 'ACTIVE'
                            ? 'bg-green-500'
                            : 'bg-gray-400'
                        )}
                      />
                      <span className="ml-2 capitalize">
                        {projectType.status === 'ACTIVE'
                          ? 'Active'
                          : 'Archived'}
                      </span>
                    </div>
                  </td>
                  <td className="px-4">
                    <Link to={`/manage/templates/${projectType.id}`}>
                      <ArrowRightIcon className="w-6 h-6 text-light-gray-1" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ChevronRightIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          previousLabel={
            <ChevronLeftIcon
              className="h-6 w-6 text-dark-gray-2 opacity-50"
              aria-hidden="true"
            />
          }
          className="flex items-center gap-1 p-4 justify-end border-t"
          pageClassName="py-1 px-2 leading-6"
          activeClassName="bg-light-gray-3 rounded"
          forcePage={currentPage}
          onPageChange={({ selected }) => {
            setCurrentPage(selected);
          }}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          disabledClassName="opacity-20 cursor-not-allowed"
        />
      </div>
      <CreateTemplateModal />
    </div>
  );
};

export default Templates;
