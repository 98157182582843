import { XCircleIcon } from '@heroicons/react/24/solid';
import { Avatar, ListItemAvatar } from '@mui/material';
import Tippy from '@tippyjs/react';

import moment from 'moment';
import { useMemo, useState } from 'react';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import ConfirmationDialog from '_components/ConfirmationDialog';
import { ProjectUserWithProfile } from '_hooks/useProjectUsers';
import { ProjectStore } from '_store/project-store';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import getDisplayNameFromProfile from '_utils/getDisplayNameFromProfile';
import getErrorMessage from '_utils/getErrorMessage';

import theme from '../../theme/theme';

interface Props {
  projectUser: ProjectUserWithProfile;
}

const ProjectUserItem = ({ projectUser }: Props) => {
  const snackBar = useSnackBarStore();

  const profile = useAuthStore($ => $.profile);
  const canPerformAction = useAuthStore($ => $.canPerformAction);

  const { removeProjectUser } = ProjectStore;

  const [isResending, setIsResending] = useState(false);
  const [isResendDone, setIsResendDone] = useState(false);

  const [isConfirmRemoveModalVisible, setIsConfirmRemoveModalVisible] =
    useState(false);
  const [isRemovingUser, setIsRemovingUser] = useState(false);

  const handleResend = async (value: string, type = 'email') => {
    try {
      setIsResending(true);
      // if (type === 'phone') {
      //   await inviteUserByPhone(value, Number(id));
      //   snackBar.show('Successfully resent phone invite', 'success');
      //   return;
      // }
      const { error } = await supabase.functions.invoke(
        'resend-invitation-email',
        {
          body: {
            email: value,
          },
        }
      );
      if (error) {
        const errorMessage = await getErrorMessage(error);
        throw new Error(errorMessage);
      }
      snackBar.show('Successfully resent email invite', 'success');
      setIsResendDone(true);
    } catch (err) {
      snackBar.show((err as Error).message, 'error');
    } finally {
      setIsResending(false);
    }
  };

  const handleRemoveUserFromProject = async () => {
    try {
      setIsRemovingUser(true);
      await removeProjectUser(projectUser.user_id, projectUser.project_id);
      snackBar.show('User removed', 'success');
      queryClient.invalidateQueries(['projectUsers']);
    } catch (error) {
      snackBar.show((error as Error).message, 'error');
    } finally {
      setIsConfirmRemoveModalVisible(false);
      setIsRemovingUser(false);
    }
  };

  const canSendInvite = useMemo(() => {
    if (projectUser.profile.invited_at) {
      const deltaTime = Math.abs(
        moment(projectUser.profile.invited_at).diff(moment(), 'seconds')
      );
      if (deltaTime < 180) {
        return false;
      }
    }
    return true;
  }, [projectUser]);

  return (
    <>
      <div className={'flex items-center'}>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: `${theme.palette.primary.light} !important`,
              color: 'white !important',
            }}
            alt={projectUser.profile.first_name ?? ''}
            src={
              projectUser.profile.profile_image_url
                ? projectUser.profile.profile_image_url
                : '/broken-image.jpg'
            }
          />
        </ListItemAvatar>
        <div className="text-blue-500">{`${
          !projectUser.profile.first_name && !projectUser.profile.last_name
            ? projectUser.profile.email
            : projectUser.profile.first_name ?? ''
        } ${projectUser.profile.last_name ?? ''}`}</div>
        {canPerformAction() && !projectUser.profile.confirmed_at ? (
          <div className="ml-4 flex items-center gap-2">
            <div className="h-6 px-2.5 border rounded border-red-500 text-red-500 text-xs font-medium flex items-center">
              Pending
            </div>
            {isResendDone ? (
              <Button className="ml-2 h-6 px-2.5 rounded bg-green-500 text-white text-xs font-medium flex items-center">
                Invite Sent!
              </Button>
            ) : (
              <Tippy
                className="text-xs"
                content={
                  canSendInvite
                    ? 'Resend email invite'
                    : 'An invite has been sent recently. Please wait for a while to send another invitation.'
                }>
                <div>
                  <Button
                    disabled={isResending || !canSendInvite}
                    className="h-6 px-2.5"
                    onClick={() =>
                      handleResend(
                        projectUser.profile?.email
                          ? projectUser.profile.email
                          : projectUser.profile.mobile_number ?? '',
                        projectUser.profile.email ? 'email' : 'phone'
                      )
                    }>
                    Resend Invite
                  </Button>
                </div>
              </Tippy>
            )}
          </div>
        ) : projectUser.status === 'invited' ? (
          <div className="ml-4 flex items-center gap-2">
            <div className="h-6 px-2.5 border rounded border-primary text-primary text-xs font-medium flex items-center">
              Invite Sent
            </div>
          </div>
        ) : null}
        {canPerformAction() &&
        projectUser.profile.role !== 'ADMIN' &&
        projectUser.user_id !== profile?.id ? (
          <Tippy className="text-xs" content="Remove user from the project">
            <span
              color="error"
              className="ml-4 cursor-pointer text-mid-gray-5"
              onClick={() => {
                setIsConfirmRemoveModalVisible(true);
              }}>
              <XCircleIcon className="w-6 h-6" />
            </span>
          </Tippy>
        ) : null}
      </div>

      <ConfirmationDialog
        isVisible={isConfirmRemoveModalVisible}
        title="Confirm user removal?"
        isBusy={isRemovingUser}
        content={
          <div>
            Are you sure you want to remove{' '}
            <span className="font-medium">
              {getDisplayNameFromProfile(projectUser.profile)}
            </span>{' '}
            from the project?
          </div>
        }
        onClose={() => setIsConfirmRemoveModalVisible(false)}
        onConfirm={handleRemoveUserFromProject}
      />
    </>
  );
};

export default ProjectUserItem;
