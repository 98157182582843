import { PencilSquareIcon } from '@heroicons/react/24/solid';

import Button from '_components/button';

import EditTemplateInfoModal from './EditTemplateInfoModal';
import useTemplateDetailStore from './store';

const TemplateBasicInfo = () => {
  const projectType = useTemplateDetailStore($ => $.projectType);

  const setIsEditModalVisible = useTemplateDetailStore(
    $ => $.setIsEditModalVisible
  );

  return (
    <div className="border border-light-gray-3 rounded-xl overflow-hidden">
      <div className="bg-light-gray-5 flex items-center justify-between py-3 px-4">
        <div className="font-semibold">Template Details</div>
        <div>
          <Button onClick={() => setIsEditModalVisible(true)} color="secondary">
            <PencilSquareIcon
              className="-ml-1 mr-2 h-5 w-5"
              aria-hidden="true"
            />
            Edit
          </Button>
        </div>
      </div>
      <div className="p-5 flex flex-col gap-4 border-t">
        <div className="flex items-center gap-4">
          <div className="w-[120px] text-mid-gray-1 font-semibold">Title</div>
          <div className="text-dark-gray-2 capitalize">
            {projectType?.name ?? '(no data)'}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-[120px] text-mid-gray-1 font-semibold">Order</div>
          <div className="text-dark-gray-2">
            {projectType?.sequence ?? '(no data)'}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-[120px] text-mid-gray-1 font-semibold">Status</div>
          <div className="text-dark-gray-2">
            {projectType?.status === 'INACTIVE' ? (
              <div className="h-6 px-2.5 rounded bg-mid-gray-1 text-white text-xs font-medium flex items-center">
                Archived
              </div>
            ) : (
              <div className="h-6 px-2.5 rounded bg-green-500 text-white text-xs font-medium flex items-center">
                Active
              </div>
            )}
          </div>
        </div>
      </div>
      <EditTemplateInfoModal />
    </div>
  );
};

export default TemplateBasicInfo;
