import { Dialog } from '@mui/material';

import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import Button from '_components/button';
import Label from '_components/label';
import Spinner from '_components/spinner';
import { getProfileOrDefault } from '_models/profile';
import useSnackBarStore from '_stores/useSnackBarStore';
import { Profile, Role } from '_types/profile';
import getErrorMessage from '_utils/getErrorMessage';

const validatorSchema = Yup.object()
  .shape({
    first_name: Yup.string()
      .trim()
      .max(50)
      .min(3)
      .label('First name')
      .required(),
    last_name: Yup.string().trim().max(50).label('Last name').required(),
  })
  .required();

export const InviteUser = (props: {
  open: boolean;
  role: Role;
  query: string;
  isMobile: boolean;
  phone: string;
  projectId?: number;
  onClose: () => void;
}) => {
  const { open, role, query, projectId, isMobile, onClose } = props;

  const snackBar = useSnackBarStore();
  const [isBusy, setIsBusy] = useState(false);

  const getPartialProfile = (value: string): Profile => {
    const profile = getProfileOrDefault();
    if (isMobile) {
      profile.mobile_number = value;
    } else {
      profile.email = value;
    }
    if (role) {
      profile.role = role;
    }
    return profile;
  };

  const onInviteUser = async (
    profile: Profile,
    formikHelpers: FormikHelpers<Profile>
  ) => {
    if (!projectId) return;
    try {
      setIsBusy(true);

      const { error: inviteError } = await supabase.functions.invoke(
        'invite-user-to-project',
        {
          body: {
            email: profile.email?.toLowerCase(),
            phone: `+65${profile.mobile_number}`,
            isMobile,
            role: profile.role,
            projectId: projectId,
            firstName: profile.first_name,
            lastName: profile.last_name,
          },
        }
      );
      if (inviteError) {
        throw inviteError;
      }

      snackBar.show('User added successfully', 'success');
      queryClient.invalidateQueries(['projectUsers']);
      queryClient.invalidateQueries(['projectUserInvitations']);
      formikHelpers.resetForm();
      onClose();
    } catch (error) {
      const errorMessage = await getErrorMessage(error);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <Dialog maxWidth={false} open={open} className="relative">
      {isBusy ? (
        <div className="absolute inset-0 bg-white opacity-75 flex justify-center items-center z-20">
          <Spinner className="w-8 h-8" />
        </div>
      ) : null}
      <div className="flex items-center justify-between py-3 px-4">
        <div className="font-semibold">Invite New User to Project</div>
      </div>
      <div className="w-[680px]">
        <Formik
          enableReinitialize={true}
          initialValues={getPartialProfile(query)}
          onSubmit={onInviteUser}
          validationSchema={validatorSchema}>
          {({ errors, touched }) => {
            console.log(errors);
            return (
              <Form>
                <div className="bg-light-gray-5 p-5">
                  <div className="relative flex items-center">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      Role
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>
                  <div className="flex flex-start items-center mt-4">
                    <Label className="w-24">Role*</Label>
                    <Field
                      as="select"
                      id="manageUser-client-select"
                      name="role"
                      autoComplete="role"
                      placeholder="Role"
                      className="block w-32 ml-8 py-2 px-3 w-full rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                      <option value="CLIENT">Client</option>
                      <option value="DESIGNER">Designer</option>
                    </Field>
                    {errors.role && touched.role && (
                      <span className="ml-4 text-red-500 text-xs">
                        {errors.role}
                      </span>
                    )}
                  </div>
                  <div className="relative flex items-center mt-5">
                    <span className="flex-shrink text-xs text-mid-gray-3 uppercase font-semibold">
                      User Details
                    </span>
                    <div className="flex-grow border-t border-light-gray-3 ml-3" />
                  </div>
                  <div className="flex flex-start mt-6">
                    <Label className="w-24 mt-1">First Name*</Label>
                    <div className="ml-4">
                      <Field
                        name="first_name"
                        type="text"
                        autoComplete="first_name"
                        placeholder="First Name"
                        className="block w-56 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500"
                      />
                      {errors.first_name && touched.first_name && (
                        <div className="mt-2 text-red-500 text-xs">
                          {errors.first_name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-start mt-6">
                    <Label className="w-24 mt-1">Last Name*</Label>
                    <div className="ml-4">
                      <Field
                        id="manageUser-lastName-input"
                        name="last_name"
                        type="text"
                        autoComplete="last_name"
                        placeholder="Last Name"
                        className="block w-56 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500"
                      />
                      {errors.last_name && touched.last_name && (
                        <div className="mt-2 text-red-500 text-xs">
                          {errors.last_name}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-start mt-6">
                    <Label className="w-24 mt-1">Gender*</Label>
                    <div className="ml-4">
                      <Field
                        as="select"
                        name="gender"
                        autoComplete="gender"
                        placeholder="Gender"
                        className="block w-32 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                      </Field>
                      {errors.gender && touched.gender && (
                        <span className="ml-4 text-red-500 text-xs">
                          {errors.gender}
                        </span>
                      )}
                    </div>
                  </div>

                  {isMobile && (
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Mobile No.*</Label>
                      <Field name="mobile_number">
                        {({ field }: FieldProps) => (
                          <input
                            {...field}
                            value={`+65${field.value}`}
                            type="text"
                            disabled
                            placeholder="eg: 91234567"
                            autoComplete="mobile_number"
                            className="block w-64 ml-4 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  {!isMobile && (
                    <div className="flex flex-start mt-6">
                      <Label className="w-24 mt-1">Email*</Label>
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        disabled
                        placeholder="Email"
                        className="block w-96 ml-4 py-2 px-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  )}
                </div>
                <div className="flex justify-end gap-2 p-4 border-t border-light-gray-3">
                  <Button color="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button disabled={isBusy} type="submit">
                    Invite
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Dialog>
  );
};
