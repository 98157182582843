import useCountryList from '_hooks/useCountryList';
import { Address } from '_hooks/useProjectBasicInfo';
import { Project } from '_types/project';

export const ProjectCard = (props: { project?: Project }) => {
  const { data: countryList } = useCountryList();
  const { project } = props;

  if (!project) return null;

  const address = project.address as unknown as Address;

  return (
    <div className="flex justify-start items-center">
      <div className="w-[100px] h-[70px] overflow-hidden rounded-lg">
        {project.cover_image_url && (
          <img
            alt="cover"
            src={project.cover_image_url}
            className="object-center"
          />
        )}
      </div>
      <div className="ml-4">
        <div className="text-2xl font-semibold">{project.name}</div>
        <div className="text-mid-gray-1">
          <span>
            {address.unit}
            {address.unit && ','}
          </span>
          <span>{address.street}, </span>
          <span>
            {countryList?.find(e => e.name === address.country)?.name}{' '}
          </span>
          <span>{address.postal_code}</span>
        </div>
      </div>
    </div>
  );
};
