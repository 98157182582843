import { Popover, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/solid';
import { Avatar, Tooltip } from '@mui/material';

import moment from 'moment';
import { Fragment, useCallback, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { queryClient } from '_clients/queryClient';
import { supabase } from '_clients/supabaseClient';
import replaceString from '_components/replace-string';
import Spinner from '_components/spinner';
import useNotifications from '_hooks/useNotifications';
import { ProjectUpdatePreloaded } from '_hooks/useProjectUpdates';
import useAuthStore from '_stores/useAuthStore';
import useSnackBarStore from '_stores/useSnackBarStore';
import getErrorMessage from '_utils/getErrorMessage';

function Notifications() {
  const snackBar = useSnackBarStore();

  const currentUserProfile = useAuthStore($ => $.profile);
  const selectedOrganization = useAuthStore($ => $.selectedOrganization);
  const changeOrganization = useAuthStore($ => $.changeOrganization);

  const { data: notifications } = useNotifications();
  const navigate = useNavigate();

  const [isBusy, setIsBusy] = useState(false);

  const haveNotifications = useMemo(
    () => notifications && notifications.length > 0,
    [notifications]
  );

  const markNotificationsAsRead = useCallback(async () => {
    try {
      setIsBusy(true);
      const { data: ack, error } = await supabase
        .from('notification_acknowledgements')
        .select()
        .eq('user_id', currentUserProfile?.id)
        .select()
        .maybeSingle();
      if (error) {
        throw error;
      }
      if (ack) {
        const { error } = await supabase
          .from('notification_acknowledgements')
          .update({
            date_read: new Date().toISOString(),
          })
          .eq('user_id', currentUserProfile?.id);
        if (error) {
          throw error;
        }
      } else {
        const { error } = await supabase
          .from('notification_acknowledgements')
          .insert({
            user_id: currentUserProfile?.id,
            date_read: new Date().toISOString(),
          });
        if (error) {
          throw error;
        }
      }

      await queryClient.invalidateQueries(['notifications']);
    } catch (e) {
      const errorMessage = await getErrorMessage(e);
      snackBar.show(errorMessage, 'error');
    } finally {
      setIsBusy(false);
    }
  }, [currentUserProfile, snackBar]);

  const handleClickNotificationItem = useCallback(
    async (projectUpdate: ProjectUpdatePreloaded) => {
      if (!selectedOrganization) {
        return;
      }
      if (
        projectUpdate.project?.organization_id &&
        selectedOrganization.id !== projectUpdate.project?.organization_id
      ) {
        await changeOrganization(projectUpdate.project?.organization_id);
      }
      navigate(`/projects/${projectUpdate.project_id}`);
    },
    [changeOrganization, navigate, selectedOrganization]
  );

  return (
    <Popover className="relative h-6 w-6 text-dark-gray-2">
      {({ close }) => (
        <>
          <Popover.Button className="focus:outline-none">
            <Tooltip arrow title="Notifications">
              <div className="relative cursor-pointer text-white hover:opacity-90">
                <BellIcon className="w-6 h-6" />
                {notifications?.length ? (
                  <div className="absolute w-4 h-4 -bottom-1 -right-1 rounded-full bg-red-400 text-white text-xxs font-semibold flex items-center justify-center">
                    {notifications.length}
                  </div>
                ) : null}
              </div>
            </Tooltip>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="w-[342px] bg-white rounded-lg border border-light-gray-5 shadow-lg absolute -top-2 -right-2 z-10">
              {isBusy ? (
                <div className="absolute inset-0 bg-white opacity-75 flex justify-center items-center z-20">
                  <Spinner className="w-8 h-8" />
                </div>
              ) : null}
              <div className="py-4 px-5 flex justify-between">
                <div className="font-semibold">Notifications</div>
                <button
                  onClick={async () => {
                    await markNotificationsAsRead();
                    close();
                  }}
                  disabled={!haveNotifications}
                  className="text-primary cursor-pointer disabled:text-mid-gray-1 disabled:cursor-not-allowed">
                  Dismiss All
                </button>
              </div>
              <div className="w-full border-t border-light-gray-3" />
              <div className="max-h-[600px] overflow-auto" id="scrollableDiv">
                {haveNotifications ? (
                  <InfiniteScroll
                    dataLength={notifications?.length ?? 0}
                    next={() => {
                      console.log('next');
                    }}
                    hasMore={false}
                    loader={<h4>Loading...</h4>}
                    pullDownToRefresh={false}
                    pullDownToRefreshContent={false}
                    scrollableTarget="scrollableDiv"
                    endMessage={
                      <div className="p-3 text-center text-mid-gray-3">
                        End of the notifications
                      </div>
                    }>
                    {(notifications || []).map(projectUpdate => (
                      <div key={projectUpdate.id}>
                        <div
                          className="py-3 px-5 flex justify-start w-full hover:bg-gray-50 cursor-pointer"
                          onClick={() => {
                            close();
                            handleClickNotificationItem(projectUpdate);
                          }}>
                          <Avatar
                            alt={projectUpdate.profile?.first_name ?? ''}
                            src={projectUpdate.profile?.profile_image_url ?? ''}
                            sx={{ width: 24, height: 24 }}
                          />
                          <div className="ml-2 mb-2 flex-1">
                            <div className="flex justify-between items-center">
                              <div className="capitalize leading-6">
                                {projectUpdate.type
                                  ?.replaceAll('_', ' ')
                                  .toLowerCase()}
                              </div>
                              <div className="text-xs text-mid-gray-3">
                                {projectUpdate &&
                                moment(projectUpdate?.created_at).isSame(
                                  new Date(),
                                  'day'
                                )
                                  ? moment(projectUpdate.created_at).format(
                                      'LT'
                                    )
                                  : moment(projectUpdate.created_at).format(
                                      'll'
                                    )}
                              </div>
                            </div>
                            <div className="text-xs text-mid-gray-3">
                              {replaceString(projectUpdate)}
                            </div>
                          </div>
                        </div>
                        <div className="w-full border-t border-light-gray-3" />
                      </div>
                    ))}
                  </InfiniteScroll>
                ) : (
                  <div className="py-4 px-5">
                    <div>No new notifications</div>
                    <div className="text-xs text-mid-gray-3">
                      No new notifications received
                    </div>
                  </div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default Notifications;
